import { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
	WbGrid,
	WbGridCol,
	WbGridRow,
	WbText,
	WbButton,
	WbCard,
	WbBreadcrumbs,
	WbBreadcrumb,
	WbIcon,
} from '@workbench/react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import DemoDetail from '../../Components/Demo/DemoDetails/DemoDetails';
import './DemoDetails.scss';

function BreadCrumb() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const handleBreadCrumbClick = (e) => {
		navigate(`/${e.currentTarget.innerText.toLowerCase()}`);
	};
	return (
		<WbBreadcrumbs className="navigation">
			<WbBreadcrumb onClick={handleBreadCrumbClick}>{t('breadcrumb.home')}</WbBreadcrumb>
			<WbBreadcrumb onClick={handleBreadCrumbClick}>{t('breadcrumb.demo')}</WbBreadcrumb>
			<WbBreadcrumb>{t('breadcrumb.model')}</WbBreadcrumb>
		</WbBreadcrumbs>
	);
}

// wb doesn't handle fullWidth={false} properly
function WbGridOverride({ fullWidth, children }) {
	return fullWidth ? <WbGrid fullWidth> {children} </WbGrid> : <WbGrid> {children} </WbGrid>;
}

function StickyFooter() {
	const { t } = useTranslation();
	const footerRef = useRef();
	const [sticky, setSticky] = useState(true);
	const [open, setOpen] = useState(true);

	const handleClose = () => {
		setOpen(!open);
	};

	const handleScroll = () => {
		const hideSticky =
			window.innerHeight + window.scrollY + footerRef.current.offsetHeight / 2 >=
			document.documentElement.scrollHeight;
		if (hideSticky) {
			setSticky(false);
			setOpen(true);
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, []);

	const stickyState = open ? 'open' : 'closed';
	const chevronState = open ? 'down' : 'up';

	const classes = classNames('cta_footer', {
		cta_footer__sticky: sticky,
		cta_footer__snappy: !sticky,
		[`cta_footer__sticky-${stickyState}`]: true,
	});

	return (
		<WbCard variant="dark-grey" className={classes} ref={footerRef} data-testid="cta-card">
			<WbCard variant="dark-grey" className="cta_footer__toggleBtn">
				<WbIcon name={`bds/chevron-${chevronState}/24`} onClick={handleClose} data-testid="cta-toggle" />
			</WbCard>

			<WbGridOverride fullWidth={!sticky}>
				<WbGridRow className="cta_footer__content">
					<WbGridCol mq1={12} mq2={12} mq3={6} mq4={6} mq5={7} mq6={7} className="cta_footer__text">
						<WbText>
							<b> {t('footerPopups.description-bold')} </b> {t('footerPopups.description-normal')}
						</WbText>
					</WbGridCol>
					<WbGridCol mq1="hide" />
					<WbGridCol mq1="hide" mq5={1} mq6={1} />
					<WbGridCol mq1={6} mq2={6} mq3={3} mq4={3} mq5={2}>
						<WbButton
							className="cta_footer__button"
							variant="secondary"
							theme="dark"
							size="l"
							href="https://vehicle-images.mercedes-benz.com/"
						>
							<WbText>{t('footerPopups.get-started')}</WbText>
						</WbButton>
					</WbGridCol>
					<WbGridCol mq1={6} mq2={6} mq3={3} mq4={3} mq5={2}>
						<WbButton
							className="cta_footer__button"
							variant="primary"
							size="l"
							href="https://developer.mercedes-benz.com/products?u=Retailer"
						>
							<WbText>{t('footerPopups.buy-product-keys')}</WbText>
						</WbButton>
					</WbGridCol>
				</WbGridRow>
			</WbGridOverride>
		</WbCard>
	);
}

function DemoDetails() {
	const { vehicleId } = useParams();

	return (
		<WbGrid>
			<BreadCrumb />
			<DemoDetail vehicleId={vehicleId} />
			<StickyFooter />
		</WbGrid>
	);
}

export default DemoDetails;
