/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { createUseStyles } from 'react-jss';
import { WbGridCol, WbGridRow, WbHeading, WbTab, WbTabContent, WbTabs, WbText } from '@workbench/react';
import { useTranslation } from 'react-i18next';
import RDBox from '../../Common/RDBox/RDBox';
import '../../Demo/DemoTabs/DemoTabs.scss';
import { TabContents, chooseTabContent } from '../TabContents/TabContents';
import RDivider from '../../Common/RDivider/RDivider';
import vehicleData from "../../../Constants/vehicleData";
import { useSiteStyles } from '../../../Contexts/StyleContext';

const useStyles = createUseStyles((theme) => ({
    siteStyles: (siteStyles) => ({
        border: siteStyles.border,
        key: siteStyles.key,
    }),
}));
function VehicleTabs(props) {
    const { infoList, mmesData, steveData, equipmentImages } = props;
    const { t } = useTranslation();

    const checkTabExteriorOrInterior = (tab) => tab.name === "exterior" || tab.name === "interior";

    const { siteStyles } = useSiteStyles();
    const classes = useStyles(siteStyles);

    const equipmentHighlightFilter = (tab) => {
        if (siteStyles.key === "equipment" && tab.name !== "generalData" && tab.name !== "meServices") {
            return "equipmentHighlight";
        } else if (siteStyles.key === "specifications" && tab.name !== "meServices") {
            return "specificationsHighlight";
        } else if (siteStyles.key === "me-services" && tab.name === "meServices") {
            return "meServicesHighlight";
        }
    }

    const chooseSelectedTab = () => {
        if (siteStyles.key === "equipment") {
            return "packages";
        } else if (siteStyles.key === "me-services") {
            return "meServices";
        }
        return "generalData";
    }

    return (
        <RDBox className="rd-demo-content">
            <WbTabs className="rd-demo-content__tabs" selected={chooseSelectedTab()}>
                {infoList.tabs.map((tab) => (
                    <React.Fragment key={tab.name}>
                        <WbTab className={`${equipmentHighlightFilter(tab) === "equipmentHighlight" ? classes.siteStyles : null} ${equipmentHighlightFilter(tab) === "specificationsHighlight" ? classes.siteStyles : null} ${equipmentHighlightFilter(tab) === "meServicesHighlight" ? classes.siteStyles : null}`} name={tab.name}>{t(`demoTabs.dataTabs.${tab.name}`)}</WbTab>
                        <WbTabContent name={tab.name} slot="content" className={`rd-demo-content__tab ${equipmentHighlightFilter(tab) === "meServicesHighlight" ? classes.siteStyles : null} ${equipmentHighlightFilter(tab) === "specificationsHighlight" && tab.name === "generalData" ? classes.siteStyles : null} `}>
                            {(checkTabExteriorOrInterior(tab) && equipmentImages) && (
                                <>
                                    <WbGridRow className="rd-demo-line-content">
                                        <WbGridCol mq3={6} mq1={8}>
                                            <RDBox className="rd-demo-image-box">
                                                <img className="rd-demo-lineImage" src={tab.name === "exterior" ? equipmentImages?.PAINT?.A31 || equipmentImages?.PAINT?.A34 : equipmentImages?.UPHOLSTERY?.A31 || equipmentImages?.UPHOLSTERY?.A34} />
                                                <RDBox className="rd-demo-image-box-description">
                                                    <WbText>{tab.name === "exterior" ? t("demoTabs.paint") : t("demoTabs.upholstery")}</WbText>
                                                    <WbText>{tab.name === "exterior" ? equipmentImages?.PAINT?.desc?.text : equipmentImages?.UPHOLSTERY?.desc?.text}</WbText>
                                                </RDBox>
                                            </RDBox>
                                        </WbGridCol>
                                        {tab.name === "exterior" && (
                                            <WbGridCol mq3={6} mq1={4}>
                                                <RDBox className="rd-demo-line-box">
                                                    <WbText>{t("demoTabs.lack")}</WbText>
                                                    <WbText>Metallic</WbText>
                                                </RDBox>
                                            </WbGridCol>
                                        )}
                                    </WbGridRow>
                                    <RDivider />
                                </>
                            )}
                            <WbHeading size="xs" className="rd-demo-content__heading">
                                {t(`demoTabs.dataTabs.${tab.name}`)}
                            </WbHeading>
                            <WbGridRow>
                                <TabContents tab={tab} vehicleData={infoList} mmesData={mmesData} steveData={steveData} equipmentImages={equipmentImages} equipmentHighlightFilter={equipmentHighlightFilter(tab)} className="rd-demo-content__element">
                                    {chooseTabContent(tab.type)}
                                </TabContents>
                            </WbGridRow>
                        </WbTabContent>
                    </React.Fragment>
                ))}
            </WbTabs>
        </RDBox>
    );
}

export default VehicleTabs;