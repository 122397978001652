import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationsEn from './locales/en.json';
import translationsDe from './locales/de.json';

const resources = {
	en: {
		translation: translationsEn,
	},
	de: {
		translation: translationsDe,
	},
};

i18n.use(LanguageDetector).use(initReactI18next).init({
	fallbackLng: 'en',
	resources,
});

export default i18n;
