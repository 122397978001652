/* eslint-disable no-shadow */
import '../../Assets/VideoPlaylist/css/site.css';

import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useInactivity } from '../../Utils/VideoPlaylist/lib/hooks';
import { usePlayerStore } from '../../Utils/VideoPlaylist/lib/stores';
import ModalPlayer from '../../Components/VideoPlaylist/ModalPlayer';
import Playlist from '../../Components/VideoPlaylist/Playlist';

function VideoPlaylist() {
    const { i18n } = useTranslation();
    const [dataSets, setDataSets] = useState([]);
    const setIdle = usePlayerStore(state => state.setIdle);
    // Go into Idle Mode after Inactivity for 30 seconds.
    useInactivity(30000, () => {
        setIdle(true);
    }, () => {
        setIdle(false);
    });
    const darkmode = usePlayerStore(state => state.darkmode);
    const hasTouch = usePlayerStore(state => state.hasTouch);
    const title = useRef('');
    const description = useRef('');
    const logo = useRef('');
    useEffect(() => {
        document.title = 'Retailer Dashboard Video Playlist';
        fetch(`/video-playlist/config/playlist-${i18n.language.split('-')[0] === 'de' ? 'de' : 'en'}.json`)
            .then(async (res) => res.json())
            .then(async (playlist) => {
                title.current = playlist.title;
                description.current = playlist.description;
                logo.current = playlist.darkmode
                    ? playlist.logoDark ?? '/video-playlist/config/images/logo_dark.png'
                    : playlist.logo ?? '/video-playlist/config/images/logo.png';
                usePlayerStore.getState().setDarkMode(playlist.darkmode);
                usePlayerStore.getState().setHasTouch(playlist.hasTouch);
                usePlayerStore.getState().setScenes(playlist.scenes);
                const results = await Promise.all(playlist.items.map(async (item) => {
                    const { title, filename } = item;
                    // fetch and read files
                    const res = await fetch(filename);
                    const data = await res.json();
                    // add filename for reference
                    const playlistItem = { filename, title, data };
                    return playlistItem;
                }));
                setDataSets(results);
            })
            .catch(error => {
                throw error;
            });
    }, []);

    return (
        <main className={`relative overflow-hidden ${darkmode ? 'dark' : ''} ${!hasTouch ? 'pointer-events-none' : ''}`}>
            <section className="bg-smoke p-[88px] dark:bg-grey-10 top-components-section">
                <div className="flex justify-between top-components">
                    <div className='w-2/3 header-holder'>
                        <h3 className="text-gray-20 mb-[32px] text-xxl dark:text-white">{title.current}</h3>
                        <p className="text-s font-normal text-grey-40 dark:text-grey-70">
                            {description.current}
                        </p>
                    </div>
                    <div className="flex justify-items-end top-image-holder">
                        <figure className="flex justify-center bg-white shadow-level-1 dark:bg-obsidian max-w-96 max-h-64">
                            <img src={logo.current} alt="Autohaus Logo" />
                        </figure>
                    </div>
                </div>
            </section>
            <section className="h-[572px] overflow-hidden bg-white p-[88px] dark:bg-obsidian">
                <Playlist dataSets={dataSets} />
            </section>
            <ModalPlayer />
        </main>
    );
}
export default VideoPlaylist;
