import { WbText, WbGrid, WbGridCol, WbGridRow, WbLink } from '@workbench/react';
import { Trans, useTranslation } from "react-i18next";

function ProductKey() {
	useTranslation();

	return (
		<div className="gs__product-key">
			<WbGrid>
				<WbGridRow>
					<WbGridCol className="gs__product-key__text" mq1={12}>
							<WbText size="l">
							<Trans i18nKey="productKeyCta"
								components={{Link: <WbLink
									href="https://developer.mercedes-benz.com/products?u=Retailer"
									rel="noopener noreferrer"
									target="_blank"
									variant="inline"/>,
									bold: <strong />
									}}/>
							</WbText>
					</WbGridCol>
				</WbGridRow>
			</WbGrid>
		</div>
	);
}

export default ProductKey;
