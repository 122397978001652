import axios from 'axios';

const BASEPATH = window.REACT_API_BASE_URL || 'https://mbdev-marketing-dashboard-backend-dev-app.azurewebsites.net/backend'

// TODO: Retry-Axios

export const getVehicleImages = (params) => {
    const { vin, apikey, queries } = params;
    return axios.get(`${BASEPATH}/business_bundle/vehicles/${vin}`, {
        params: {
            ...queries,
            uniformImage: 'middle',
            fallback: true,
            imageUrl: true
        },
        headers: { "x-api-key": apikey }
    });
}

export const getVehicleImage = (params) => {
    const { imageUrl, apikey, queries } = params;
    return axios.get(`${imageUrl}`, {
        params: queries,
        responseType: "arraybuffer"
    });
}

export const getVehicleVideo = (params) => {
    const { vin, apikey, queries } = params;
    return axios.get(`${BASEPATH}/videos_bundle/${vin}`, {
        params: queries,
        headers: { "x-api-key": apikey }
    });
}

export const getVehicleEquipmentImages = (params) => {
    const { vin, apikey, queries } = params;
    return axios.get(`${BASEPATH}/equipment_bundle/${vin}`, {
        params: queries,
        headers: { "x-api-key": apikey }
    });
}

export const getV2SData = (params) => {
    const { vin, apikey, queries } = params;
    return axios.get(`${BASEPATH}/specification_bundle/${vin}`, {
        params: queries,
        headers: { "x-api-key": apikey }
    });
}

export const getV2SEquipments = (params) => {
    const { vin, apikey, queries } = params;
    return axios.get(`${BASEPATH}/equipment_specification_bundle/${vin}`, {
        params: queries,
        headers: { "x-api-key": apikey }
    });
}

export const getMmesData = (params) => {
    const { vin, apikey, queries } = params;
    return axios.get(`${BASEPATH}/meservice_specification_bundle/${vin}`, {
        params: queries,
        headers: { "x-api-key": apikey }
    });
}