import { useEffect } from 'react';
import { WbCard } from '@workbench/react';

function BlingsVideo(props) {
	const { detail, type } = props;

	const createVideo = () => {
		if (window.player) {
			window.player.destroy();
		}
		const videoSettings = {
			container: document.getElementById('blings-video'),
			showTimeline: true,
			posterFrame: 3,
			cinematic: false,
			hideFullScreenBtn: false,
			loaderColor: '#e1ccdc',
			ctrlBtnsColor: '#e1ccdc',
			muted: false,
			autoplay: false,
			autoReplay: false,
		};
		window.BlingsPlayer.create({
			project: {
				id: 'a7b2caa6-9374-4934-ac61-534e9ec3766a',
			},
			data: detail,
			settings: videoSettings,
			scenes: ['startNoSpec', 'spec', 'outroNoEnd', 'endScreen'],
		}).then((player) => {
			window.player = player;
		});
	};

	
	useEffect(() => {
		if (type === "video") {
			createVideo();
		}
	}, [type]);

	return (
		<WbCard className="rd-video-card">
			<div id="blings-video" data-testid="blings-video" />
		</WbCard>
	);
}

export default BlingsVideo;
