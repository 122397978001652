/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import classNames from 'classnames';
import './RDBox.scss';

const rdStyle = (style = {}, size = 8, wrap = true) => ({
	...style,
	gap: size,
	wrap: !!wrap,
});

function RDBox({ className, children, style, size, direction, align, wrap, onClick, ...rest }) {
	return (
		<div
			className={classNames(
				'rd-box',
				{ [`rd-box--${direction}`]: !!direction },
				{ [`rd-box--align-${align}`]: !!align },
				{ [className]: !!className }
			)}
			style={rdStyle(style, size, wrap)}
			direction={direction}
			size={size}
			onClick={onClick}
			{...rest}
		>
			{children}
		</div>
	);
}

export default RDBox;
