// StyleContext.js
import React, { createContext, useContext, useState } from 'react';

const StyleContext = createContext();

export const HighlightStyleProvider = ({ children }) => {
  const [siteStyles, setSiteStyles] = useState({});

  return (
    <StyleContext.Provider value={{ siteStyles, setSiteStyles }}>
      {children}
    </StyleContext.Provider>
  );
};

export const useSiteStyles = () => useContext(StyleContext);
