import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from 'react-redux';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './app/store';
import './i18n/i18n';
import { HighlightStyleProvider } from "./Contexts/StyleContext";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<Provider store={store}>
		<HighlightStyleProvider>
			<Router>
				<Suspense fallback={<div>Loading...</div>}>
					<App />
				</Suspense>
			</Router>
		</HighlightStyleProvider>
	</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
