import { WbGrid, WbGridCol, WbGridRow, WbHeading, WbText } from '@workbench/react';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DemoHeaderMenu from '../DemoHeaderMenu/DemoHeaderMenu';
import './DemoInfo.scss';
import ImagePreview from './ImagePreview/ImagePreview';
import VehicleCard from './VehicleCard/VehicleCard';

function DemoInfo(props) {
	const { infoList } = props;
	const { t, i18n } = useTranslation();

	const [type, setType] = useState('exterior');
	const [subType, setSubType] = useState('');
	const [images, setImages] = useState(1);
	const [nightImages, setNightImages] = useState(1);
	const [isLoading, setIsLoading] = useState(false);

	const rdDemoImageGallery = useRef(null);

	useMemo(async () => {
		const wbImages = [];
		const wbNightImages = [];

		setIsLoading(true);

		const promiseList = PromiseHandler360(infoList.images);
		const promiseListNight = PromiseHandler360(infoList.night.images);

		Promise.all(promiseList).then((values) => {
			for (const imageURL of values) {
				wbImages.push({
					highRes: { src: imageURL },
				});
			}
		});

		Promise.all(promiseListNight).then((values) => {
			for (const imageURL of values) {
				wbNightImages.push({
					highRes: { src: imageURL },
				});
			}

			setImages(wbImages);
			setNightImages(wbNightImages);
			setIsLoading(false);
		});
	}, [infoList]);


	const fullscreenToggle = () => {
		if (document.fullscreenElement) {
			document.exitFullscreen();
		}
		else {
			rdDemoImageGallery.current.requestFullscreen();
		}
	}

	return (
		<>
			<WbGrid fullWidth className="rd-demo-heading">
				<WbGridRow>
					<WbGridCol mq5={5} mq4={12} mq1={12} className="rd-demo-heading__text">
						<WbHeading size="l">{infoList.name[i18n.language === 'de' ? 1 : 0]}</WbHeading>
						<WbText size="m">
							{t("demoPage.info.ctaText")}
						</WbText>
					</WbGridCol>
					<WbGridCol mq5={7} mq4={12} mq1={12} className="rd-demo-heading__buttons">
						<DemoHeaderMenu />
					</WbGridCol>
				</WbGridRow>
			</WbGrid>

			<WbGrid fullWidth>
				<WbGridRow className="rd-demo-info">
					<WbGridCol mq5={8} mq4={8} mq1={12} ref={rdDemoImageGallery} className="rd-demo-info__galery ">
						<ImagePreview
							routeId={infoList.routeId}
							type={type}
							subType={subType}
							images={images}
							nightImages={nightImages}
							textureUrls={infoList.bisImages}
							textureUrlsNight={infoList.night.bisImages}
							isLoading={isLoading}
							interiorImage={infoList.interiorImage}
							interiorImages={infoList.interiorImages}
							exteriorImages={infoList.images}
							exteriorImagesNight={infoList.night.images}
							interiorImagesNight={infoList.night.interiorImages}
							isWindowOpen={infoList.interiorImage ? true : false}
							windowImages={[infoList.interiorImage, infoList.exteriorImage]}
							trunkImage={infoList.trunkImage}
							trunkImageNight={infoList.night.trunkImage}
							typeHandler={setType}
							subTypeHandler={setSubType}
							fullscreenToggle={fullscreenToggle}
							disableNight={infoList.disableNight}
						/>
					</WbGridCol>
					<WbGridCol className="rd-demo-vehicle" mq5={4} mq4={4} mq1={12}>
						<VehicleCard infoList={infoList} />
					</WbGridCol>
				</WbGridRow>
			</WbGrid>
		</>
	);
}

function PromiseHandler360(images) {
	const promiseList = [];

	for (const image of images) {
		const imagePromise = new Promise((resolve, reject) => {
			fetch(image).then((respond) => {
				respond.blob().then((blob) => {
					const imageURL = URL.createObjectURL(blob);
					resolve(imageURL);
				});
			});
		});

		promiseList.push(imagePromise);
	}

	return promiseList;
}

export default DemoInfo;
