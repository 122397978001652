/* eslint-disable no-shadow */
import { useEffect, useState } from 'react';

/**
 * Performs an Action once the user hasn't clicked for timeout milliseconds.
 * @param timeout number of milliseconds to wait
 * @param action Action to perform then
 * @param reset What to do when they clicked, before restting the Wait-Timer
 */
export const useInactivity = (timeout, action, reset) => {
    useEffect(() => {
        let id = setTimeout(() => {
            action();
        }, timeout);
        document.addEventListener('pointerdown', () => {
            reset();
            clearTimeout(id);
            id = setTimeout(() => {
                action();
            }, timeout);
        });
    }, [timeout, action, reset]);
};
/**
 * A Counter counting up every rate milliseconds
 * @param rate number of milliseconds between increments
 * @param start where to start counting (default = 0)
 * @param inc by how much to increase at each increment step (default = 1)
 * @returns the current value of the Counter nd a function to reset the counter
 */
export const useCounter = (rate, start, inc) => {
    const [current, setCurrent] = useState(start ?? 0);
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrent(current => current + (inc ?? 1));
        }, rate);
        return () => {
            clearInterval(interval);
        };
    }, [rate, inc]);
    return [
        current,
        () => {
            setCurrent(start ?? 0);
        }
    ];
};
