import { WbGrid, WbGridCol, WbGridRow, WbButton } from '@workbench/react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import './Demo.scss';
import DemoHeader from '../../Components/Demo/DemoHeader/DemoHeader';
import DemoContent from '../../Components/Demo/DemoContent/DemoContent';
import RDBox from '../../Components/Common/RDBox/RDBox';
import { useSiteStyles } from '../../Contexts/StyleContext';

function Demo() {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();

	const { setSiteStyles } = useSiteStyles();

	let resetStyles = {
		border: '',
		key: '',
	};

	useEffect(() => {
		setSiteStyles(resetStyles);
	}, []);

	return (
		<WbGrid>
			<WbGridRow>
				<WbGridCol mq1={12}>
					<RDBox className="rd-demo" direction="vertical">
						<DemoHeader />
						<WbGridCol mq1={12}>
							{/* <WbButton
								className="demo-video-playlist-button"
								variant="secondary"
								onClick={() => window.open('video-playlist', '_blank', 'noopener,noreferrer')}
							>
								{t('gettingStarted.videoPlaylistButton')}
							</WbButton> */}
							<WbButton
								className="demo-vin-vehicle-button"
								variant="secondary"
								onClick={() => navigate(`/vehicle`)}
							>
								{t('gettingStarted.vinVehicleDemoButton')}
							</WbButton>
						</WbGridCol>
						<DemoContent />
					</RDBox>
				</WbGridCol>
			</WbGridRow>
		</WbGrid>
	);
}

export default Demo;
