import { WbIcon, WbText, WbTooltip } from '@workbench/react';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import checkHighlight from "../../../../../Utils/checkHighlightKeyValue";
import RDBox from '../../../../Common/RDBox/RDBox';
import './ImagePreviewController.scss';

import { createUseStyles } from 'react-jss';
import { useSiteStyles } from "../../../../../Contexts/StyleContext";

const useStyles = createUseStyles((theme) => ({
	siteStyles: (siteStyles) => ({
		border: siteStyles.border,
		key: siteStyles.key
	}),
}));

const headerMenuConstants = [
	// {
	// 	key: "video",
	// 	type: "icon",
	// 	defaultKey: "video",
	// 	tooltipText: "demoPage.info.tooltip.video",
	// 	content: {
	// 		icon: "bds/media-play/24"
	// 	}
	// },
	{
		key: 'exterior',
		type: 'icon',
		defaultKey: '360-view-exterior',
		tooltipText: 'demoPage.info.tooltip.exterior',
		content: {
			icon: 'coupe',
		},
	},
	{
		key: 'interior',
		type: 'icon',
		defaultKey: '360-view-interior',
		tooltipText: 'demoPage.info.tooltip.interior',
		content: {
			icon: 'seat',
		},
	},
];

const subHeaderConstant = {
	interior: [
		{
			key: '360-view-interior',
			type: 'icon',
			tooltipText: 'demoPage.info.tooltip.show360',
			content: {
				icon: 'view-360',
			},
		},
		{
			key: 'image-gallery',
			type: 'icon',
			tooltipText: 'demoPage.info.tooltip.showImages',
			content: {
				icon: 'bds/grid-view-4/16',
			},
		},
		{
			key: 'trunk',
			type: 'text',
			tooltipText: 'demoPage.info.tooltip.trunk',
			content: {
				text: 'T',
			},
		},
		{
			key: 'night-view',
			type: 'icon',
			tooltipText: 'demoPage.info.tooltip.lightOff',
			content: {
				icon: 'bds/light-off/24',
			}
		},
		{
			key: 'fullscreen-view',
			type: 'icon',
			tooltipText: 'demoPage.info.tooltip.enterFullscreen',
			content: {
				icon: 'bds/fullscreen/16',
			}
		}
	],
	exterior: [
		{
			key: '360-view-exterior',
			type: 'icon',
			tooltipText: 'demoPage.info.tooltip.show360',
			content: {
				icon: 'view-360',
			},
		},
		{
			key: 'image-gallery',
			type: 'icon',
			tooltipText: 'demoPage.info.tooltip.showImages',
			content: {
				icon: 'bds/grid-view-4/16',
			},
		},
		{
			key: 'night-view',
			type: 'icon',
			tooltipText: 'demoPage.info.tooltip.lightOff',
			content: {
				icon: 'bds/light-off/24',
			}
		},
		{
			key: 'fullscreen-view',
			type: 'icon',
			tooltipText: 'demoPage.info.tooltip.enterFullscreen',
			content: {
				icon: 'bds/fullscreen/16',
			}
		}
	],
	video: [
		{
			key: 'fullscreen-view',
			type: 'icon',
			tooltipText: 'demoPage.info.tooltip.enterFullscreen',
			content: {
				icon: 'bds/fullscreen/16',
			}
		}
	]
};

function ImagePreviewController(props) {
	const { type, typeHandler, subType, subTypeHandler, availablePanels, availableSubPanels, fullscreenToggle, night, setNight } = props;
	const [fullscreen, setFullscreen] = useState(false);
	const { t } = useTranslation();
	const tooltipKey = useRef(0);

	useEffect(() => {
		tooltipKey.current += 1;
	}, [t])

	const [fullable, setFullable] = useState(true);

	const changeFullscreenButtonValues = fs => {
		const exterior = subHeaderConstant.exterior.find(e => e.key === "fullscreen-view");
		const interior = subHeaderConstant.interior.find(e => e.key === "fullscreen-view");
		const video = subHeaderConstant.video.find(e => e.key === "fullscreen-view");

		if (!fs) {
			exterior.tooltipText = "demoPage.info.tooltip.exitFullscreen";
			exterior.content.icon = "bds/fullscreen-exit/16";
			interior.tooltipText = "demoPage.info.tooltip.exitFullscreen";
			interior.content.icon = "bds/fullscreen-exit/16";
			video.tooltipText = "demoPage.info.tooltip.exitFullscreen";
			video.content.icon = "bds/fullscreen-exit/16";
		}
		else {
			exterior.tooltipText = "demoPage.info.tooltip.enterFullscreen";
			exterior.content.icon = "bds/fullscreen/16";
			interior.tooltipText = "demoPage.info.tooltip.enterFullscreen";
			interior.content.icon = "bds/fullscreen/16";
			video.tooltipText = "demoPage.info.tooltip.enterFullscreen";
			video.content.icon = "bds/fullscreen/16";
		}
	}
	const changeNightButtonValues = val => {
		const exterior = subHeaderConstant.exterior.find(e => e.key === "night-view");
		const interior = subHeaderConstant.interior.find(e => e.key === "night-view");

		if (!val) {
			exterior.tooltipText = "demoPage.info.tooltip.lightOff";
			exterior.content.icon = "bds/light-off/24";
			interior.tooltipText = "demoPage.info.tooltip.lightOff";
			interior.content.icon = "bds/light-off/24";
		}
		else {
			exterior.tooltipText = "demoPage.info.tooltip.lightOn";
			exterior.content.icon = "bds/light-on/24";
			interior.tooltipText = "demoPage.info.tooltip.lightOn";
			interior.content.icon = "bds/light-on/24";
		}
	}

	const handleFullscreenEvent = () => {
		changeFullscreenButtonValues(!document.fullscreenElement);
		setFullscreen(!fullscreen);
	}

	window.addEventListener("fullscreenchange", handleFullscreenEvent);

	useEffect(() => {
		typeHandler('exterior');
		subTypeHandler(defaultKeyFinder('exterior', availableSubPanels));
	}, []);

	
	const { siteStyles } = useSiteStyles();
	const classes = useStyles(siteStyles);

	useEffect(() => {
		if(siteStyles.key === "video") {
			typeHandler("video");
			subTypeHandler(defaultKeyFinder("video", availableSubPanels));
		} else if(siteStyles.key === "interior") {
			typeHandler("interior");
			subTypeHandler(defaultKeyFinder("interior", availableSubPanels));
		} else {
			typeHandler("exterior");
			subTypeHandler(defaultKeyFinder("exterior", availableSubPanels));
		}
	}, [siteStyles.key]);


	const classNameSelector = (key, selectedType) => {
		return `rd-demo-controller__icon-container ${key === selectedType ? 'active' : ''} ${key===selectedType && checkHighlight(siteStyles.key) ? classes.siteStyles : null}`;
	};

	const subClassNameSelector = (key, selectedType) => {
		return `rd-demo-controller__icon-container ${key === selectedType ? 'active' : ''}`;
	};

	return (
		<RDBox className="rd-demo-controller" wrap data-testid="image-preview-controller">
			<div className="rd-controller-buttons__left">
				{headerMenuConstants
					.filter(function (e, index, array) {
						return availablePanels[e.key];
					})
					.map((item) => (
						<RDBox
							key={item.key}
							onClick={() => {
								setFullable(true);
								typeHandler(item.key);
								subTypeHandler(defaultKeyFinder(item.key, availableSubPanels));
							}}
							className="rd-demo-controller__button"
							align="center"
							direction="vertical"
							size={16}
							data-testid={`image-preview-controller-main-${item.key}`}
						>
							<WbTooltip key={tooltipKey.current}>
								<div className={classNameSelector(item.key, type)} data-testid={`image-preview-controller-main-${item.key}-highlight`}>
									<ControllerButton item={item} typeHandler={typeHandler} />
								</div>
								<div slot="content">{t(item.tooltipText)}</div>
							</WbTooltip>
						</RDBox>
					))}
			</div>

			{subHeaderConstant[type] && (
				<div className="rd-demo-divider">
					<div className="rd-demo-divider__line" />
				</div>
			)}
			<div className="rd-controller-buttons__right">
				{subHeaderConstant[type] &&
					subHeaderConstant[type]
						.filter(function (e) {
							return availableSubPanels[type][e.key];
						})
						.map((item) => (
							<RDBox
								key={item.key}
								onClick={() => {
									setFullable(item.key !== "image-gallery");
									//Prevent to call subTypeHandler when clicked to fullscreen button 
									if (item.key === 'fullscreen-view') {
										fullscreenToggle();
									}
									else if (item.key === 'night-view') {
										changeNightButtonValues(!night);
										setNight(!night);
									}
									else {
										subTypeHandler(item.key);
									}
								}}
								className="rd-demo-controller__button"
								align="center"
								direction="vertical"
								size={16}
								fullable={fullable.toString()}
								data-testid={`image-preview-controller-${item.key}`}
							>
								<WbTooltip key={tooltipKey.current}>
									<div aria-label='enter-fullscreen' className={subClassNameSelector(item.key, subType)}>
										<ControllerButton item={item} typeHandler={typeHandler} />
									</div>
									<div slot="content">{t(item.tooltipText)}</div>
								</WbTooltip>
							</RDBox>
						))}
			</div>
		</RDBox>
	);
}

function ControllerButton(props) {
	const { item } = props;

	switch (item.type) {
		case 'icon':
			return <WbIcon name={item.content.icon} />;
		case 'text':
			return <WbText strong>{item.content.text}</WbText>;
	}
}

//Returns available first subtype as a default key finder
function defaultKeyFinder(type, availableSubTypes) {
	const availableSubType = subHeaderConstant[type].find((element) => {
		return availableSubTypes[type][element.key];
	});

	return availableSubType.key;
}

export default ImagePreviewController;
