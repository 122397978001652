/* eslint-disable consistent-return */
import React, { useEffect, useRef, useState } from 'react';
import { usePlayerStore } from '../../Utils/VideoPlaylist/lib/stores';

const playerSettings = {
    showTimeline: true,
    posterFrame: 1070,
    cinematic: false,
    hideFullScreenBtn: true,
    colors: {
        loader: '#e1ccdc',
        ctrlBtns: '#fff',
        thumb: '#0078D6',
        progress: '#0078D6'
    },
    muted: true,
    autoplay: true,
    autoReplay: false
};
export default function Player({ data }) {

    // Blings format check
    const isMobile = window.BlingsPlayer.helpers.utils.isMobile();
    const ref = useRef(null);
    const setCurrentPlayer = usePlayerStore(state => state.setCurrentPlayer);
    const hasTouch = usePlayerStore(state => state.hasTouch);
    const scenes = usePlayerStore(state => state.scenes);
    const idle = usePlayerStore(state => state.idle);
    const player = useRef();
    const [playerState, setPlayerState] = useState('waiting');
    const [outputFormat, setOutputFormat] = useState(isMobile);

    useEffect(() => {
        // if we are idle and currently have a player open that has ended, close it
        if (playerState === 'done' && idle && player.current != null) {
            // Return from Modal Window
            setCurrentPlayer(undefined);
            player.current.destroy();
            player.current = undefined;
            setPlayerState('waiting');
        }
    }, [idle, playerState, setCurrentPlayer]);
    useEffect(() => {
        if (ref.current == null || data == null) {
            return;
        }
        const container = ref.current;
        container.innerHTML = '';
        const settings = { ...playerSettings, container };
        window.BlingsPlayer.create({
            project: {
                id: outputFormat ? 'm-a7b2caa6-9374-4934-ac61-534e9ec3766a' : 'a7b2caa6-9374-4934-ac61-534e9ec3766a'
            },
            data,
            scenes: scenes ?? ['startNoSpec', 'spec', 'outroNoEnd', 'endScreen'],
            settings
        })
            .then(newPlayer => {
                setPlayerState('playing');
                player.current = newPlayer;
                player.current.EE.on('onComplete', () => {
                    setPlayerState('done');
                });
            })
            .catch(error => {
                throw error;
            });
        return () => {
            player.current?.destroy();
            container.innerHTML = '';
        };
    }, [data, hasTouch, setCurrentPlayer, scenes]);

    return (
        <figure className="h-0 grow">
            <div ref={ref}>Hello, I&aposm a Player</div>
        </figure>
    );
}
