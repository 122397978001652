import { WbIcon, WbText, WbLink, WbPopover } from '@workbench/react';
import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import './DemoHeaderMenu.scss';
import RDBox from '../../Common/RDBox/RDBox';
import { createUseStyles } from 'react-jss';
import { useSiteStyles } from '../../../Contexts/StyleContext';
import useDebouncedEffect from '../../../Utils/useDebouncedEffect';
import headerMenuConstants from '../../../Constants/headerMenuConstants';

const useStyles = createUseStyles((theme) => ({
	siteStyles: (siteStyles) => ({
		border: siteStyles.border,
	}),
}));

function TooltipWrapper(props) {
	const { activeItem, headerItem, children } = props;

	const [isHover, setIsHover] = useState(false);
	const [showPopover, setShowPopover] = useState(false);

	useDebouncedEffect(
		() => {
			if (isHover) {
				setShowPopover(true);
			} else {
				setShowPopover(false);
			}
		},
		[isHover],
		30
	);

	const isPopoverVisible = activeItem === headerItem.key;

	if (window.location.pathname === '/demo') {
		return children;
	}

	return (
		<div>
			<WbPopover
				open={showPopover}
				onMouseEnter={() => {
					setIsHover(true);
				}}
				onMouseLeave={() => {
					setIsHover(false);
				}}
				style={isPopoverVisible ? {} : { display: 'none' }}
				data-testid={`${headerItem.tooltip}`}
			>
				<div slot="content" className="popover">
					<WbText size="m">
						<Trans
							i18nKey={headerItem.tooltip}
							components={{
								Link: (
									<WbLink
										href={headerItem.href}
										rel="noopener noreferrer"
										target="_blank"
										variant="inline"
									/>
								),
								bold: <strong />,
							}}
						/>
					</WbText>
				</div>
				<div>{children}</div>
			</WbPopover>
			{!isPopoverVisible && children}
		</div>
	);
}

function DemoHeaderMenu() {
	const { t } = useTranslation();
	const { siteStyles, setSiteStyles } = useSiteStyles();
	const classes = useStyles(siteStyles);
	const [activeItem, setActiveItem] = useState(null);

	const handleHighlight = (key) => {
		let newStyles = {
			border: '5px solid #9AD2FE',
			key: '',
		};
		if (window.location.pathname === '/demo') {
			return;
		}

		switch (key) {
			case 'exterior':
				newStyles.key = 'exterior';
				break;
			case 'interior':
				newStyles.key = 'interior';
				break;
			case 'equipment':
				newStyles.key = 'equipment';
				break;
			case 'video':
				newStyles.key = 'video';
				break;
			case 'specifications':
				newStyles.key = 'specifications';
				break;
			case 'me-services':
				newStyles.key = 'me-services';
				break;
			default:
				break;
		}

		setSiteStyles(newStyles);
		if (activeItem === key) {
			setActiveItem(null);
			setSiteStyles({
				border: 'none',
				key: '',
			});
			return;
		} else {
			setActiveItem(key);
		}
	};

	return (
		<RDBox className="rd-demo-header-menu" wrap>
			{headerMenuConstants.map((e) => (
				<TooltipWrapper key={e.key} headerItem={e} activeItem={activeItem}>
					<RDBox
						key={e.key}
						className="rd-demo-header-menu__item"
						align="center"
						direction="vertical"
						size={16}
						onClick={() => {
							handleHighlight(e.key);
						}}
						data-testid={`highlight-${e.key}`}
					>
						<div
							className={`rd-demo-header-menu__item__icon-container ${
								activeItem === e.key ? classes.siteStyles : null
							}`}
							style={
								window.location.pathname === '/demo'
									? { pointerEvents: 'none', backgroundColor: 'initial', cursor: 'none' }
									: {}
							}
							data-testid={`product-icon-${e.key}`}

						>
							<WbIcon name={e.icon} />
						</div>
						<WbText size="m" strong>
							{t(`demoPage.header.headerMenu.${e.key}.title`)}
						</WbText>
					</RDBox>
				</TooltipWrapper>
			))}
		</RDBox>
	);
}

export default DemoHeaderMenu;
