import { Outlet } from 'react-router-dom';
import './Layout.scss';
import Header from './Header/Header';
import Footer from './Footer/Footer';

function Layout() {
	return (
		<div className="App">
			<header>
				<Header />
			</header>
			<main>
				<Outlet />
			</main>
			<footer>
				<Footer />
			</footer>
		</div>
	);
}

export default Layout;
