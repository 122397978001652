import React from 'react';
import { WbGrid, WbGridCol, WbGridRow, WbHeading, WbText, WbBreadcrumbs, WbBreadcrumb } from '@workbench/react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DemoHeaderMenu from '../DemoHeaderMenu/DemoHeaderMenu';
import './DemoHeader.scss';
import RDBox from '../../Common/RDBox/RDBox';

function DemoHeader() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const handleBreadCrumbClick = () => {
		navigate(`/home`);
	};

	return (
		<RDBox className="rd-demo-header" direction="vertical">
			<WbBreadcrumbs>
				<WbBreadcrumb onClick={handleBreadCrumbClick} data-testid="demo-header-nav">{t("breadcrumb.home")}</WbBreadcrumb>
				<WbBreadcrumb>{t("breadcrumb.demo")}</WbBreadcrumb>
			</WbBreadcrumbs>
			<WbGrid fullWidth>
				<WbGridRow className="rd-demo-header__main" style={{ alignItems: 'bottom' }}>
					<WbGridCol mq1={12}>
						<RDBox className="rd-demo-header__title" direction="vertical" align="start">
							<WbHeading size="l">{t('demoPage.header.title')}</WbHeading>
							<WbText>{t('demoPage.header.content')}</WbText>
						</RDBox>
					</WbGridCol>
					<WbGridCol className="rd-demo-header__menu" mq1={12}>
						<DemoHeaderMenu />
					</WbGridCol>
				</WbGridRow>
			</WbGrid>
		</RDBox>
	);
}

export default DemoHeader;
