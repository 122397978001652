const classList = [
	{
		key: 1,
		description: 'EQS 53 4MATIC+',
		imageUrl: `${process.env.PUBLIC_URL}/images/Vehicles/EQS-53-4MATIC+.webp`,
		routeId: 'EQS',
	},
	{
		key: 2,
		description: 'S 580 e 4MATIC',
		imageUrl: `${process.env.PUBLIC_URL}/images/Vehicles/S-580-e-4MATIC.webp`,
		routeId: 'S-Class-Hybrid',
	},
	{
		key: 3,
		description: 'SL 55 4MATIC+',
		imageUrl: `${process.env.PUBLIC_URL}/images/Vehicles/SL-55-4MATIC+.webp`,
		routeId: 'SL-AMG',
	},
	{
		key: 4,
		description: 'smart EQ fortwo',
		imageUrl: `${process.env.PUBLIC_URL}/images/Vehicles/smart-EQ-fortwo.webp`,
		routeId: 'Smart-EQ',
	},
	{
		key: 5,
		description: 'Sprinter 311 CDI',
		imageUrl: `${process.env.PUBLIC_URL}/images/Vehicles/Sprinter-311-CDI.webp`,
		routeId: 'Sprinter',
	},
	{
		key: 6,
		description: 'V 300 d',
		imageUrl: `${process.env.PUBLIC_URL}/images/Vehicles/V-300-d.webp`,
		routeId: 'V-Class',
	},
];

export default classList;