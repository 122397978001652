import { WbGrid, WbGridCol, WbGridRow } from '@workbench/react';
import React, { useState, useEffect } from 'react';
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';
import useWidth from '../../../Utils/useWidth';
import { useTranslation } from 'react-i18next';

function TopHeader() {
	const [expandedMenu, setExpandedMenu] = useState(false);
	const width = useWidth();
	useEffect(() => {
		adaptNavbar();
	}, [width]);

	const toggleTheMobileMenu = () => {
		setExpandedMenu(!expandedMenu);
	};

	function adaptNavbar() {
		if (window.innerWidth >= 992) {
			setExpandedMenu(false);
		}
	}

	return (
		<div className="top-header">
			<WbGrid>
				<WbGridRow>
					<WbGridCol mq3={6} mq1={11}>
						<div className="logo__mb-developers" />
					</WbGridCol>
					<WbGridCol mq3={6} mq1="hide">
						<div className="logo__retailer-dashboard" />
					</WbGridCol>
					<WbGridCol className="top-header__expand-icon">
						{expandedMenu ? (
							<AiOutlineClose className="top-header__MobileMenuIcon close-menu" data-testid="header-close-menu" onClick={toggleTheMobileMenu} />
						) : (
							<AiOutlineMenu className="top-header__MobileMenuIcon open-menu" data-testid="header-open-menu" onClick={toggleTheMobileMenu} />
						)}
					</WbGridCol>
					{expandedMenu && <ExtendedHeader />}
				</WbGridRow>
			</WbGrid>
		</div>
	);
}

function ExtendedHeader() {
	const { t } = useTranslation();

	return (
		<div className="top-header__ExpandedHeaderLinks">
			<span className="HeaderLink">
				<a href={t('mainScreen.header.dataLink')} rel="noopener noreferrer" target="_blank">
					{t('mainScreen.header.mercedesBenzData')}
				</a>
			</span>
			<span className="HeaderLink">
				<a
					href="https://developer.mercedes-benz.com/products?u=Retailer"
					rel="noopener noreferrer"
					target="_blank"
				>
					{t('mainScreen.header.products')}
				</a>
			</span>
			<span className="HeaderLink">
				<a
					href="https://developer.mercedes-benz.com/contact/vehicle_images/business_inquiry"
					rel="noopener noreferrer"
					target="_blank"
				>
					{t('mainScreen.header.contact')}
				</a>
			</span>
			<span className="HeaderLink">
				<a
					href="https://developer.mercedes-benz.com/contact/vehicle_images/technical_inquiry"
					rel="noopener noreferrer"
					target="_blank"
				>
					{t('mainScreen.header.support')}
				</a>
			</span>
		</div>
	);
}

export default TopHeader;
