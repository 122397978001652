const footer = [
	{
		key: 1,
		url: 'https://developer.mercedes-benz.com/legal/imprint',
		value: 'imprint'
	},
	{
		key: 2,
		url: 'https://developer.mercedes-benz.com/legal/notice',
		value: 'legalNotice',
	},
	{
		key: 3,
		url: 'https://developer.mercedes-benz.com/legal/cookies',
		value: 'cookies',
	},
	{
		key: 4,
		url: 'https://developer.mercedes-benz.com/legal/privacy',
		value: 'privacyStatement',
	},
	{
		key: 5,
		url: 'https://developer.mercedes-benz.com/legal/terms',
		value: 'termsOfUse',
	},
];

export default footer;