import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WbGridCol, WbText } from '@workbench/react';
import EquipmentDetail from './Equipment/EquipmentDetail';
import './DemoTabContents.scss';
import { RdCardButtonBar, RdCard, RdCardDetails } from '../../Common/RDCard/RDCard';

const routeIdMap = { "EQS": "EQS", "S-Class-Hybrid": "S580", "SL-AMG": "Sclass", "Smart-EQ": "Smart", "Sprinter": "Sprinter", "V-Class": "V300" }

function NoDataContent() {
	const { t } = useTranslation();
	return (
		<WbGridCol mq1={12}>
			<WbText>{t('demoTabs.content.noDataAvailable')}</WbText>
		</WbGridCol>
	);
}

function SimpleTabContent({ detail, className}) {
	const { t } = useTranslation();
	return (
		<WbGridCol mq5={4} mq4={4} mq1={12} key={detail.key} className={className} data-testid="demo-tab-content-simple">
			<WbText size="m" className="rd-demo-content-simple__text">
				{t(`demoPage.${detail.key}`)}
			</WbText>
			<WbText size="l" strong>
				{detail.value}
			</WbText>
		</WbGridCol>
	);
}

function MeServicesTabContent({ vehicleData, equipmentHighlightFilter, tab }) {
	const { i18n } = useTranslation();
	const [mmesData, setMmesData] = useState([]);

	useEffect(() => {
		const fillMmesData = async () => {
			const importedMmes = await import(`../../../Constants/mmesData${i18n.language === 'de' ? 'DE' : ''}/${routeIdMap[vehicleData.routeId]}.json`);
			const mmesArray = Object.keys(importedMmes).filter(e => parseFloat(e)).map(e => importedMmes[e]);

			setMmesData(mmesArray);
		}
		fillMmesData();

	}, [i18n.language]);


	return mmesData.map((detail, index) => <WbGridCol key={index} mq1={12} mq3={6} mq4={6} mq5={4} className="rd-demo-content__element" data-testid={`demo-tab-content-${tab.name}-card`}>
			<RdCard
				icon={detail.serviceIconImage}
				title={detail.heading}
				rightTag={detail.subHeading}
				img={detail.primaryImage}
			>
				<RdCardDetails icon={detail.serviceIconImage} title={detail.heading}>
					<WbText size="s">
						{/* eslint-disable-next-line react/no-danger */}
						<div dangerouslySetInnerHTML={{ __html: detail.summary }} />
					</WbText>
				</RdCardDetails>
				<RdCardButtonBar showMoreDetailsButton equipmentHighlightFilter={equipmentHighlightFilter} />
			</RdCard>
		</WbGridCol>
	);
}

function EquipmentTabContent({ detail, vehicleData, className, equipmentHighlightFilter, tab }) {
	const { t, i18n } = useTranslation();
	const { key: code, value: title } = detail;
	const [steveData, setSteveData] = useState(null);
	useEffect(() => {
		const fillSteveData = async () => {
			const importedSteve = await import(`../../../Constants/steveData${i18n.language === 'de' ? 'DE' : ''}/${routeIdMap[vehicleData.routeId]}.json`);
			const steveArray = Object.keys(importedSteve).filter(e => parseFloat(e)).map(e => importedSteve[e]);
			setSteveData(steveArray.find((item) => item.code === code));
		}
		fillSteveData();

	}, [i18n.language]);

	const hasDetails = steveData?.components.components.length > 0;
	const imgUrls = vehicleData.equipmentImageContext
		.keys()
		.filter((path) => path.includes(code))
		.map((path) => {
			const fileName = path.split('/').pop();
			return `${vehicleData.assetBasePath}/Equipment/${fileName}`;
		});
	const hasHighlight = steveData?.highlight && t('demoTabs.highlight');
	const hasMercedesMe = steveData?.mercedesMe && t('demoTabs.mercedesMe');
	const titleCodeValue = title.substring(title.lastIndexOf('.') + 1);
	const cardTitle = `${t(title)} (${titleCodeValue})`;
	const salesDesignationValue = steveData?.salesDesignation;
	const functionTextValue = steveData?.functionText || '';
	const cardDetailTitle = `${salesDesignationValue} ${functionTextValue}`;
	return (
		<WbGridCol mq1={12} mq3={6} mq4={6} mq5={4} key={code} className={className} data-testid={`demo-tab-content-${tab.name}-card`}>
			<RdCard equipmentHighlightFilter={equipmentHighlightFilter} img={imgUrls} title={cardTitle} rightTag={hasHighlight} leftTag={hasMercedesMe}> 
				{steveData && (
					<RdCardDetails title={cardDetailTitle}>
						<EquipmentDetail steveData={steveData} />
					</RdCardDetails>
				)}
				<RdCardButtonBar showMoreDetailsButton={hasDetails} equipmentHighlightFilter={equipmentHighlightFilter}/>
			</RdCard>
		</WbGridCol>
	);
}

function DemoTabContents({ children, tab, vehicleData, className, equipmentHighlightFilter }) {
	if (tab.details.length === 0) return <NoDataContent />;
	if (tab.name === 'meServices') return <MeServicesTabContent vehicleData={vehicleData} equipmentHighlightFilter={equipmentHighlightFilter} tab={tab}/>;
	return tab.details.map((detail) =>
		React.Children.map(children, (child) => React.cloneElement(child, { tab, detail, vehicleData, className, equipmentHighlightFilter }))
	);
}

const chooseDemoTabContent = (type) => {
	switch (type) {
		case 'equipment':
			return <EquipmentTabContent />;
		default:
			return <SimpleTabContent />;
	}
};

export { DemoTabContents, chooseDemoTabContent };
