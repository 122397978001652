const EqsData = {
	name: [
		'Mercedes-AMG EQS 53 4MATIC+',
		'Mercedes-AMG EQS 53 4MATIC+'
	],
	routeId: 'EQS',
	assetBasePath: `${process.env.PUBLIC_URL}/images/Vehicles/EQS`,
	equipmentImageContext: require.context('/public/images/Vehicles/EQS/Equipment', true, /\.(jpe?g|webp)$/i),
	night: {
		images: [
			`${process.env.PUBLIC_URL}/images/Vehicles/EQS/night/EXT000.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/EQS/night/EXT010.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/EQS/night/EXT020.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/EQS/night/EXT030.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/EQS/night/EXT040.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/EQS/night/EXT050.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/EQS/night/EXT060.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/EQS/night/EXT070.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/EQS/night/EXT080.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/EQS/night/EXT090.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/EQS/night/EXT100.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/EQS/night/EXT110.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/EQS/night/EXT120.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/EQS/night/EXT130.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/EQS/night/EXT140.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/EQS/night/EXT150.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/EQS/night/EXT160.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/EQS/night/EXT170.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/EQS/night/EXT180.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/EQS/night/EXT190.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/EQS/night/EXT200.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/EQS/night/EXT210.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/EQS/night/EXT220.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/EQS/night/EXT230.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/EQS/night/EXT240.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/EQS/night/EXT250.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/EQS/night/EXT260.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/EQS/night/EXT270.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/EQS/night/EXT280.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/EQS/night/EXT290.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/EQS/night/EXT300.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/EQS/night/EXT310.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/EQS/night/EXT320.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/EQS/night/EXT330.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/EQS/night/EXT340.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/EQS/night/EXT350.webp`,
		],
		exteriorImage: `${process.env.PUBLIC_URL}/images/Vehicles/EQS/night/Exterior.webp`,
		interiorImage: `${process.env.PUBLIC_URL}/images/Vehicles/EQS/night/INT1.webp`,
		interiorImages: [
			`${process.env.PUBLIC_URL}/images/Vehicles/EQS/night/INT1.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/EQS/night/INT2.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/EQS/night/INT3.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/EQS/night/INT4.webp`,
		],
		bisImages: [
			`${process.env.PUBLIC_URL}/images/Vehicles/EQS/night/BIS4.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/EQS/night/BIS2.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/EQS/night/BIS5.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/EQS/night/BIS6.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/EQS/night/BIS1.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/EQS/night/BIS3.webp`,
		],

	},
	images: [
		`${process.env.PUBLIC_URL}/images/Vehicles/EQS/EXT000.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/EQS/EXT010.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/EQS/EXT020.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/EQS/EXT030.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/EQS/EXT040.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/EQS/EXT050.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/EQS/EXT060.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/EQS/EXT070.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/EQS/EXT080.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/EQS/EXT090.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/EQS/EXT100.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/EQS/EXT110.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/EQS/EXT120.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/EQS/EXT130.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/EQS/EXT140.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/EQS/EXT150.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/EQS/EXT160.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/EQS/EXT170.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/EQS/EXT180.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/EQS/EXT190.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/EQS/EXT200.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/EQS/EXT210.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/EQS/EXT220.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/EQS/EXT230.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/EQS/EXT240.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/EQS/EXT250.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/EQS/EXT260.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/EQS/EXT270.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/EQS/EXT280.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/EQS/EXT290.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/EQS/EXT300.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/EQS/EXT310.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/EQS/EXT320.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/EQS/EXT330.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/EQS/EXT340.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/EQS/EXT350.webp`,
	],
	exteriorImage: `${process.env.PUBLIC_URL}/images/Vehicles/EQS/Exterior.webp`,
	interiorImage: `${process.env.PUBLIC_URL}/images/Vehicles/EQS/INT1.webp`,
	interiorImages: [
		`${process.env.PUBLIC_URL}/images/Vehicles/EQS/INT1.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/EQS/INT2.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/EQS/INT3.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/EQS/INT4.webp`,
	],
	bisImages: [
		`${process.env.PUBLIC_URL}/images/Vehicles/EQS/BIS4.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/EQS/BIS2.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/EQS/BIS5.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/EQS/BIS6.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/EQS/BIS1.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/EQS/BIS3.webp`,
	],
	vehicleDetails: [
		[
			{ type: 'car', value: 'Sports Tourer' },
			{ type: 'engine', value: '484 kW (658 PS)' },
			{ type: 'mile', value: '220 km/h' },
			{ type: 'fuel', value: 'Electric' },
			{ type: 'transmission', value: 'Automatic transmission' }
		],
		[
			{ type: 'car', value: 'Sports Tourer' },
			{ type: 'engine', value: '484 kW (658 PS)' },
			{ type: 'mile', value: '220 km/h' },
			{ type: 'fuel', value: 'Elektroantrieb' },
			{ type: 'transmission', value: 'Automatikgetriebe' }
		]
	],
	exteriorPaint: `${process.env.PUBLIC_URL}/images/Vehicles/EQS/ExteriorPaint/eqspaint.webp`,
	interiorUpholstery: `${process.env.PUBLIC_URL}/images/Vehicles/EQS/InteriorUpholstery/eqsupholstery.webp`,
	videoDetails: [
		{
			isTouch: false,
			logo: "",
			scene1text: 'Mercedes-AMG EQS 53 4MATIC+',
			scene1image: `${process.env.PUBLIC_URL}/images/Vehicles/EQS/Video/EXT000.webp`,
			scene2text: 'Mercedes-AMG EQS 53 4MATIC+',
			vehicleRotationImages: {
				1: `${process.env.PUBLIC_URL}/images/Vehicles/EQS/Video/EXT300.webp`,
				2: `${process.env.PUBLIC_URL}/images/Vehicles/EQS/Video/EXT310.webp`,
				3: `${process.env.PUBLIC_URL}/images/Vehicles/EQS/Video/EXT320.webp`,
				4: `${process.env.PUBLIC_URL}/images/Vehicles/EQS/Video/EXT330.webp`,
				5: `${process.env.PUBLIC_URL}/images/Vehicles/EQS/Video/EXT340.webp`,
				6: `${process.env.PUBLIC_URL}/images/Vehicles/EQS/Video/EXT350.webp`,
			},
			scene3text: 'EQS - Your dream is closer than ever',
			interiorImages: {
				1: `${process.env.PUBLIC_URL}/images/Vehicles/EQS/Video/INT1.webp`,
				2: `${process.env.PUBLIC_URL}/images/Vehicles/EQS/Video/INT2.webp`,
				3: `${process.env.PUBLIC_URL}/images/Vehicles/EQS/Video/INT3.webp`,
				4: `${process.env.PUBLIC_URL}/images/Vehicles/EQS/Video/INT4.webp`,
			},
			details: {
				textcolorblack: false,
				title1: 'Paint',
				text1: 'Designo selenite grey magno',
				title2: 'Upholstery',
				text2: 'Nappa leather black',
				title3: 'Engine',
				text3: 'Electric',
				title4: 'Power (PS/KW)',
				text4: '658 / 484',
				bgimage: `${process.env.PUBLIC_URL}/images/Vehicles/EQS/Video/EXT040.webp`,
			},
			packages2: {
				title: 'Equipments highlights.',
				1: {
					image: 'https://assets.oneweb.mercedes-benz.com/bbd/images/v1/4933/5/f0/f040b0753b28dd74e22fa6fe843053ecdcb75.jpg',
					text: 'Panoramic sunroof',
					detailslink: '',
					code: '413',
				},
				2: {
					image: 'https://assets.oneweb.mercedes-benz.com/bbd/images/v1/4933/b/78/2d185e9a70cf138bda91f4c0b8e26ff56a090.jpg',
					text: 'AMG carbon trim',
					detailslink: '',
					code: 'H73',
				},
			},
			packages3: {},
			outroText: 'Mercedes-Benz. The best or nothing.',
			endText: 'Experience now the Mercedes-AMG EQS 53 4MATIC+',
			endBtnLink: 'https://www.mercedes-benz.com',
			endBtnText: 'Contact us',
			endImage: '',
			endImageTransparent: `${process.env.PUBLIC_URL}/images/Vehicles/EQS/Video/EXT040.webp`,
			endBkgImage:
				'https://assets.oneweb.mercedes-benz.com/bbd/images/v1/4216/3/ce/f19bab99ea61f29d8282b8d9d13b82b758673.jpg',
			qr: 'https://0cn.de/pfbdb+qr-400.png',
		},
		{
			isTouch: false,
			logo: "",
			scene1text: 'Mercedes-AMG EQS 53 4MATIC+',
			scene1image: `${process.env.PUBLIC_URL}/images/Vehicles/EQS/Video/EXT000.webp`,
			scene2text: 'Mercedes-AMG EQS 53 4MATIC+',
			vehicleRotationImages: {
				1: `${process.env.PUBLIC_URL}/images/Vehicles/EQS/Video/EXT300.webp`,
				2: `${process.env.PUBLIC_URL}/images/Vehicles/EQS/Video/EXT310.webp`,
				3: `${process.env.PUBLIC_URL}/images/Vehicles/EQS/Video/EXT320.webp`,
				4: `${process.env.PUBLIC_URL}/images/Vehicles/EQS/Video/EXT330.webp`,
				5: `${process.env.PUBLIC_URL}/images/Vehicles/EQS/Video/EXT340.webp`,
				6: `${process.env.PUBLIC_URL}/images/Vehicles/EQS/Video/EXT350.webp`,
			},
			scene3text: 'EQS - Your dream is closer than ever',
			interiorImages: {
				1: `${process.env.PUBLIC_URL}/images/Vehicles/EQS/Video/INT1.webp`,
				2: `${process.env.PUBLIC_URL}/images/Vehicles/EQS/Video/INT2.webp`,
				3: `${process.env.PUBLIC_URL}/images/Vehicles/EQS/Video/INT3.webp`,
				4: `${process.env.PUBLIC_URL}/images/Vehicles/EQS/Video/INT4.webp`,
			},
			details: {
				textcolorblack: false,
				title1: "Farbe",
				text1: "Designo selenitgrau magno",
				title2: "Polster",
				text2: "Leder Nappa AMG schwarz / spacegrau",
				title3: "Motor",
				text3: "Elektroantrieb",
				title4: "Leistung (PS/KW)",
				text4: "658 / 484",
				bgimage: `${process.env.PUBLIC_URL}/images/Vehicles/EQS/Video/EXT040.webp`,
			},
			packages2: {
				1: {
					image: "https://assets.oneweb.mercedes-benz.com/bbd/images/v1/4933/5/f0/f040b0753b28dd74e22fa6fe843053ecdcb75.jpg",
					text: "Panorama-Schiebedach",
					detailslink: "",
					code: "413"
				},
				2: {
					image: "https://assets.oneweb.mercedes-benz.com/bbd/images/v1/4933/b/78/2d185e9a70cf138bda91f4c0b8e26ff56a090.jpg",
					text: "AMG Zierelemente Carbon",
					detailslink: "",
					code: "H73"
				},
				title: "Ausstattungshighlights."
			},
			packages3: {},
			outroText: "Mercedes-Benz. Das Beste oder nichts.",
			endText: "Erleben Sie jetzt den Mercedes-AMG EQS 53 4MATIC+",
			endBtnLink: "https://www.mercedes-benz.de",
			endBtnText: "Jetzt Anfragen",
			endImage: '',
			endImageTransparent: `${process.env.PUBLIC_URL}/images/Vehicles/EQS/Video/EXT040.webp`,
			endBkgImage:
				'https://assets.oneweb.mercedes-benz.com/bbd/images/v1/4216/3/ce/f19bab99ea61f29d8282b8d9d13b82b758673.jpg',
			qr: 'https://0cn.de/pfbdb+qr-400.png',
		},
	],
	tabs: [
		{
			name: 'generalData',
			details: [
				{ key: 'numberOfDoors', value: '4' },
				{ key: 'numberOfSeats', value: '5' },
				{ key: 'length', value: '5.223 mm' },
				{ key: 'emptyWeight', value: '2.625 kg' },
				{ key: 'payload', value: '525 kg' },
				{ key: 'maxWeight', value: '3.225 kg' }
			],
		},
		{
			name: 'packages',
			details: [
				{ key: '275', value: 'demoPage.equipmentsComponents.demoEqsConstants.packages.275' },
				{ key: 'PDC', value: 'demoPage.equipmentsComponents.demoEqsConstants.packages.PDC' },
				{ key: 'PBH', value: 'demoPage.equipmentsComponents.demoEqsConstants.packages.PBH' },
				{ key: 'P71', value: 'demoPage.equipmentsComponents.demoEqsConstants.packages.P71' },
				{ key: 'P60', value: 'demoPage.equipmentsComponents.demoEqsConstants.packages.P60' },
				{ key: 'P49', value: 'demoPage.equipmentsComponents.demoEqsConstants.packages.P49' },
				{ key: 'P17', value: 'demoPage.equipmentsComponents.demoEqsConstants.packages.P17' }
			],
			type: 'equipment',
		},
		{
			name: 'exterior',
			details: [
				{ key: '297', value: 'demoPage.equipmentsComponents.demoEqsConstants.paint.297' },
				{ key: 'RWW', value: 'demoPage.equipmentsComponents.demoEqsConstants.exterior.RWW' },
				{ key: '413', value: 'demoPage.equipmentsComponents.demoEqsConstants.exterior.413' },
				{ key: '875', value: 'demoPage.equipmentsComponents.demoEqsConstants.exterior.875' },
				{ key: 'U47', value: 'demoPage.equipmentsComponents.demoEqsConstants.exterior.U47' },
				{ key: 'R01', value: 'demoPage.equipmentsComponents.demoEqsConstants.exterior.R01' },
				{ key: 'PUF', value: 'demoPage.equipmentsComponents.demoEqsConstants.exterior.PUF' },
				{ key: 'PAX', value: 'demoPage.equipmentsComponents.demoEqsConstants.exterior.PAX' },
			],
			type: 'equipment',
		},
		{
			name: 'interior',
			details: [
				{ key: 'H73', value: 'demoPage.equipmentsComponents.demoEqsConstants.interior.H73' },
				{ key: '851', value: 'demoPage.equipmentsComponents.demoEqsConstants.interior.851' },
				{ key: '287', value: 'demoPage.equipmentsComponents.demoEqsConstants.interior.287' },
				{ key: '399', value: 'demoPage.equipmentsComponents.demoEqsConstants.interior.399' },
				{ key: '401', value: 'demoPage.equipmentsComponents.demoEqsConstants.interior.401' },
				{ key: '443', value: 'demoPage.equipmentsComponents.demoEqsConstants.interior.443' },
				{ key: '543', value: 'demoPage.equipmentsComponents.demoEqsConstants.interior.543' },
				{ key: '581', value: 'demoPage.equipmentsComponents.demoEqsConstants.interior.581' },
				{ key: '723', value: 'demoPage.equipmentsComponents.demoEqsConstants.interior.723' },
				{ key: '732', value: 'demoPage.equipmentsComponents.demoEqsConstants.interior.732' },
				{ key: '840', value: 'demoPage.equipmentsComponents.demoEqsConstants.interior.840' },
				{ key: '872', value: 'demoPage.equipmentsComponents.demoEqsConstants.interior.872' },
				{ key: '878', value: 'demoPage.equipmentsComponents.demoEqsConstants.interior.878' },
				{ key: '890', value: 'demoPage.equipmentsComponents.demoEqsConstants.interior.890' },
				{ key: '899', value: 'demoPage.equipmentsComponents.demoEqsConstants.interior.899' },
				{ key: 'U88', value: 'demoPage.equipmentsComponents.demoEqsConstants.interior.U88' },
				{ key: 'U45', value: 'demoPage.equipmentsComponents.demoEqsConstants.interior.U45' },
				{ key: 'U26', value: 'demoPage.equipmentsComponents.demoEqsConstants.interior.U26' },
				{ key: 'PTF', value: 'demoPage.equipmentsComponents.demoEqsConstants.interior.PTF' },
				{ key: 'PAG', value: 'demoPage.equipmentsComponents.demoEqsConstants.interior.PAG' },
				{ key: 'P53', value: 'demoPage.equipmentsComponents.demoEqsConstants.interior.P53' },
				{ key: 'L6J', value: 'demoPage.equipmentsComponents.demoEqsConstants.interior.L6J' },
				{ key: '8U8', value: 'demoPage.equipmentsComponents.demoEqsConstants.interior.8U8' },
				{ key: '7U4', value: 'demoPage.equipmentsComponents.demoEqsConstants.interior.7U4' },
				{ key: '61U', value: 'demoPage.equipmentsComponents.demoEqsConstants.interior.61U' },
			],
			type: 'equipment',
		},
		{
			name: 'infotainment',
			details: [
				{ key: '321', value: 'demoPage.equipmentsComponents.demoEqsConstants.infotainment.321' },
				{ key: '365', value: 'demoPage.equipmentsComponents.demoEqsConstants.infotainment.365' },
				{ key: '367', value: 'demoPage.equipmentsComponents.demoEqsConstants.infotainment.367' },
				{ key: '384', value: 'demoPage.equipmentsComponents.demoEqsConstants.infotainment.384' },
				{ key: '444', value: 'demoPage.equipmentsComponents.demoEqsConstants.infotainment.444' },
				{ key: '537', value: 'demoPage.equipmentsComponents.demoEqsConstants.infotainment.537' },
				{ key: '810', value: 'demoPage.equipmentsComponents.demoEqsConstants.infotainment.810' },
				{ key: 'PBG', value: 'demoPage.equipmentsComponents.demoEqsConstants.infotainment.PBG' },
				{ key: '72B', value: 'demoPage.equipmentsComponents.demoEqsConstants.infotainment.72B' },
				{ key: '22U', value: 'demoPage.equipmentsComponents.demoEqsConstants.infotainment.22U' },
				{ key: '13U', value: 'demoPage.equipmentsComponents.demoEqsConstants.infotainment.13U' }
			],
			type: 'equipment',
		},
		{
			name: 'safety',
			details: [
				{ key: '231', value: 'demoPage.equipmentsComponents.demoEqsConstants.safety.231' },
				{ key: '243', value: 'demoPage.equipmentsComponents.demoEqsConstants.safety.243' },
				{ key: '290', value: 'demoPage.equipmentsComponents.demoEqsConstants.safety.290' },
				{ key: '292', value: 'demoPage.equipmentsComponents.demoEqsConstants.safety.292' },
				{ key: '294', value: 'demoPage.equipmentsComponents.demoEqsConstants.safety.294' },
				{ key: '299', value: 'demoPage.equipmentsComponents.demoEqsConstants.safety.299' },
				{ key: '351', value: 'demoPage.equipmentsComponents.demoEqsConstants.safety.351' },
				{ key: '475', value: 'demoPage.equipmentsComponents.demoEqsConstants.safety.475' },
				{ key: '503', value: 'demoPage.equipmentsComponents.demoEqsConstants.safety.503' },
				{ key: '513', value: 'demoPage.equipmentsComponents.demoEqsConstants.safety.513' },
				{ key: '546', value: 'demoPage.equipmentsComponents.demoEqsConstants.safety.546' },
				{ key: '628', value: 'demoPage.equipmentsComponents.demoEqsConstants.safety.628' },
				{ key: 'U60', value: 'demoPage.equipmentsComponents.demoEqsConstants.safety.U60' },
				{ key: 'P82', value: 'demoPage.equipmentsComponents.demoEqsConstants.safety.P82' },
				{ key: 'P20', value: 'demoPage.equipmentsComponents.demoEqsConstants.safety.P20' },
				{ key: 'B53', value: 'demoPage.equipmentsComponents.demoEqsConstants.safety.B53' },
				{ key: 'B51', value: 'demoPage.equipmentsComponents.demoEqsConstants.safety.B51' },
				{ key: '70B', value: 'demoPage.equipmentsComponents.demoEqsConstants.safety.70B' },
				{ key: '27U', value: 'demoPage.equipmentsComponents.demoEqsConstants.safety.27U' }
			],
			type: 'equipment',
		},
		{
			name: 'technicalDetails',
			details: [
				{ key: '489', value: 'demoPage.equipmentsComponents.demoEqsConstants.technicalDetails.489' },
				{ key: '9B3', value: "demoPage.equipmentsComponents.demoEqsConstants.technicalDetails.9B3" },
				{ key: '83B', value: 'demoPage.equipmentsComponents.demoEqsConstants.technicalDetails.83B' },
			],
			type: 'equipment',
		},
		{
			name: 'meServices',
			type: 'me-services',
			details: [[]]
		},
	],
};

const S580Data = {
	name: [
		'S 580 e 4MATIC Sedan',
		'S 580 e 4MATIC Limousine'
	],
	routeId: 'S-Class-Hybrid',
	assetBasePath: `${process.env.PUBLIC_URL}/images/Vehicles/S580`,
	equipmentImageContext: require.context('/public/images/Vehicles/S580/Equipment', true, /\.(jpe?g|webp)$/i),
	night: {
		images: [
			`${process.env.PUBLIC_URL}/images/Vehicles/S580/night/EXT000.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/S580/night/EXT010.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/S580/night/EXT020.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/S580/night/EXT030.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/S580/night/EXT040.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/S580/night/EXT050.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/S580/night/EXT060.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/S580/night/EXT070.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/S580/night/EXT080.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/S580/night/EXT090.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/S580/night/EXT100.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/S580/night/EXT110.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/S580/night/EXT120.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/S580/night/EXT130.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/S580/night/EXT140.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/S580/night/EXT150.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/S580/night/EXT160.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/S580/night/EXT170.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/S580/night/EXT180.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/S580/night/EXT190.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/S580/night/EXT200.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/S580/night/EXT210.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/S580/night/EXT220.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/S580/night/EXT230.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/S580/night/EXT240.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/S580/night/EXT250.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/S580/night/EXT260.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/S580/night/EXT270.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/S580/night/EXT280.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/S580/night/EXT290.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/S580/night/EXT300.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/S580/night/EXT310.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/S580/night/EXT320.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/S580/night/EXT330.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/S580/night/EXT340.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/S580/night/EXT350.webp`,
		],
		bisImages: [
			`${process.env.PUBLIC_URL}/images/Vehicles/S580/night/BIS4.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/S580/night/BIS2.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/S580/night/BIS5.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/S580/night/BIS6.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/S580/night/BIS1.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/S580/night/BIS3.webp`,
		],
		exteriorImage: `${process.env.PUBLIC_URL}/images/Vehicles/S580/night/Exterior.webp`,
		interiorImage: `${process.env.PUBLIC_URL}/images/Vehicles/S580/night/INT1.webp`,
		interiorImages: [
			`${process.env.PUBLIC_URL}/images/Vehicles/S580/night/INT1.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/S580/night/INT2.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/S580/night/INT3.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/S580/night/INT4.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/S580/night/INT5.webp`,
		],
		trunkImage: `${process.env.PUBLIC_URL}/images/Vehicles/S580/night/BET1.webp`,
	},
	images: [
		`${process.env.PUBLIC_URL}/images/Vehicles/S580/EXT000.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/S580/EXT010.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/S580/EXT020.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/S580/EXT030.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/S580/EXT040.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/S580/EXT050.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/S580/EXT060.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/S580/EXT070.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/S580/EXT080.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/S580/EXT090.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/S580/EXT100.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/S580/EXT110.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/S580/EXT120.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/S580/EXT130.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/S580/EXT140.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/S580/EXT150.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/S580/EXT160.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/S580/EXT170.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/S580/EXT180.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/S580/EXT190.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/S580/EXT200.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/S580/EXT210.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/S580/EXT220.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/S580/EXT230.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/S580/EXT240.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/S580/EXT250.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/S580/EXT260.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/S580/EXT270.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/S580/EXT280.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/S580/EXT290.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/S580/EXT300.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/S580/EXT310.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/S580/EXT320.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/S580/EXT330.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/S580/EXT340.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/S580/EXT350.webp`,
	],
	bisImages: [
		`${process.env.PUBLIC_URL}/images/Vehicles/S580/BIS4.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/S580/BIS2.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/S580/BIS5.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/S580/BIS6.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/S580/BIS1.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/S580/BIS3.webp`,
	],
	exteriorImage: `${process.env.PUBLIC_URL}/images/Vehicles/S580/Exterior.webp`,
	interiorImage: `${process.env.PUBLIC_URL}/images/Vehicles/S580/INT1.webp`,
	interiorImages: [
		`${process.env.PUBLIC_URL}/images/Vehicles/S580/INT1.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/S580/INT2.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/S580/INT3.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/S580/INT4.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/S580/INT5.webp`,
	],
	trunkImage: `${process.env.PUBLIC_URL}/images/Vehicles/S580/BET1.webp`,
	vehicleDetails: [
		[
			{ type: 'car', value: 'Saloon' },
			{ type: 'engine', value: '270 kW (367 PS)' },
			{ type: 'mile', value: '250 km/h' },
			{ type: 'fuel', value: 'Super' },
			{ type: 'transmission', value: 'Automatic transmission' },
		],
		[
			{ type: 'car', value: 'Saloon' },
			{ type: 'engine', value: '270 kW (367 PS)' },
			{ type: 'mile', value: '250 km/h' },
			{ type: 'fuel', value: 'Super' },
			{ type: 'transmission', value: 'Automatikgetriebe' },
		]
	],
	exteriorPaint: `${process.env.PUBLIC_URL}/images/Vehicles/S580/ExteriorPaint/sclasspaint.webp`,
	interiorUpholstery: `${process.env.PUBLIC_URL}/images/Vehicles/S580/InteriorUpholstery/sclassupholstery.webp`,
	videoDetails: [
		{
			isTouch: false,
			logo: "",
			scene1text: 'S 580 e 4MATIC Sedan',
			scene1image: `${process.env.PUBLIC_URL}/images/Vehicles/S580/Video/EXT000.webp`,
			scene2text: 'S 580 e 4MATIC Sedan',
			vehicleRotationImages: {
				1: `${process.env.PUBLIC_URL}/images/Vehicles/S580/Video/EXT300.webp`,
				2: `${process.env.PUBLIC_URL}/images/Vehicles/S580/Video/EXT310.webp`,
				3: `${process.env.PUBLIC_URL}/images/Vehicles/S580/Video/EXT320.webp`,
				4: `${process.env.PUBLIC_URL}/images/Vehicles/S580/Video/EXT330.webp`,
				5: `${process.env.PUBLIC_URL}/images/Vehicles/S580/Video/EXT340.webp`,
				6: `${process.env.PUBLIC_URL}/images/Vehicles/S580/Video/EXT350.webp`,
			},
			scene3text: 'S-Class - Your dream is closer than ever',
			interiorImages: {
				1: `${process.env.PUBLIC_URL}/images/Vehicles/S580/Video/INT1.webp`,
				2: `${process.env.PUBLIC_URL}/images/Vehicles/S580/Video/INT2.webp`,
				3: `${process.env.PUBLIC_URL}/images/Vehicles/S580/Video/INT3.webp`,
				4: `${process.env.PUBLIC_URL}/images/Vehicles/S580/Video/INT4.webp`,
			},
			details: {
				textcolorblack: false,
				title1: 'Paint',
				text1: 'MANUFAKTUR diamond white bright',
				title2: 'Upholstery',
				text2: 'Nappa leather macchiato beige / magma grey',
				title3: 'Engine',
				text3: 'Hybrid',
				title4: 'Power (PS/KW)',
				text4: '367 / 270',
				bgimage: `${process.env.PUBLIC_URL}/images/Vehicles/S580/Video/EXT040.webp`,
			},
			packages2: {
				title: 'Equipments highlights.',
				1: {
					image: 'https://assets.oneweb.mercedes-benz.com/bbd/images/v1/4860/f/ab/4176468018aeece1f7ddcccf75a3eddde83f4.jpg',
					text: 'Panoramic sunroof',
					detailslink: '',
					code: '413',
				},
				2: {
					image: 'https://assets.oneweb.mercedes-benz.com/bbd/images/v1/4860/c/c2/cf11ea700e9f4aff9051bb9504bb8658f78bf.jpg',
					text: 'Electrically adjustable outer rear seat',
					detailslink: '',
					code: '223',
				},
			},
			packages3: {},
			outroText: 'Mercedes-Benz. The best or nothing.',
			endText: 'Experience now the S 580 e 4MATIC Sedan',
			endBtnLink: 'https://www.mercedes-benz.com',
			endBtnText: 'Contact us',
			endImage: '',
			endImageTransparent: `${process.env.PUBLIC_URL}/images/Vehicles/S580/Video/EXT040.webp`,
			endBkgImage:
				'https://assets.oneweb.mercedes-benz.com/bbd/images/v1/4216/3/ce/f19bab99ea61f29d8282b8d9d13b82b758673.jpg',
			qr: 'https://0cn.de/pfbdb+qr-400.png',
		},
		{
			isTouch: false,
			logo: "",
			scene1text: 'S 580 e 4MATIC Limousine',
			scene1image: `${process.env.PUBLIC_URL}/images/Vehicles/S580/Video/EXT000.webp`,
			scene2text: 'S 580 e 4MATIC Limousine',
			vehicleRotationImages: {
				1: `${process.env.PUBLIC_URL}/images/Vehicles/S580/Video/EXT300.webp`,
				2: `${process.env.PUBLIC_URL}/images/Vehicles/S580/Video/EXT310.webp`,
				3: `${process.env.PUBLIC_URL}/images/Vehicles/S580/Video/EXT320.webp`,
				4: `${process.env.PUBLIC_URL}/images/Vehicles/S580/Video/EXT330.webp`,
				5: `${process.env.PUBLIC_URL}/images/Vehicles/S580/Video/EXT340.webp`,
				6: `${process.env.PUBLIC_URL}/images/Vehicles/S580/Video/EXT350.webp`,
			},
			scene3text: 'S-Class - Your dream is closer than ever',
			interiorImages: {
				1: `${process.env.PUBLIC_URL}/images/Vehicles/S580/Video/INT1.webp`,
				2: `${process.env.PUBLIC_URL}/images/Vehicles/S580/Video/INT2.webp`,
				3: `${process.env.PUBLIC_URL}/images/Vehicles/S580/Video/INT3.webp`,
				4: `${process.env.PUBLIC_URL}/images/Vehicles/S580/Video/INT4.webp`,
			},
			details: {
				textcolorblack: false,
				title1: "Farbe",
				text1: "MANUFAKTUR diamantweiß bright",
				title2: "Polster",
				text2: "Leder Nappa macchiatobeige / magmagrau",
				title3: "Motor",
				text3: "Hybridantrieb",
				title4: "Leistung (PS/KW)",
				text4: "367 / 270",
				bgimage: `${process.env.PUBLIC_URL}/images/Vehicles/S580/Video/EXT040.webp`,
			},
			packages2: {
				title: 'Ausstattungshighlights.',
				1: {
					image: "https://assets.oneweb.mercedes-benz.com/bbd/images/v1/4860/f/ab/4176468018aeece1f7ddcccf75a3eddde83f4.jpg",
					text: "Panorama-Schiebedach",
					detailslink: "",
					code: "413"
				},
				2: {
					image: "https://assets.oneweb.mercedes-benz.com/bbd/images/v1/4860/c/c2/cf11ea700e9f4aff9051bb9504bb8658f78bf.jpg",
					text: "Fondsitze elektrisch einstellbar inklusive Memory-Funktion",
					detailslink: "",
					code: "223"
				}
			},
			packages3: {},
			outroText: "Mercedes-Benz. Das Beste oder nichts.",
			endText: "Erleben Sie jetzt den S 580 e 4MATIC Limousine",
			endBtnLink: "https://www.mercedes-benz.de",
			endBtnText: "Jetzt Anfragen",
			endImage: '',
			endImageTransparent: `${process.env.PUBLIC_URL}/images/Vehicles/S580/Video/EXT040.webp`,
			endBkgImage:
				'https://assets.oneweb.mercedes-benz.com/bbd/images/v1/4216/3/ce/f19bab99ea61f29d8282b8d9d13b82b758673.jpg',
			qr: 'https://0cn.de/pfbdb+qr-400.png',
		},
	],
	tabs: [
		{
			name: 'generalData',
			details: [
				{ key: 'numberOfDoors', value: '4' },
				{ key: 'numberOfSeats', value: '5' },
				{ key: 'length', value: '5.289 mm' },
				{ key: 'emptyWeight', value: '2.395 kg' },
				{ key: 'payload', value: '600 kg' },
				{ key: 'maxWeight', value: '3.070 kg' }
			],
		},
		{
			name: 'packages',
			details: [
				{ key: '942', value: 'demoPage.equipmentsComponents.demoS580Constants.packages.942' },
				{ key: '950', value: 'demoPage.equipmentsComponents.demoS580Constants.packages.950' },
				{ key: 'P55', value: 'demoPage.equipmentsComponents.demoS580Constants.packages.P55' },
			],
			type: 'equipment',
		},
		{
			name: 'exterior',
			details: [
				{ key: '799', value: 'demoPage.equipmentsComponents.demoS580Constants.paint.799' },
				{ key: 'RVQ', value: 'demoPage.equipmentsComponents.demoS580Constants.exterior.RVQ' },
				{ key: '413', value: 'demoPage.equipmentsComponents.demoS580Constants.exterior.413' },
				{ key: '587', value: 'demoPage.equipmentsComponents.demoS580Constants.exterior.587' },
				{ key: '874', value: 'demoPage.equipmentsComponents.demoS580Constants.exterior.874' },
				{ key: '889', value: 'demoPage.equipmentsComponents.demoS580Constants.exterior.889' },
				{ key: 'R01', value: 'demoPage.equipmentsComponents.demoS580Constants.exterior.R01' },
			],
			type: 'equipment',
		},
		{
			name: 'interior',
			details: [
				{ key: 'H17', value: 'demoPage.equipmentsComponents.demoS580Constants.interior.H17' },
				{ key: '805', value: 'demoPage.equipmentsComponents.demoS580Constants.interior.805' },
				{ key: '223', value: 'demoPage.equipmentsComponents.demoS580Constants.interior.223' },
				{ key: '276', value: 'demoPage.equipmentsComponents.demoS580Constants.interior.276' },
				{ key: '282', value: 'demoPage.equipmentsComponents.demoS580Constants.interior.282' },
				{ key: '297', value: 'demoPage.equipmentsComponents.demoS580Constants.interior.297' },
				{ key: '401', value: 'demoPage.equipmentsComponents.demoS580Constants.interior.401' },
				{ key: '402', value: 'demoPage.equipmentsComponents.demoS580Constants.interior.402' },
				{ key: '443', value: 'demoPage.equipmentsComponents.demoS580Constants.interior.443' },
				{ key: '540', value: 'demoPage.equipmentsComponents.demoS580Constants.interior.540' },
				{ key: '543', value: 'demoPage.equipmentsComponents.demoS580Constants.interior.543' },
				{ key: '581', value: 'demoPage.equipmentsComponents.demoS580Constants.interior.581' },
				{ key: '596', value: 'demoPage.equipmentsComponents.demoS580Constants.interior.596' },
				{ key: '726', value: 'demoPage.equipmentsComponents.demoS580Constants.interior.726' },
				{ key: '840', value: 'demoPage.equipmentsComponents.demoS580Constants.interior.840' },
				{ key: '881', value: 'demoPage.equipmentsComponents.demoS580Constants.interior.881' },
				{ key: '891', value: 'demoPage.equipmentsComponents.demoS580Constants.interior.891' },
				{ key: 'U35', value: 'demoPage.equipmentsComponents.demoS580Constants.interior.U35' },
				{ key: 'U26', value: 'demoPage.equipmentsComponents.demoS580Constants.interior.U26' },
				{ key: 'U25', value: 'demoPage.equipmentsComponents.demoS580Constants.interior.U25' },
				{ key: 'U22', value: 'demoPage.equipmentsComponents.demoS580Constants.interior.U22' },
				{ key: 'P69', value: 'demoPage.equipmentsComponents.demoS580Constants.interior.P69' },
				{ key: 'P64', value: 'demoPage.equipmentsComponents.demoS580Constants.interior.P64' },
				{ key: 'P09', value: 'demoPage.equipmentsComponents.demoS580Constants.interior.P09' },
				{ key: 'L5C', value: 'demoPage.equipmentsComponents.demoS580Constants.interior.L5C' },
				{ key: '55U', value: 'demoPage.equipmentsComponents.demoS580Constants.interior.55U' },
			],
			type: 'equipment',
		},
		{
			name: 'infotainment',
			details: [
				{ key: '321', value: 'demoPage.equipmentsComponents.demoS580Constants.infotainment.321' },
				{ key: '355', value: 'demoPage.equipmentsComponents.demoS580Constants.infotainment.355' },
				{ key: '383', value: 'demoPage.equipmentsComponents.demoS580Constants.infotainment.383' },
				{ key: '445', value: 'demoPage.equipmentsComponents.demoS580Constants.infotainment.445' },
				{ key: '447', value: 'demoPage.equipmentsComponents.demoS580Constants.infotainment.447' },
				{ key: '451', value: 'demoPage.equipmentsComponents.demoS580Constants.infotainment.451' },
				{ key: '537', value: 'demoPage.equipmentsComponents.demoS580Constants.infotainment.537' },
				{ key: '810', value: 'demoPage.equipmentsComponents.demoS580Constants.infotainment.810' },
				{ key: '868', value: 'demoPage.equipmentsComponents.demoS580Constants.infotainment.868' },
				{ key: '897', value: 'demoPage.equipmentsComponents.demoS580Constants.infotainment.897' },
				{ key: '898', value: 'demoPage.equipmentsComponents.demoS580Constants.infotainment.898' },
				{ key: 'U19', value: 'demoPage.equipmentsComponents.demoS580Constants.infotainment.U19' },
				{ key: 'PBG', value: 'demoPage.equipmentsComponents.demoS580Constants.infotainment.PBG' },
				{ key: '33U', value: 'demoPage.equipmentsComponents.demoS580Constants.infotainment.33U' },
				{ key: '32U', value: 'demoPage.equipmentsComponents.demoS580Constants.infotainment.32U' },
				{ key: '14U', value: 'demoPage.equipmentsComponents.demoS580Constants.infotainment.14U' },
				{ key: '01U', value: 'demoPage.equipmentsComponents.demoS580Constants.infotainment.01U' },
			],
			type: 'equipment',
		},
		{
			name: 'safety',
			details: [
				{ key: '233', value: 'demoPage.equipmentsComponents.demoS580Constants.safety.233' },
				{ key: '243', value: 'demoPage.equipmentsComponents.demoS580Constants.safety.243' },
				{ key: '292', value: 'demoPage.equipmentsComponents.demoS580Constants.safety.292' },
				{ key: '351', value: 'demoPage.equipmentsComponents.demoS580Constants.safety.351' },
				{ key: '513', value: 'demoPage.equipmentsComponents.demoS580Constants.safety.513' },
				{ key: '546', value: 'demoPage.equipmentsComponents.demoS580Constants.safety.546' },
				{ key: '883', value: 'demoPage.equipmentsComponents.demoS580Constants.safety.883' },
				{ key: 'P47', value: 'demoPage.equipmentsComponents.demoS580Constants.safety.P47' },
				{ key: 'P20', value: 'demoPage.equipmentsComponents.demoS580Constants.safety.P20' },
				{ key: 'B53', value: 'demoPage.equipmentsComponents.demoS580Constants.safety.B53' },
				{ key: '70B', value: 'demoPage.equipmentsComponents.demoS580Constants.safety.70B' }
			],
			type: 'equipment',
		},
		{
			name: 'technicalDetails',
			details: [
				{ key: 'M04', value: 'demoPage.equipmentsComponents.demoS580Constants.technicalDetails.M04' },
				{ key: '201', value: 'demoPage.equipmentsComponents.demoS580Constants.technicalDetails.201' },
				{ key: '489', value: 'demoPage.equipmentsComponents.demoS580Constants.technicalDetails.489' },
				{ key: 'B30', value: 'demoPage.equipmentsComponents.demoS580Constants.technicalDetails.B30' },
				{ key: '9B2', value: 'demoPage.equipmentsComponents.demoS580Constants.technicalDetails.9B2' },
				{ key: '83B', value: 'demoPage.equipmentsComponents.demoS580Constants.technicalDetails.83B' },
			],
			type: 'equipment',
		},
		{
			name: 'meServices',
			type: 'me-services',
			details: [[]]
		},
	],
};

const SclassData = {
	name: [
		'Mercedes-AMG SL 55 4-MATIC+',
		'Mercedes-AMG SL 55 4-MATIC+'
	],
	routeId: 'SL-AMG',
	assetBasePath: `${process.env.PUBLIC_URL}/images/Vehicles/Sclass/`,
	equipmentImageContext: require.context('/public/images/Vehicles/SClass/Equipment', true, /\.(jpe?g|webp)$/i),
	night: {
		images: [
			`${process.env.PUBLIC_URL}/images/Vehicles/Amg/night/EXT000.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Amg/night/EXT010.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Amg/night/EXT020.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Amg/night/EXT030.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Amg/night/EXT040.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Amg/night/EXT050.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Amg/night/EXT060.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Amg/night/EXT070.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Amg/night/EXT080.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Amg/night/EXT090.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Amg/night/EXT100.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Amg/night/EXT110.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Amg/night/EXT120.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Amg/night/EXT130.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Amg/night/EXT140.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Amg/night/EXT150.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Amg/night/EXT160.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Amg/night/EXT170.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Amg/night/EXT180.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Amg/night/EXT190.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Amg/night/EXT200.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Amg/night/EXT210.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Amg/night/EXT220.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Amg/night/EXT230.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Amg/night/EXT240.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Amg/night/EXT250.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Amg/night/EXT260.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Amg/night/EXT270.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Amg/night/EXT280.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Amg/night/EXT290.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Amg/night/EXT300.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Amg/night/EXT310.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Amg/night/EXT320.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Amg/night/EXT330.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Amg/night/EXT340.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Amg/night/EXT350.webp`,
		],
		bisImages: [
			`${process.env.PUBLIC_URL}/images/Vehicles/Amg/night/BIS4.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Amg/night/BIS2.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Amg/night/BIS5.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Amg/night/BIS6.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Amg/night/BIS1.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Amg/night/BIS3.webp`,
		],
		exteriorImage: `${process.env.PUBLIC_URL}/images/Vehicles/Amg/night/Exterior.webp`,
		interiorImage: `${process.env.PUBLIC_URL}/images/Vehicles/Amg/night/INT1.webp`,
		interiorImages: [
			`${process.env.PUBLIC_URL}/images/Vehicles/Amg/night/INT1.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Amg/night/INT2.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Amg/night/INT4.webp`,
		]
	},
	images: [
		`${process.env.PUBLIC_URL}/images/Vehicles/Amg/EXT000.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Amg/EXT010.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Amg/EXT020.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Amg/EXT030.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Amg/EXT040.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Amg/EXT050.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Amg/EXT060.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Amg/EXT070.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Amg/EXT080.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Amg/EXT090.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Amg/EXT100.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Amg/EXT110.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Amg/EXT120.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Amg/EXT130.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Amg/EXT140.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Amg/EXT150.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Amg/EXT160.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Amg/EXT170.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Amg/EXT180.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Amg/EXT190.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Amg/EXT200.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Amg/EXT210.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Amg/EXT220.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Amg/EXT230.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Amg/EXT240.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Amg/EXT250.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Amg/EXT260.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Amg/EXT270.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Amg/EXT280.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Amg/EXT290.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Amg/EXT300.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Amg/EXT310.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Amg/EXT320.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Amg/EXT330.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Amg/EXT340.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Amg/EXT350.webp`,
	],
	bisImages: [
		`${process.env.PUBLIC_URL}/images/Vehicles/Amg/BIS4.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Amg/BIS2.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Amg/BIS5.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Amg/BIS6.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Amg/BIS1.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Amg/BIS3.webp`,
	],
	exteriorImage: `${process.env.PUBLIC_URL}/images/Vehicles/Amg/Exterior.webp`,
	interiorImage: `${process.env.PUBLIC_URL}/images/Vehicles/Amg/INT1.webp`,
	interiorImages: [
		`${process.env.PUBLIC_URL}/images/Vehicles/Amg/INT1.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Amg/INT2.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Amg/INT4.webp`,
	],
	vehicleDetails: [
		[
			{ type: 'car', value: 'Cabriolet/Roadster' },
			{ type: 'engine', value: '350 kW (476 PS)' },
			{ type: 'mile', value: '295 km/h' },
			{ type: 'fuel', value: 'Super' },
			{ type: 'transmission', value: 'Automatic transmission' }
		],
		[
			{ type: 'car', value: 'Cabriolet/Roadster' },
			{ type: 'engine', value: '350 kW (476 PS)' },
			{ type: 'mile', value: '295 km/h' },
			{ type: 'fuel', value: 'Super' },
			{ type: 'transmission', value: 'Automatikgetriebe' }
		]
	],
	exteriorPaint: `${process.env.PUBLIC_URL}/images/Vehicles/Amg/ExteriorPaint/slamgpaint.webp`,
	interiorUpholstery: `${process.env.PUBLIC_URL}/images/Vehicles/Amg/InteriorUpholstery/slamgupholstery.webp`,
	videoDetails: [
		{
			isTouch: false,
			logo: "",
			scene1text: 'Mercedes-AMG SL 55 4-MATIC+',
			scene1image: `${process.env.PUBLIC_URL}/images/Vehicles/Sclass/Video/EXT000.webp`,
			scene2text: 'Mercedes-AMG SL 55 4-MATIC+',
			vehicleRotationImages: {
				1: `${process.env.PUBLIC_URL}/images/Vehicles/Sclass/Video/EXT300.webp`,
				2: `${process.env.PUBLIC_URL}/images/Vehicles/Sclass/Video/EXT310.webp`,
				3: `${process.env.PUBLIC_URL}/images/Vehicles/Sclass/Video/EXT320.webp`,
				4: `${process.env.PUBLIC_URL}/images/Vehicles/Sclass/Video/EXT330.webp`,
				5: `${process.env.PUBLIC_URL}/images/Vehicles/Sclass/Video/EXT340.webp`,
				6: `${process.env.PUBLIC_URL}/images/Vehicles/Sclass/Video/EXT350.webp`,
			},
			scene3text: 'SL-Class - Your dream is closer than ever',
			interiorImages: {
				1: `${process.env.PUBLIC_URL}/images/Vehicles/Sclass/Video/INT1.webp`,
				2: `${process.env.PUBLIC_URL}/images/Vehicles/Sclass/Video/INT2.webp`,
				3: `${process.env.PUBLIC_URL}/images/Vehicles/Sclass/Video/INT2.webp`,
				4: `${process.env.PUBLIC_URL}/images/Vehicles/Sclass/Video/INT4.webp`,
			},
			details: {
				textcolorblack: false,
				title1: 'Paint',
				text1: 'Sun yellow',
				title2: 'Upholstery',
				text2: 'Nappa leather - black',
				title3: 'Engine',
				text3: 'Petrol',
				title4: 'Power (PS/KW)',
				text4: '476 / 350',
				bgimage: `${process.env.PUBLIC_URL}/images/Vehicles/Sclass/Video/EXT040.webp`,
			},
			packages2: {
				title: 'Equipments highlights.',
				1: {
					image: 'https://assets.oneweb.mercedes-benz.com/bbd/images/v1/5151/b/ce/cd53c063201a77cf733f04326380b1d7d1161.jpg',
					text: '53.3 cm (21-inch) AMG cross-spoke forged wheels',
					detailslink: '',
					code: 'RWE',
				},
				2: {
					image: 'https://assets.oneweb.mercedes-benz.com/bbd/images/v1/5151/9/a1/3e851e527140bcdb52fffbd9625f3bbd52079.jpg',
					text: 'AMG carbon trim',
					detailslink: '',
					code: 'H73',
				},
			},
			packages3: {},
			outroText: 'Mercedes-Benz. The best or nothing.',
			endText: 'Experience now the Mercedes-AMG SL 55 4-MATIC+',
			endBtnLink: 'https://www.mercedes-benz.com',
			endBtnText: 'Contact us',
			endImage: '',
			endImageTransparent: `${process.env.PUBLIC_URL}/images/Vehicles/Amg/EXT040.webp`,
			endBkgImage:
				'https://assets.oneweb.mercedes-benz.com/bbd/images/v1/4216/3/ce/f19bab99ea61f29d8282b8d9d13b82b758673.jpg',
			qr: 'https://0cn.de/pfbdb+qr-400.png',
		},
		{
			isTouch: false,
			logo: "",
			scene1text: 'Mercedes-AMG SL 55 4-MATIC+',
			scene1image: `${process.env.PUBLIC_URL}/images/Vehicles/Sclass/Video/EXT000.webp`,
			scene2text: 'Mercedes-AMG SL 55 4-MATIC+',
			vehicleRotationImages: {
				1: `${process.env.PUBLIC_URL}/images/Vehicles/Sclass/Video/EXT300.webp`,
				2: `${process.env.PUBLIC_URL}/images/Vehicles/Sclass/Video/EXT310.webp`,
				3: `${process.env.PUBLIC_URL}/images/Vehicles/Sclass/Video/EXT320.webp`,
				4: `${process.env.PUBLIC_URL}/images/Vehicles/Sclass/Video/EXT330.webp`,
				5: `${process.env.PUBLIC_URL}/images/Vehicles/Sclass/Video/EXT340.webp`,
				6: `${process.env.PUBLIC_URL}/images/Vehicles/Sclass/Video/EXT350.webp`,
			},
			scene3text: 'SL-Class - Your dream is closer than ever',
			interiorImages: {
				1: `${process.env.PUBLIC_URL}/images/Vehicles/Sclass/Video/INT1.webp`,
				2: `${process.env.PUBLIC_URL}/images/Vehicles/Sclass/Video/INT2.webp`,
				3: `${process.env.PUBLIC_URL}/images/Vehicles/Sclass/Video/INT2.webp`,
				4: `${process.env.PUBLIC_URL}/images/Vehicles/Sclass/Video/INT4.webp`,
			},
			details: {
				textcolorblack: false,
				title1: "Farbe",
				text1: "Sonnengelb",
				title2: "Polster",
				text2: "Leder Nappa schwarz",
				title3: "Motor",
				text3: "Benzinantrieb",
				title4: "Leistung (PS/KW)",
				text4: "476 / 350",
				bgimage: `${process.env.PUBLIC_URL}/images/Vehicles/Sclass/Video/EXT040.webp`,
			},
			packages2: {
				title: 'Ausstattungshighlights',
				1: {
					image: "https://assets.oneweb.mercedes-benz.com/bbd/images/v1/5151/b/ce/cd53c063201a77cf733f04326380b1d7d1161.jpg",
					text: "53,3 cm (21\") AMG Schmiederäder im 10-Doppelspeichen-Design",
					detailslink: "",
					code: "RWE"
				},
				2: {
					image: "https://assets.oneweb.mercedes-benz.com/bbd/images/v1/5151/9/a1/3e851e527140bcdb52fffbd9625f3bbd52079.jpg",
					text: "AMG Zierelemente Carbon",
					detailslink: "",
					code: "H73"
				}
			},
			packages3: {},
			outroText: "Mercedes-Benz. Das Beste oder nichts.",
			endText: "Erleben Sie jetzt den Mercedes-AMG SL 55 4MATIC+",
			endBtnLink: "https://www.mercedes-benz.de",
			endBtnText: "Jetzt Anfragen",
			endImage: '',
			endImageTransparent: `${process.env.PUBLIC_URL}/images/Vehicles/Amg/EXT040.webp`,
			endBkgImage:
				'https://assets.oneweb.mercedes-benz.com/bbd/images/v1/4216/3/ce/f19bab99ea61f29d8282b8d9d13b82b758673.jpg',
			qr: 'https://0cn.de/pfbdb+qr-400.png',
		}
	],
	tabs: [
		{
			name: 'generalData',
			details: [
				{ key: 'numberOfDoors', value: '2' },
				{ key: 'numberOfSeats', value: '4' },
				{ key: 'length', value: '4.705 mm' },
				{ key: 'emptyWeight', value: '1.895 kg' },
				{ key: 'payload', value: '350 kg' },
				{ key: 'maxWeight', value: '2.320 kg' }
			],
		},
		{
			name: 'packages',
			details: [
				{ key: 'P71', value: 'demoPage.equipmentsComponents.demoSclassConstants.packages.P71' },
				{ key: 'P49', value: 'demoPage.equipmentsComponents.demoSclassConstants.packages.P49' },
				{ key: 'P20', value: 'demoPage.equipmentsComponents.demoSclassConstants.packages.P20' }
			],
			type: 'equipment',
		},
		{
			name: 'exterior',
			details: [
				{ key: '914', value: 'demoPage.equipmentsComponents.demoSclassConstants.paint.914' },
				{ key: 'RWE', value: 'demoPage.equipmentsComponents.demoSclassConstants.exterior.RWE' },
				{ key: '318', value: 'demoPage.equipmentsComponents.demoSclassConstants.exterior.318' },
				{ key: '588', value: 'demoPage.equipmentsComponents.demoSclassConstants.exterior.588' },
				{ key: '740', value: 'demoPage.equipmentsComponents.demoSclassConstants.exterior.740' },
				{ key: '773', value: 'demoPage.equipmentsComponents.demoSclassConstants.exterior.773' },
				{ key: '871', value: 'demoPage.equipmentsComponents.demoSclassConstants.exterior.871' },
				{ key: '874', value: 'demoPage.equipmentsComponents.demoSclassConstants.exterior.874' },
				{ key: '889', value: 'demoPage.equipmentsComponents.demoSclassConstants.exterior.889' },
				{ key: 'R01', value: 'demoPage.equipmentsComponents.demoSclassConstants.exterior.R01' },
				{ key: 'B51', value: 'demoPage.equipmentsComponents.demoSclassConstants.exterior.B51' }
			],
			type: 'equipment',
		},
		{
			name: 'interior',
			details: [
				{ key: 'H73', value: 'demoPage.equipmentsComponents.demoSclassConstants.interior.H73' },
				{ key: '801', value: 'demoPage.equipmentsComponents.demoSclassConstants.interior.801' },
				{ key: '256', value: 'demoPage.equipmentsComponents.demoSclassConstants.interior.256' },
				{ key: '275', value: 'demoPage.equipmentsComponents.demoSclassConstants.interior.275' },
				{ key: '309', value: 'demoPage.equipmentsComponents.demoSclassConstants.interior.309' },
				{ key: '399', value: 'demoPage.equipmentsComponents.demoSclassConstants.interior.399' },
				{ key: '401', value: 'demoPage.equipmentsComponents.demoSclassConstants.interior.401' },
				{ key: '403', value: 'demoPage.equipmentsComponents.demoSclassConstants.interior.403' },
				{ key: '443', value: 'demoPage.equipmentsComponents.demoSclassConstants.interior.443' },
				{ key: '543', value: 'demoPage.equipmentsComponents.demoSclassConstants.interior.543' },
				{ key: '581', value: 'demoPage.equipmentsComponents.demoSclassConstants.interior.581' },
				{ key: '891', value: 'demoPage.equipmentsComponents.demoSclassConstants.interior.891' },
				{ key: '942', value: 'demoPage.equipmentsComponents.demoSclassConstants.interior.942' },
				{ key: 'U88', value: 'demoPage.equipmentsComponents.demoSclassConstants.interior.U88' },
				{ key: 'U45', value: 'demoPage.equipmentsComponents.demoSclassConstants.interior.U45' },
				{ key: 'U35', value: 'demoPage.equipmentsComponents.demoSclassConstants.interior.U35' },
				{ key: 'PBP', value: 'demoPage.equipmentsComponents.demoSclassConstants.interior.PBP' },
				{ key: 'L6J', value: 'demoPage.equipmentsComponents.demoSclassConstants.interior.L6J' },
				{ key: '51U', value: 'demoPage.equipmentsComponents.demoSclassConstants.interior.51U' }
			],
			type: 'equipment',
		},
		{
			name: 'infotainment',
			details: [
				{ key: '355', value: 'demoPage.equipmentsComponents.demoSclassConstants.infotainment.355' },
				{ key: '365', value: 'demoPage.equipmentsComponents.demoSclassConstants.infotainment.365' },
				{ key: '383', value: 'demoPage.equipmentsComponents.demoSclassConstants.infotainment.383' },
				{ key: '438', value: 'demoPage.equipmentsComponents.demoSclassConstants.infotainment.438' },
				{ key: '464', value: 'demoPage.equipmentsComponents.demoSclassConstants.infotainment.464' },
				{ key: '811', value: 'demoPage.equipmentsComponents.demoSclassConstants.infotainment.811' },
				{ key: '868', value: 'demoPage.equipmentsComponents.demoSclassConstants.infotainment.868' },
				{ key: '897', value: 'demoPage.equipmentsComponents.demoSclassConstants.infotainment.897' },
				{ key: 'U19', value: 'demoPage.equipmentsComponents.demoSclassConstants.infotainment.U19' },
				{ key: 'PBG', value: 'demoPage.equipmentsComponents.demoSclassConstants.infotainment.PBG' },
				{ key: '77B', value: 'demoPage.equipmentsComponents.demoSclassConstants.infotainment.77B' },
				{ key: '72B', value: 'demoPage.equipmentsComponents.demoSclassConstants.infotainment.72B' },
				{ key: '34U', value: 'demoPage.equipmentsComponents.demoSclassConstants.infotainment.34U' },
				{ key: '14U', value: 'demoPage.equipmentsComponents.demoSclassConstants.infotainment.14U' }
			],
			type: 'equipment',
		},
		{
			name: 'safety',
			details: [
				{ key: '299', value: 'demoPage.equipmentsComponents.demoSclassConstants.safety.299' },
				{ key: '351', value: 'demoPage.equipmentsComponents.demoSclassConstants.safety.351' },
				{ key: '475', value: 'demoPage.equipmentsComponents.demoSclassConstants.safety.475' },
				{ key: '501', value: 'demoPage.equipmentsComponents.demoSclassConstants.safety.501' },
				{ key: '513', value: 'demoPage.equipmentsComponents.demoSclassConstants.safety.513' },
				{ key: '628', value: 'demoPage.equipmentsComponents.demoSclassConstants.safety.628' },
				{ key: '8U8', value: 'demoPage.equipmentsComponents.demoSclassConstants.safety.8U8' },
				{ key: '70B', value: 'demoPage.equipmentsComponents.demoSclassConstants.safety.70B' },
				{ key: '20U', value: 'demoPage.equipmentsComponents.demoSclassConstants.safety.20U' }
			],
			type: 'equipment',
		},
		{
			name: 'technicalDetails',
			details: [
				{ key: 'Motor', value: 'Motor' },
				{ key: '201', value: 'demoPage.equipmentsComponents.demoSclassConstants.technicalDetails.201' },
				{ key: '421', value: 'demoPage.equipmentsComponents.demoSclassConstants.technicalDetails.421' },
				{ key: '470', value: 'demoPage.equipmentsComponents.demoSclassConstants.technicalDetails.470' },
				{ key: '479', value: 'demoPage.equipmentsComponents.demoSclassConstants.technicalDetails.479' },
				{ key: 'B07', value: 'demoPage.equipmentsComponents.demoSclassConstants.technicalDetails.B07' }
			],
			type: 'equipment',
		},
		{
			name: 'meServices',
			type: 'me-services',
			details: [[]]
		},
	],
};

const SmartData = {
	name: [
		'smart EQ fortwo coupé BRABUS Line',
		'smart EQ fortwo coupé BRABUS'
	],
	routeId: 'Smart-EQ',
	assetBasePath: `${process.env.PUBLIC_URL}/images/Vehicles/Smart`,
	equipmentImageContext: require.context('/public/images/Vehicles/Smart/Equipment', true, /\.(jpe?g|webp)$/i),
	night: {
		images: [
			`${process.env.PUBLIC_URL}/images/Vehicles/Smart/night/EXT000.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Smart/night/EXT010.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Smart/night/EXT020.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Smart/night/EXT030.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Smart/night/EXT040.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Smart/night/EXT050.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Smart/night/EXT060.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Smart/night/EXT070.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Smart/night/EXT080.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Smart/night/EXT090.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Smart/night/EXT100.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Smart/night/EXT110.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Smart/night/EXT120.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Smart/night/EXT130.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Smart/night/EXT140.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Smart/night/EXT150.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Smart/night/EXT160.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Smart/night/EXT170.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Smart/night/EXT180.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Smart/night/EXT190.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Smart/night/EXT200.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Smart/night/EXT210.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Smart/night/EXT220.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Smart/night/EXT230.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Smart/night/EXT240.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Smart/night/EXT250.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Smart/night/EXT260.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Smart/night/EXT270.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Smart/night/EXT280.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Smart/night/EXT290.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Smart/night/EXT300.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Smart/night/EXT310.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Smart/night/EXT320.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Smart/night/EXT330.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Smart/night/EXT340.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Smart/night/EXT350.webp`,
		],
		interiorImage: `${process.env.PUBLIC_URL}/images/Vehicles/Smart/night/INT1.webp`,
		interiorImages: [
			`${process.env.PUBLIC_URL}/images/Vehicles/Smart/night/INT1.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Smart/night/INT2.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Smart/night/INT4.webp`,
		],
	},
	images: [
		`${process.env.PUBLIC_URL}/images/Vehicles/Smart/EXT000.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Smart/EXT010.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Smart/EXT020.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Smart/EXT030.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Smart/EXT040.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Smart/EXT050.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Smart/EXT060.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Smart/EXT070.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Smart/EXT080.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Smart/EXT090.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Smart/EXT100.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Smart/EXT110.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Smart/EXT120.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Smart/EXT130.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Smart/EXT140.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Smart/EXT150.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Smart/EXT160.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Smart/EXT170.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Smart/EXT180.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Smart/EXT190.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Smart/EXT200.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Smart/EXT210.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Smart/EXT220.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Smart/EXT230.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Smart/EXT240.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Smart/EXT250.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Smart/EXT260.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Smart/EXT270.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Smart/EXT280.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Smart/EXT290.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Smart/EXT300.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Smart/EXT310.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Smart/EXT320.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Smart/EXT330.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Smart/EXT340.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Smart/EXT350.webp`,
	],
	interiorImage: `${process.env.PUBLIC_URL}/images/Vehicles/Smart/INT1.webp`,
	interiorImages: [
		`${process.env.PUBLIC_URL}/images/Vehicles/Smart/INT1.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Smart/INT2.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Smart/INT4.webp`,
	],
	vehicleDetails: [
		[
			{ type: 'car', value: 'Coupe' },
			{ type: 'engine', value: '60 kW (82 PS)' },
			{ type: 'mile', value: '130 km/h' },
			{ type: 'fuel', value: 'Electric' },
			{ type: 'transmission', value: 'Automatic transmission' }
		],
		[
			{ type: 'car', value: 'Coupe' },
			{ type: 'engine', value: '60 kW (82 PS)' },
			{ type: 'mile', value: '130 km/h' },
			{ type: 'fuel', value: 'Elektroantrieb' },
			{ type: 'transmission', value: 'Automatikgetriebe' }
		]
	],
	exteriorPaint: `${process.env.PUBLIC_URL}/images/Vehicles/Smart/ExteriorPaint/smartpaint.webp`,
	exteriorUpperPaint: `${process.env.PUBLIC_URL}/images/Vehicles/Smart/ExteriorPaint/smartupperpaint.webp`,
	interiorUpholstery: `${process.env.PUBLIC_URL}/images/Vehicles/Smart/InteriorUpholstery/smartupholstery.webp`,
	videoDetails: [
		{
			isTouch: false,
			logo: "",
			scene1text: 'smart EQ fortwo coupé BRABUS Line',
			scene1image: `${process.env.PUBLIC_URL}/images/Vehicles/Smart/Video/EXT000.webp`,
			scene2text: 'smart EQ fortwo coupé BRABUS Line',
			vehicleRotationImages: {
				1: `${process.env.PUBLIC_URL}/images/Vehicles/Smart/Video/EXT300.webp`,
				2: `${process.env.PUBLIC_URL}/images/Vehicles/Smart/Video/EXT310.webp`,
				3: `${process.env.PUBLIC_URL}/images/Vehicles/Smart/Video/EXT320.webp`,
				4: `${process.env.PUBLIC_URL}/images/Vehicles/Smart/Video/EXT330.webp`,
				5: `${process.env.PUBLIC_URL}/images/Vehicles/Smart/Video/EXT340.webp`,
				6: `${process.env.PUBLIC_URL}/images/Vehicles/Smart/Video/EXT350.webp`,
			},
			scene3text: 'smart EQ - Your dream is closer than ever',
			interiorImages: {
				1: `${process.env.PUBLIC_URL}/images/Vehicles/Smart/Video/INT1.webp`,
				2: `${process.env.PUBLIC_URL}/images/Vehicles/Smart/Video/INT2.webp`,
				3: `${process.env.PUBLIC_URL}/images/Vehicles/Smart/Video/INT2.webp`,
				4: `${process.env.PUBLIC_URL}/images/Vehicles/Smart/Video/INT4.webp`,
			},
			details: {
				textcolorblack: false,
				title1: 'Paint',
				text1: 'Bodypanels in cool silver (metallic)',
				title2: 'Upholstery',
				text2: '',
				title3: 'Engine',
				text3: 'Electric',
				title4: 'Power (PS/KW)',
				text4: '82 / 60',
				bgimage: `${process.env.PUBLIC_URL}/images/Vehicles/Smart/Video/EXT040.webp`,
			},
			packages2: {
				title: 'Equipments highlights.',
				1: {
					image: 'https://assets.oneweb.mercedes-benz.com/bbd/images/v1/4259/2/bf/c97702d7d89b633f58fd8bf9369efa65289d4.jpg',
					text: 'Exterior mirror, electrically',
					detailslink: '',
					code: 'V21',
				},
				2: {
					image: 'https://assets.oneweb.mercedes-benz.com/bbd/images/v1/4259/8/5e/db0b679162880eac868fd6140731f03bcdd97.jpg',
					text: 'Handbag fixation',
					detailslink: '',
					code: 'J66',
				},
			},
			packages3: {},
			outroText: 'Mercedes-Benz. The best or nothing.',
			endText: 'Experience now the smart EQ fortwo coupé BRABUS Line',
			endBtnLink: 'https://www.mercedes-benz.com',
			endBtnText: 'Contact us',
			endImage: '',
			endImageTransparent: `${process.env.PUBLIC_URL}/images/Vehicles/Smart/Video/EXT040.webp`,
			endBkgImage:
				'https://assets.oneweb.mercedes-benz.com/bbd/images/v1/4216/3/ce/f19bab99ea61f29d8282b8d9d13b82b758673.jpg',
			qr: 'https://0cn.de/pfbdb+qr-400.png',
		},
		{
			isTouch: false,
			logo: "",
			scene1text: 'smart EQ fortwo',
			scene1image: `${process.env.PUBLIC_URL}/images/Vehicles/Smart/Video/EXT000.webp`,
			scene2text: 'smart EQ fortwo',
			vehicleRotationImages: {
				1: `${process.env.PUBLIC_URL}/images/Vehicles/Smart/Video/EXT300.webp`,
				2: `${process.env.PUBLIC_URL}/images/Vehicles/Smart/Video/EXT310.webp`,
				3: `${process.env.PUBLIC_URL}/images/Vehicles/Smart/Video/EXT320.webp`,
				4: `${process.env.PUBLIC_URL}/images/Vehicles/Smart/Video/EXT330.webp`,
				5: `${process.env.PUBLIC_URL}/images/Vehicles/Smart/Video/EXT340.webp`,
				6: `${process.env.PUBLIC_URL}/images/Vehicles/Smart/Video/EXT350.webp`,
			},
			scene3text: 'smart EQ - Your dream is closer than ever',
			interiorImages: {
				1: `${process.env.PUBLIC_URL}/images/Vehicles/Smart/Video/INT1.webp`,
				2: `${process.env.PUBLIC_URL}/images/Vehicles/Smart/Video/INT2.webp`,
				3: `${process.env.PUBLIC_URL}/images/Vehicles/Smart/Video/INT2.webp`,
				4: `${process.env.PUBLIC_URL}/images/Vehicles/Smart/Video/INT4.webp`,
			},
			details: {
				textcolorblack: false,
				title1: "Farbe",
				text1: "Bodypanels in cool silver (metallic)",
				title2: "Polster",
				text2: "Pulse",
				title3: "Motor",
				text3: "Elektroantrieb",
				title4: "Leistung (PS/KW)",
				text4: "82 / 60",
				bgimage: `${process.env.PUBLIC_URL}/images/Vehicles/Smart/Video/EXT040.webp`,
			},
			packages2: {
				title: 'Ausstattungshighlights',
				1: {
					image: "https://assets.oneweb.mercedes-benz.com/bbd/images/v1/4259/2/bf/c97702d7d89b633f58fd8bf9369efa65289d4.jpg",
					text: "Außenspiegel elektrisch",
					detailslink: "",
					code: "V21"
				},
				2: {
					image: "https://assets.oneweb.mercedes-benz.com/bbd/images/v1/4259/8/5e/db0b679162880eac868fd6140731f03bcdd97.jpg",
					text: "Haltegurt zur Befestigung von Gegenständen auf dem Beifahrersitz",
					detailslink: "",
					code: "J66"
				}
			},
			packages3: {},
			outroText: "Mercedes-Benz. Das Beste oder nichts.",
			endText: "Erleben Sie jetzt den smart EQ fortwo",
			endBtnLink: "https://www.mercedes-benz.de",
			endBtnText: "Jetzt Anfragen",
			endImage: '',
			endImageTransparent: `${process.env.PUBLIC_URL}/images/Vehicles/Smart/Video/EXT040.webp`,
			endBkgImage:
				'https://assets.oneweb.mercedes-benz.com/bbd/images/v1/4216/3/ce/f19bab99ea61f29d8282b8d9d13b82b758673.jpg',
			qr: 'https://0cn.de/pfbdb+qr-400.png',
		},
	],
	tabs: [
		{
			name: 'generalData',
			details: [
				{ key: 'numberOfDoors', value: '3' },
				{ key: 'numberOfSeats', value: '2' },
				{ key: 'length', value: '2.695 mm' },
				{ key: 'emptyWeight', value: '1.020 kg' },
				{ key: 'payload', value: '215 kg' },
				{ key: 'maxWeight', value: '1.310 kg' }
			],
		},
		{
			name: 'packages',
			details: [
				{ key: '15U', value: 'demoPage.equipmentsComponents.demoSmartConstants.packages.15U' },
				{ key: 'U14', value: 'demoPage.equipmentsComponents.demoSmartConstants.packages.U14' },
				{ key: 'P59', value: 'demoPage.equipmentsComponents.demoSmartConstants.packages.P59' },
				{ key: 'P45', value: 'demoPage.equipmentsComponents.demoSmartConstants.packages.P45' },
				{ key: 'P23', value: 'demoPage.equipmentsComponents.demoSmartConstants.packages.P23' }
			],
			type: 'equipment',
		},
		{
			name: 'exterior',
			details: [
				{ key: 'EN2U', value: 'demoPage.equipmentsComponents.demoSmartConstants.paint.EN2U' },
				{ key: 'EDA', value: 'demoPage.equipmentsComponents.demoSmartConstants.paint.EDA' },
				{ key: 'R69', value: 'demoPage.equipmentsComponents.demoSmartConstants.exterior.R69' },
				{ key: 'V21', value: 'demoPage.equipmentsComponents.demoSmartConstants.exterior.V21' },
				{ key: 'V18', value: 'demoPage.equipmentsComponents.demoSmartConstants.exterior.V18' },
				{ key: 'V15', value: 'demoPage.equipmentsComponents.demoSmartConstants.exterior.V15' },
				{ key: 'V13', value: 'demoPage.equipmentsComponents.demoSmartConstants.exterior.V13' },
				{ key: 'U37', value: 'demoPage.equipmentsComponents.demoSmartConstants.exterior.U37' },
				{ key: 'K32', value: 'demoPage.equipmentsComponents.demoSmartConstants.exterior.K32' },
				{ key: 'H02', value: 'demoPage.equipmentsComponents.demoSmartConstants.exterior.H02' },
				{ key: 'E22', value: 'demoPage.equipmentsComponents.demoSmartConstants.exterior.E22' }
			],
			type: 'equipment',
		},
		{
			name: 'interior',
			details: [
				{ key: '877', value: 'demoPage.equipmentsComponents.demoSmartConstants.interior.877' },
				{ key: 'J66', value: 'demoPage.equipmentsComponents.demoSmartConstants.interior.J66' },
				{ key: 'J59', value: 'demoPage.equipmentsComponents.demoSmartConstants.interior.J59' },
				{ key: 'J37', value: 'demoPage.equipmentsComponents.demoSmartConstants.interior.J37' },
				{ key: 'I63', value: 'demoPage.equipmentsComponents.demoSmartConstants.interior.I63' },
				{ key: 'I57', value: 'demoPage.equipmentsComponents.demoSmartConstants.interior.I57' },
				{ key: 'I34', value: 'demoPage.equipmentsComponents.demoSmartConstants.interior.I34' },
				{ key: 'I01', value: 'demoPage.equipmentsComponents.demoSmartConstants.interior.I01' },
				{ key: '54U', value: 'demoPage.equipmentsComponents.demoSmartConstants.interior.54U' },
				{ key: '4U1', value: 'demoPage.equipmentsComponents.demoSmartConstants.interior.4U1' },
				{ key: '15U', value: 'demoPage.equipmentsComponents.demoSmartConstants.interior.15U' }
			],
			type: 'equipment',
		},
		{
			name: 'infotainment',
			details: [
				{ key: '524', value: 'demoPage.equipmentsComponents.demoSmartConstants.infotainment.524' },
				{ key: 'K36', value: 'demoPage.equipmentsComponents.demoSmartConstants.infotainment.K36' },
				{ key: '14U', value: 'demoPage.equipmentsComponents.demoSmartConstants.infotainment.14U' }
			],
			type: 'equipment',
		},
		{
			name: 'safety',
			details: [
				{ key: '258', value: 'demoPage.equipmentsComponents.demoSmartConstants.safety.258' },
				{ key: 'V52', value: 'demoPage.equipmentsComponents.demoSmartConstants.safety.V52' },
				{ key: 'C68', value: 'demoPage.equipmentsComponents.demoSmartConstants.safety.C68' }
			],
			type: 'equipment',
		},
		{
			name: 'technicalDetails',
			details: [
				{ key: 'B49', value: 'demoPage.equipmentsComponents.demoSmartConstants.technicalDetails.B49' },
				{ key: 'B48', value: 'demoPage.equipmentsComponents.demoSmartConstants.technicalDetails.B48' }
			],
			type: 'equipment',
		},
		{
			name: 'meServices',
			type: 'me-services',
			details: [[]]
		},
	],
};

const SprinterData = {
	name: [
		'Sprinter 311 CDI Panel Van Compact',
		'Sprinter 311 CDI Kasten Kompakt'
	],
	routeId: 'Sprinter',
	assetBasePath: `${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/`,
	equipmentImageContext: require.context('/public/images/Vehicles/Sprinter/Equipment', true, /\.(jpe?g|webp)$/i),
	night: {
		images: [
			`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/night/EXT000.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/night/EXT010.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/night/EXT020.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/night/EXT030.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/night/EXT040.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/night/EXT050.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/night/EXT060.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/night/EXT070.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/night/EXT080.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/night/EXT090.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/night/EXT100.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/night/EXT110.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/night/EXT120.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/night/EXT130.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/night/EXT140.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/night/EXT150.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/night/EXT160.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/night/EXT170.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/night/EXT180.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/night/EXT190.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/night/EXT200.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/night/EXT210.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/night/EXT220.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/night/EXT230.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/night/EXT240.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/night/EXT250.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/night/EXT260.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/night/EXT270.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/night/EXT280.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/night/EXT290.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/night/EXT300.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/night/EXT310.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/night/EXT320.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/night/EXT330.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/night/EXT340.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/night/EXT350.webp`,
		],
		interiorImage: `${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/night/INT1.webp`,
		interiorImages: [
			`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/night/INT1.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/night/INT2.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/night/INT3.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/night/INT4.webp`,
		]
	},
	images: [
		`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/EXT000.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/EXT010.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/EXT020.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/EXT030.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/EXT040.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/EXT050.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/EXT060.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/EXT070.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/EXT080.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/EXT090.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/EXT100.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/EXT110.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/EXT120.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/EXT130.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/EXT140.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/EXT150.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/EXT160.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/EXT170.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/EXT180.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/EXT190.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/EXT200.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/EXT210.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/EXT220.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/EXT230.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/EXT240.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/EXT250.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/EXT260.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/EXT270.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/EXT280.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/EXT290.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/EXT300.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/EXT310.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/EXT320.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/EXT330.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/EXT340.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/EXT350.webp`,
	],
	interiorImage: `${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/INT1.webp`,
	interiorImages: [
		`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/INT1.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/INT2.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/INT3.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/INT4.webp`,
	],
	vehicleDetails: [
		[
			{ type: 'car', value: 'Commercial Van' },
			{ type: 'engine', value: '84 kW (114 PS)' },
			{ type: 'mile', value: '145 km/h' },
			{ type: 'fuel', value: 'Diesel' },
			{ type: 'transmission', value: 'Manual transmission' }
		],
		[
			{ type: 'car', value: 'Transporter' },
			{ type: 'engine', value: '84 kW (114 PS)' },
			{ type: 'mile', value: '145 km/h' },
			{ type: 'fuel', value: 'Diesel' },
			{ type: 'transmission', value: 'Mechanisches Getriebe' }
		]
	],
	exteriorPaint: `${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/ExteriorPaint/sprinterpaint.webp`,
	interiorUpholstery: `${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/InteriorUpholstery/sprinterupholstery.webp`,
	videoDetails: [
		{
			isTouch: false,
			logo: "",
			scene1text: 'Sprinter 311 CDI Panel Van Compact',
			scene1image: `${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/Video/EXT000.webp`,
			scene2text: 'Sprinter 311 CDI Panel Van Compact',
			vehicleRotationImages: {
				1: `${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/Video/EXT300.webp`,
				2: `${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/Video/EXT310.webp`,
				3: `${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/Video/EXT320.webp`,
				4: `${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/Video/EXT330.webp`,
				5: `${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/Video/EXT340.webp`,
				6: `${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/Video/EXT350.webp`,
			},
			scene3text: 'Sprinter - Your dream is closer than ever',
			interiorImages: {
				1: `${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/Video/INT1.webp`,
				2: `${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/Video/INT2.webp`,
				3: `${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/Video/INT3.webp`,
				4: `${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/Video/INT3.webp`,
			},
			details: {
				textcolorblack: false,
				title1: 'Paint',
				text1: 'Arctic white',
				title2: 'Upholstery',
				text2: 'Fabric Maturin black',
				title3: 'Engine',
				text3: 'Diesel',
				title4: 'Power (PS/KW)',
				text4: '114 / 84',
				bgimage: `${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/Video/EXT040.webp`,
			},
			packages2: {
				title: 'Equipments highlights.',
				1: {
					image: 'https://assets.oneweb.mercedes-benz.com/bbd/images/v1/5687/5/bc/a2729c34c487e8d6e3f98900fc226df2344c2.jpg',
					text: 'Steel wheels 6.5 J x 16',
					detailslink: '',
					code: 'RS3',
				},
				2: {
					image: 'https://assets.oneweb.mercedes-benz.com/bbd/images/v1/5687/1/c5/efca4f3b34580d9a891a26d4174f2d143b365.jpg',
					text: 'Rear door step',
					detailslink: '',
					code: 'W73',
				},
			},
			packages3: {},
			outroText: 'Mercedes-Benz. The best or nothing.',
			endText: 'Experience now the Sprinter 311 CDI Panel Van Compact',
			endBtnLink: 'https://www.mercedes-benz.com',
			endBtnText: 'Contact us',
			endImage: '',
			endImageTransparent: `${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/Video/EXT040.webp`,
			endBkgImage:
				'https://assets.oneweb.mercedes-benz.com/bbd/images/v1/4216/3/ce/f19bab99ea61f29d8282b8d9d13b82b758673.jpg',
			qr: 'https://0cn.de/pfbdb+qr-400.png',
		},
		{
			isTouch: false,
			logo: "",
			scene1text: 'Sprinter 311 CDI Kasten Kompakt',
			scene1image: `${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/Video/EXT000.webp`,
			scene2text: 'Sprinter 311 CDI Kasten Kompakt',
			vehicleRotationImages: {
				1: `${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/Video/EXT300.webp`,
				2: `${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/Video/EXT310.webp`,
				3: `${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/Video/EXT320.webp`,
				4: `${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/Video/EXT330.webp`,
				5: `${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/Video/EXT340.webp`,
				6: `${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/Video/EXT350.webp`,
			},
			scene3text: 'Sprinter - Your dream is closer than ever',
			interiorImages: {
				1: `${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/Video/INT1.webp`,
				2: `${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/Video/INT2.webp`,
				3: `${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/Video/INT3.webp`,
				4: `${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/Video/INT3.webp`,
			},
			details: {
				textcolorblack: false,
				title1: "Farbe",
				text1: "Arktikweiß",
				title2: "Polster",
				text2: "Stoff Maturin schwarz",
				title3: "Motor",
				text3: "Dieselantrieb",
				title4: "Leistung (PS/KW)",
				text4: "114 / 84",
				bgimage: `${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/Video/EXT040.webp`,
			},
			packages2: {
				title: 'Ausstattungshighlights',
				1: {
					image: "https://assets.oneweb.mercedes-benz.com/bbd/images/v1/5965/6/b1/ff33d1c7bb37b163e0f94e7f4758338c2a495.jpg",
					text: "Stahlräder 6,5 J x 16",
					detailslink: "",
					code: "RS3"
				},
				2: {
					image: "https://assets.oneweb.mercedes-benz.com/bbd/images/v1/5965/a/71/b1ca7b7578e4ed7127d7447f97cbfdfb0ea65.jpg",
					text: "Auftritt Hecktür",
					detailslink: "",
					code: "W73"
				}
			},
			packages3: {},
			outroText: "Mercedes-Benz. Das Beste oder nichts.",
			endText: "Erleben Sie jetzt den Sprinter 311 CDI Kasten Kompakt",
			endBtnLink: "https://www.mercedes-benz.de",
			endBtnText: "Jetzt Anfragen",
			endImage: '',
			endImageTransparent: `${process.env.PUBLIC_URL}/images/Vehicles/Sprinter/Video/EXT040.webp`,
			endBkgImage:
				'https://assets.oneweb.mercedes-benz.com/bbd/images/v1/4216/3/ce/f19bab99ea61f29d8282b8d9d13b82b758673.jpg',
			qr: 'https://0cn.de/pfbdb+qr-400.png',
		}
	],
	tabs: [
		{
			name: 'generalData',
			details: [
				{ key: 'numberOfDoors', value: '4' },
				{ key: 'numberOfSeats', value: '2' },
				{ key: 'length', value: '5.932 mm' },
				{ key: 'emptyWeight', value: '2.135 kg' },
				{ key: 'payload', value: '1.198 kg' },
				{ key: 'maxWeight', value: '3.500 kg' }
			],
		},
		{
			name: 'packages',
			details: [],
			type: 'equipment',
		},
		{
			name: 'exterior',
			details: [
				{ key: '9147', value: 'demoPage.equipmentsComponents.demoSprinterConstants.paint.9147' },
				{ key: 'RS3', value: 'demoPage.equipmentsComponents.demoSprinterConstants.exterior.RS3' },
				{ key: 'W73', value: 'demoPage.equipmentsComponents.demoSprinterConstants.exterior.W73' },
				{ key: 'T16', value: 'demoPage.equipmentsComponents.demoSprinterConstants.exterior.T16' },
				{ key: 'LE1', value: 'demoPage.equipmentsComponents.demoSprinterConstants.exterior.LE1' },
				{ key: 'LB5', value: 'demoPage.equipmentsComponents.demoSprinterConstants.exterior.LB5' },
				{ key: 'IR2', value: 'demoPage.equipmentsComponents.demoSprinterConstants.exterior.IR2' },
				{ key: 'F68', value: 'demoPage.equipmentsComponents.demoSprinterConstants.exterior.F68' }
			],
			type: 'equipment',
		},
		{
			name: 'interior',
			details: [
				{ key: 'VF7', value: 'demoPage.equipmentsComponents.demoSprinterConstants.interior.VF7' },
				{ key: 'W61', value: 'demoPage.equipmentsComponents.demoSprinterConstants.interior.W61' },
				{ key: 'W54', value: 'demoPage.equipmentsComponents.demoSprinterConstants.interior.W54' },
				{ key: 'VK8', value: 'demoPage.equipmentsComponents.demoSprinterConstants.interior.VK8' },
				{ key: 'VF7', value: 'demoPage.equipmentsComponents.demoSprinterConstants.interior.VF7' },
				{ key: 'VA7', value: 'demoPage.equipmentsComponents.demoSprinterConstants.interior.VA7' },
				{ key: 'VA3', value: 'demoPage.equipmentsComponents.demoSprinterConstants.interior.VA3' },
				{ key: 'V42', value: 'demoPage.equipmentsComponents.demoSprinterConstants.interior.V42' },
				{ key: 'T86', value: 'demoPage.equipmentsComponents.demoSprinterConstants.interior.T86' },
				{ key: 'T77', value: 'demoPage.equipmentsComponents.demoSprinterConstants.interior.T77' },
				{ key: 'S25', value: 'demoPage.equipmentsComponents.demoSprinterConstants.interior.S25' },
				{ key: 'S22', value: 'demoPage.equipmentsComponents.demoSprinterConstants.interior.S22' },
				{ key: 'S04', value: 'demoPage.equipmentsComponents.demoSprinterConstants.interior.S04' },
				{ key: 'S02', value: 'demoPage.equipmentsComponents.demoSprinterConstants.interior.S02' },
				{ key: 'LC2', value: 'demoPage.equipmentsComponents.demoSprinterConstants.interior.LC2' },
				{ key: 'L65', value: 'demoPage.equipmentsComponents.demoSprinterConstants.interior.L65' },
				{ key: 'D50', value: 'demoPage.equipmentsComponents.demoSprinterConstants.interior.D50' },
				{ key: 'CL1', value: 'demoPage.equipmentsComponents.demoSprinterConstants.interior.CL1' }
			],
			type: 'equipment',
		},
		{
			name: 'infotainment',
			details: [
				{ key: 'J65', value: 'demoPage.equipmentsComponents.demoSprinterConstants.infotainment.J65' },
				{ key: 'E1O', value: 'demoPage.equipmentsComponents.demoSprinterConstants.infotainment.E1O' }
			],
			type: 'equipment',
		},
		{
			name: 'safety',
			details: [
				{ key: 'LA2', value: 'demoPage.equipmentsComponents.demoSprinterConstants.safety.LA2' },
				{ key: 'JA8', value: 'demoPage.equipmentsComponents.demoSprinterConstants.safety.JA8' },
				{ key: 'EY6', value: 'demoPage.equipmentsComponents.demoSprinterConstants.safety.EY6' },
				{ key: 'EY5', value: 'demoPage.equipmentsComponents.demoSprinterConstants.safety.EY5' }
			],
			type: 'equipment',
		},
		{
			name: 'technicalDetails',
			details: [
				{ key: 'MU1', value: 'demoPage.equipmentsComponents.demoSprinterConstants.technicalDetails.MU1' },
				{ key: 'GD8', value: 'demoPage.equipmentsComponents.demoSprinterConstants.technicalDetails.GD8' }
			],
			type: 'equipment',
		},
		{
			name: 'meServices',
			type: 'me-services',
			details: [[]]
		},
	],
};

const V300Data = {
	name: [
		'V 300 d AVANTGARDE EDITION Long',
		'V 300 d AVANTGARDE EDITION Lang'
	],
	routeId: 'V-Class',
	assetBasePath: `${process.env.PUBLIC_URL}/images/Vehicles/V300`,
	equipmentImageContext: require.context('/public/images/Vehicles/V300/Equipment', true, /\.(jpe?g|webp)$/i),
	night: {
		images: [
			`${process.env.PUBLIC_URL}/images/Vehicles/V300/night/EXT000.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/V300/night/EXT010.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/V300/night/EXT020.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/V300/night/EXT030.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/V300/night/EXT040.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/V300/night/EXT050.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/V300/night/EXT060.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/V300/night/EXT070.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/V300/night/EXT080.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/V300/night/EXT090.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/V300/night/EXT100.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/V300/night/EXT110.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/V300/night/EXT120.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/V300/night/EXT130.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/V300/night/EXT140.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/V300/night/EXT150.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/V300/night/EXT160.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/V300/night/EXT170.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/V300/night/EXT180.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/V300/night/EXT190.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/V300/night/EXT200.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/V300/night/EXT210.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/V300/night/EXT220.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/V300/night/EXT230.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/V300/night/EXT240.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/V300/night/EXT250.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/V300/night/EXT260.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/V300/night/EXT270.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/V300/night/EXT280.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/V300/night/EXT290.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/V300/night/EXT300.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/V300/night/EXT310.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/V300/night/EXT320.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/V300/night/EXT330.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/V300/night/EXT340.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/V300/night/EXT350.webp`,
		],
		interiorImage: `${process.env.PUBLIC_URL}/images/Vehicles/V300/night/INT1.webp`,
		exteriorImage: `${process.env.PUBLIC_URL}/images/Vehicles/V300/night/EXT040.webp`,
		interiorImages: [
			`${process.env.PUBLIC_URL}/images/Vehicles/V300/night/INT1.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/V300/night/INT2.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/V300/night/INT3.webp`,
			`${process.env.PUBLIC_URL}/images/Vehicles/V300/night/INT4.webp`,
		],
	},
	images: [
		`${process.env.PUBLIC_URL}/images/Vehicles/V300/EXT000.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/V300/EXT010.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/V300/EXT020.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/V300/EXT030.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/V300/EXT040.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/V300/EXT050.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/V300/EXT060.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/V300/EXT070.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/V300/EXT080.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/V300/EXT090.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/V300/EXT100.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/V300/EXT110.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/V300/EXT120.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/V300/EXT130.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/V300/EXT140.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/V300/EXT150.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/V300/EXT160.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/V300/EXT170.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/V300/EXT180.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/V300/EXT190.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/V300/EXT200.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/V300/EXT210.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/V300/EXT220.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/V300/EXT230.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/V300/EXT240.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/V300/EXT250.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/V300/EXT260.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/V300/EXT270.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/V300/EXT280.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/V300/EXT290.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/V300/EXT300.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/V300/EXT310.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/V300/EXT320.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/V300/EXT330.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/V300/EXT340.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/V300/EXT350.webp`,
	],
	interiorImage: `${process.env.PUBLIC_URL}/images/Vehicles/V300/INT1.webp`,
	exteriorImage: `${process.env.PUBLIC_URL}/images/Vehicles/V300/EXT040.webp`,
	interiorImages: [
		`${process.env.PUBLIC_URL}/images/Vehicles/V300/INT1.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/V300/INT2.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/V300/INT3.webp`,
		`${process.env.PUBLIC_URL}/images/Vehicles/V300/INT4.webp`,
	],
	vehicleDetails: [
		[
			{ type: 'car', value: 'Van' },
			{ type: 'engine', value: '176 kW (239 PS)' },
			{ type: 'mile', value: '220 km/h' },
			{ type: 'fuel', value: 'Diesel' },
			{ type: 'transmission', value: 'Automatic transmission' }
		],
		[
			{ type: 'car', value: 'Van' },
			{ type: 'engine', value: '176 kW (239 PS)' },
			{ type: 'mile', value: '220 km/h' },
			{ type: 'fuel', value: 'Diesel' },
			{ type: 'transmission', value: 'Automatikgetriebe' }
		]
	],
	exteriorPaint: `${process.env.PUBLIC_URL}/images/Vehicles/V300/ExteriorPaint/vclasspaint.webp`,
	interiorUpholstery: `${process.env.PUBLIC_URL}/images/Vehicles/V300/InteriorUpholstery/vclassupholstery.webp`,
	videoDetails: [
		{
			isTouch: false,
			logo: "",
			scene1text: 'V 300 d AVANTGARDE EDITION Long',
			scene1image: `${process.env.PUBLIC_URL}/images/Vehicles/V300/Video/EXT000.webp`,
			scene2text: 'V 300 d AVANTGARDE EDITION Long',
			vehicleRotationImages: {
				1: `${process.env.PUBLIC_URL}/images/Vehicles/V300/Video/EXT300.webp`,
				2: `${process.env.PUBLIC_URL}/images/Vehicles/V300/Video/EXT310.webp`,
				3: `${process.env.PUBLIC_URL}/images/Vehicles/V300/Video/EXT320.webp`,
				4: `${process.env.PUBLIC_URL}/images/Vehicles/V300/Video/EXT330.webp`,
				5: `${process.env.PUBLIC_URL}/images/Vehicles/V300/Video/EXT340.webp`,
				6: `${process.env.PUBLIC_URL}/images/Vehicles/V300/Video/EXT350.webp`,
			},
			scene3text: 'V Class - Your dream is closer than ever',
			interiorImages: {
				1: `${process.env.PUBLIC_URL}/images/Vehicles/V300/Video/INT1.webp`,
				2: `${process.env.PUBLIC_URL}/images/Vehicles/V300/Video/INT2.webp`,
				3: `${process.env.PUBLIC_URL}/images/Vehicles/V300/Video/INT3.webp`,
				4: `${process.env.PUBLIC_URL}/images/Vehicles/V300/Video/INT3.webp`,
			},
			details: {
				textcolorblack: false,
				title1: 'Paint',
				text1: 'Selenite grey metallic',
				title2: 'Upholstery',
				text2: 'Leather Lugano, black',
				title3: 'Engine',
				text3: 'Diesel',
				title4: 'Power (PS/KW)',
				text4: '239 / 176',
				bgimage: `${process.env.PUBLIC_URL}/images/Vehicles/V300/Video/EXT040.webp`,
			},
			packages2: {
				title: 'Equipments highlights.',
				1: {
					image: 'https://assets.oneweb.mercedes-benz.com/bbd/images/v1/5688/8/eb/b85fddf0d1cf8ab2bf50d2ac061e67f0c2296.jpg',
					text: 'AMG trim',
					detailslink: '',
					code: 'FB1',
				},
				2: {
					image: 'https://assets.oneweb.mercedes-benz.com/bbd/images/v1/5688/7/1c/6144b3e464bf84c0d679441c55edbaf0f2044.jpg',
					text: 'Parcel Shelf',
					detailslink: '',
					code: 'YG4',
				},
			},
			packages3: {},
			outroText: 'Mercedes-Benz. The best or nothing.',
			endText: 'Experience now the V 300 d AVANTGARDE EDITION Long',
			endBtnLink: 'https://www.mercedes-benz.com',
			endBtnText: 'Contact us',
			endImage: '',
			endImageTransparent: `${process.env.PUBLIC_URL}/images/Vehicles/V300/Video/EXT040.webp`,
			endBkgImage:
				'https://assets.oneweb.mercedes-benz.com/bbd/images/v1/4216/3/ce/f19bab99ea61f29d8282b8d9d13b82b758673.jpg',
			qr: 'https://0cn.de/pfbdb+qr-400.png',
		},
		{
			isTouch: false,
			logo: "",
			scene1text: 'V 300 d AVANTGARDE EDITION Lang',
			scene1image: `${process.env.PUBLIC_URL}/images/Vehicles/V300/Video/EXT000.webp`,
			scene2text: 'V 300 d AVANTGARDE EDITION Lang',
			vehicleRotationImages: {
				1: `${process.env.PUBLIC_URL}/images/Vehicles/V300/Video/EXT300.webp`,
				2: `${process.env.PUBLIC_URL}/images/Vehicles/V300/Video/EXT310.webp`,
				3: `${process.env.PUBLIC_URL}/images/Vehicles/V300/Video/EXT320.webp`,
				4: `${process.env.PUBLIC_URL}/images/Vehicles/V300/Video/EXT330.webp`,
				5: `${process.env.PUBLIC_URL}/images/Vehicles/V300/Video/EXT340.webp`,
				6: `${process.env.PUBLIC_URL}/images/Vehicles/V300/Video/EXT350.webp`,
			},
			scene3text: 'V Class - Your dream is closer than ever',
			interiorImages: {
				1: `${process.env.PUBLIC_URL}/images/Vehicles/V300/Video/INT1.webp`,
				2: `${process.env.PUBLIC_URL}/images/Vehicles/V300/Video/INT2.webp`,
				3: `${process.env.PUBLIC_URL}/images/Vehicles/V300/Video/INT3.webp`,
				4: `${process.env.PUBLIC_URL}/images/Vehicles/V300/Video/INT3.webp`,
			},
			details: {
				textcolorblack: false,
				title1: "Farbe",
				text1: "Selenitgrau metallic",
				title2: "Polster",
				text2: "Leder Lugano schwarz",
				title3: "Motor",
				text3: "Dieselantrieb",
				title4: "Leistung (PS/KW)",
				text4: "239 / 176",
				bgimage: `${process.env.PUBLIC_URL}/images/Vehicles/V300/Video/EXT040.webp`,
			},
			packages2: {
				title: 'Ausstattungshighlights',
				1: {
					image: "https://assets.oneweb.mercedes-benz.com/bbd/images/v1/6086/1/60/6f6b1c62800d6e4db7015b9af01e115ebbf4f.jpg",
					text: "AMG Zierelemente",
					detailslink: "",
					code: "FB1"
				},
				2: {
					image: "https://assets.oneweb.mercedes-benz.com/bbd/images/v1/6086/6/ff/a3e8444559006e1ac0fba93bede8eb5be07dd.jpg",
					text: "Laderaumunterteilung",
					detailslink: "",
					code: "YG4"
				}
			},
			packages3: {},
			outroText: "Mercedes-Benz. Das Beste oder nichts.",
			endText: "Erleben Sie jetzt den V 300 d AVANTGARDE EDITION Lang",
			endBtnLink: "https://www.mercedes-benz.de",
			endBtnText: "Jetzt Anfragen",
			endImage: '',
			endImageTransparent: `${process.env.PUBLIC_URL}/images/Vehicles/V300/Video/EXT040.webp`,
			endBkgImage:
				'https://assets.oneweb.mercedes-benz.com/bbd/images/v1/4216/3/ce/f19bab99ea61f29d8282b8d9d13b82b758673.jpg',
			qr: 'https://0cn.de/pfbdb+qr-400.png',
		}
	],
	tabs: [
		{
			name: 'generalData',
			details: [
				{ key: 'numberOfDoors', value: '5' },
				{ key: 'numberOfSeats', value: '7' },
				{ key: 'length', value: '5.140 mm' },
				{ key: 'emptyWeight', value: '2.313 kg' },
				{ key: 'payload', value: '787 kg' },
				{ key: 'maxWeight', value: '3.100 kg' }
			],
			type: 'simple',
		},
		{
			name: 'packages',
			details: [
				{ key: 'VQ1', value: 'demoPage.equipmentsComponents.demoV300Constants.packages.VQ1' },
				{ key: 'PS2', value: 'demoPage.equipmentsComponents.demoV300Constants.packages.PS2' },
				{ key: 'LA2', value: 'demoPage.equipmentsComponents.demoV300Constants.packages.LA2' },
				{ key: 'JP2', value: 'demoPage.equipmentsComponents.demoV300Constants.packages.JP2' },
				{ key: 'FP3', value: 'demoPage.equipmentsComponents.demoV300Constants.packages.FP3' }
			],
			type: 'equipment',
		},
		{
			name: 'exterior',
			details: [
				{ key: '7992', value: 'demoPage.equipmentsComponents.demoV300Constants.paint.7992' },
				{ key: 'CM2', value: 'demoPage.equipmentsComponents.demoV300Constants.paint.CM2' },
				{ key: 'RK4', value: 'demoPage.equipmentsComponents.demoV300Constants.exterior.RK4' },
				{ key: 'W70', value: 'demoPage.equipmentsComponents.demoV300Constants.exterior.W70' },
				{ key: 'RM7', value: 'demoPage.equipmentsComponents.demoV300Constants.exterior.RM7' },
				{ key: 'LG8', value: 'demoPage.equipmentsComponents.demoV300Constants.exterior.LG8' },
				{ key: 'LG2', value: 'demoPage.equipmentsComponents.demoV300Constants.exterior.LG2' },
				{ key: 'LE1', value: 'demoPage.equipmentsComponents.demoV300Constants.exterior.LE1' },
				{ key: 'LC5', value: 'demoPage.equipmentsComponents.demoV300Constants.exterior.LC5' },
				{ key: 'LB9', value: 'demoPage.equipmentsComponents.demoV300Constants.exterior.LB9' },
				{ key: 'FB4', value: 'demoPage.equipmentsComponents.demoV300Constants.exterior.FB4' },
				{ key: 'F69', value: 'demoPage.equipmentsComponents.demoV300Constants.exterior.F69' },
				{ key: 'F65', value: 'demoPage.equipmentsComponents.demoV300Constants.exterior.F65' },
				{ key: 'D14', value: 'demoPage.equipmentsComponents.demoV300Constants.exterior.D14' },
				{ key: 'BS1', value: 'demoPage.equipmentsComponents.demoV300Constants.exterior.BS1' }
			],
			type: 'equipment',
		},
		{
			name: 'interior',
			details: [
				{ key: 'FB1', value: 'demoPage.equipmentsComponents.demoV300Constants.interior.FB1' },
				{ key: 'VX7', value: 'demoPage.equipmentsComponents.demoV300Constants.interior.VX7' },
				{ key: 'YG4', value: 'demoPage.equipmentsComponents.demoV300Constants.interior.YG4' },
				{ key: 'W68', value: 'demoPage.equipmentsComponents.demoV300Constants.interior.W68' },
				{ key: 'W64', value: 'demoPage.equipmentsComponents.demoV300Constants.interior.W64' },
				{ key: 'W29', value: 'demoPage.equipmentsComponents.demoV300Constants.interior.W29' },
				{ key: 'VH1', value: 'demoPage.equipmentsComponents.demoV300Constants.interior.VH1' },
				{ key: 'VD7', value: 'demoPage.equipmentsComponents.demoV300Constants.interior.VD7' },
				{ key: 'US7', value: 'demoPage.equipmentsComponents.demoV300Constants.interior.US7' },
				{ key: 'US6', value: 'demoPage.equipmentsComponents.demoV300Constants.interior.US6' },
				{ key: 'US5', value: 'demoPage.equipmentsComponents.demoV300Constants.interior.US5' },
				{ key: 'UR1', value: 'demoPage.equipmentsComponents.demoV300Constants.interior.UR1' },
				{ key: 'U73', value: 'demoPage.equipmentsComponents.demoV300Constants.interior.U73' },
				{ key: 'T74', value: 'demoPage.equipmentsComponents.demoV300Constants.interior.T74' },
				{ key: 'SZ8', value: 'demoPage.equipmentsComponents.demoV300Constants.interior.SZ8' },
				{ key: 'SZ7', value: 'demoPage.equipmentsComponents.demoV300Constants.interior.SZ7' },
				{ key: 'SH8', value: 'demoPage.equipmentsComponents.demoV300Constants.interior.SH8' },
				{ key: 'SE1', value: 'demoPage.equipmentsComponents.demoV300Constants.interior.SE1' },
				{ key: 'SE0', value: 'demoPage.equipmentsComponents.demoV300Constants.interior.SE0' },
				{ key: 'SB2', value: 'demoPage.equipmentsComponents.demoV300Constants.interior.SB2' },
				{ key: 'SB1', value: 'demoPage.equipmentsComponents.demoV300Constants.interior.SB1' },
				{ key: 'LC6', value: 'demoPage.equipmentsComponents.demoV300Constants.interior.LC6' },
				{ key: 'LC4', value: 'demoPage.equipmentsComponents.demoV300Constants.interior.LC4' },
				{ key: 'LC1', value: 'demoPage.equipmentsComponents.demoV300Constants.interior.LC1' },
				{ key: 'HZ7', value: 'demoPage.equipmentsComponents.demoV300Constants.interior.HZ7' },
				{ key: 'HH4', value: 'demoPage.equipmentsComponents.demoV300Constants.interior.HH4' },
				{ key: 'H16', value: 'demoPage.equipmentsComponents.demoV300Constants.interior.H16' },
				{ key: 'H15', value: 'demoPage.equipmentsComponents.demoV300Constants.interior.H15' },
				{ key: 'H12', value: 'demoPage.equipmentsComponents.demoV300Constants.interior.H12' },
				{ key: 'FS5', value: 'demoPage.equipmentsComponents.demoV300Constants.interior.FS5' },
				{ key: 'FG0', value: 'demoPage.equipmentsComponents.demoV300Constants.interior.FG0' },
				{ key: 'FB1', value: 'demoPage.equipmentsComponents.demoV300Constants.interior.FB1' },
				{ key: 'F66', value: 'demoPage.equipmentsComponents.demoV300Constants.interior.F66' },
				{ key: 'ES3', value: 'demoPage.equipmentsComponents.demoV300Constants.interior.ES3' },
				{ key: 'ES2', value: 'demoPage.equipmentsComponents.demoV300Constants.interior.ES2' },
				{ key: 'CL4', value: 'demoPage.equipmentsComponents.demoV300Constants.interior.CL4' },
				{ key: 'CL1', value: 'demoPage.equipmentsComponents.demoV300Constants.interior.CL1' },
				{ key: 'C74', value: 'demoPage.equipmentsComponents.demoV300Constants.interior.C74' }
			],
			type: 'equipment',
		},
		{
			name: 'infotainment',
			details: [
				{ key: 'JK5', value: 'demoPage.equipmentsComponents.demoV300Constants.infotainment.JK5' },
				{ key: 'JH3', value: 'demoPage.equipmentsComponents.demoV300Constants.infotainment.JH3' },
				{ key: 'EY2', value: 'demoPage.equipmentsComponents.demoV300Constants.infotainment.EY2' },
				{ key: 'E65', value: 'demoPage.equipmentsComponents.demoV300Constants.infotainment.E65' }
			],
			type: 'equipment',
		},
		{
			name: 'safety',
			details: [
				{ key: 'Y44', value: 'demoPage.equipmentsComponents.demoV300Constants.safety.Y44' },
				{ key: 'Y10', value: 'demoPage.equipmentsComponents.demoV300Constants.safety.Y10' },
				{ key: 'T70', value: 'demoPage.equipmentsComponents.demoV300Constants.safety.T70' },
				{ key: 'T56', value: 'demoPage.equipmentsComponents.demoV300Constants.safety.T56' },
				{ key: 'T55', value: 'demoPage.equipmentsComponents.demoV300Constants.safety.T55' },
				{ key: 'T19', value: 'demoPage.equipmentsComponents.demoV300Constants.safety.T19' },
				{ key: 'SH2', value: 'demoPage.equipmentsComponents.demoV300Constants.safety.SH2' },
				{ key: 'SH1', value: 'demoPage.equipmentsComponents.demoV300Constants.safety.SH1' },
				{ key: 'SA6', value: 'demoPage.equipmentsComponents.demoV300Constants.safety.SA6' },
				{ key: 'RY2', value: 'demoPage.equipmentsComponents.demoV300Constants.safety.RY2' },
				{ key: 'JW8', value: 'demoPage.equipmentsComponents.demoV300Constants.safety.JW8' },
				{ key: 'JW5', value: 'demoPage.equipmentsComponents.demoV300Constants.safety.JW5' },
				{ key: 'JS1', value: 'demoPage.equipmentsComponents.demoV300Constants.safety.JS1' },
				{ key: 'JP1', value: 'demoPage.equipmentsComponents.demoV300Constants.safety.JP1' },
				{ key: 'JF1', value: 'demoPage.equipmentsComponents.demoV300Constants.safety.JF1' },
				{ key: 'JA9', value: 'demoPage.equipmentsComponents.demoV300Constants.safety.JA9' },
				{ key: 'JA7', value: 'demoPage.equipmentsComponents.demoV300Constants.safety.JA7' },
				{ key: 'FZ5', value: 'demoPage.equipmentsComponents.demoV300Constants.safety.FZ5' },
				{ key: 'FC1', value: 'demoPage.equipmentsComponents.demoV300Constants.safety.FC1' },
				{ key: 'F72', value: 'demoPage.equipmentsComponents.demoV300Constants.safety.F72' },
				{ key: 'EY5', value: 'demoPage.equipmentsComponents.demoV300Constants.safety.EY5' },
				{ key: 'ET4', value: 'demoPage.equipmentsComponents.demoV300Constants.safety.ET4' },
				{ key: 'BA3', value: 'demoPage.equipmentsComponents.demoV300Constants.safety.BA3' }
			],
			type: 'equipment',
		},
		{
			name: 'technicalDetails',
			details: [
				{ key: 'MU8', value: 'demoPage.equipmentsComponents.demoV300Constants.technicalDetails.MU8' },
				{ key: 'MJ8', value: 'demoPage.equipmentsComponents.demoV300Constants.technicalDetails.MJ8' },
				{ key: 'G43', value: 'demoPage.equipmentsComponents.demoV300Constants.technicalDetails.G43' },
				{ key: 'CF8', value: 'demoPage.equipmentsComponents.demoV300Constants.technicalDetails.CF8' }
			],
			type: 'equipment',
		},
		{
			name: 'meServices',
			type: 'me-services',
			details: [[]]
		},
	],
};

export default [EqsData, S580Data, SclassData, SmartData, SprinterData, V300Data];