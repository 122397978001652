import { WbLink } from '@workbench/react';
import { useTranslation } from 'react-i18next';
import rootList from '../../../Constants/footerList';
import RDBox from '../../Common/RDBox/RDBox';

function FooterLinks() {
	const { t } = useTranslation();
	return (
		<RDBox className="footer__links" align="center">
			{rootList.map((e) => (
				<WbLink key={e.key} href={e.url}> {t(`mainScreen.footer.${e.value}`)}</WbLink>
			))}
		</RDBox>
	);
}

export default FooterLinks;
