/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export */
import { create } from 'zustand';

export const usePlayerStore = create(set => ({
    idle: false,
    setIdle: isIdle => {
        set(state => ({ idle: isIdle }));
    },
    hasTouch: false,
    setHasTouch: hasTouch => {
        // If we are already idle don't change anything, otherwise become idle if we dont have touch
        set(state => ({ hasTouch, idle: state.idle || !hasTouch }));
    },
    darkmode: false,
    setDarkMode: setActive => {
        set(state => ({ darkmode: setActive }));
    },
    currentPlayer: undefined,
    setCurrentPlayer: player => {
        set(state => ({ currentPlayer: player }));
    },
    scenes: undefined,
    setScenes: scenes => {
        set(state => ({ scenes }));
    }
}));
