import * as React from 'react';
import classNames from 'classnames';
import { WbCard, WbText, WbSlider, WbSliderItem, WbLink, WbIcon, WbTag } from '@workbench/react';
import RDivider from '../RDivider/RDivider';
import ImageWithWbFallback from '../ImageWithWbFallback/ImageWithWbFallback';
import './RDCard.scss';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useSiteStyles } from '../../../Contexts/StyleContext';

const useStyles = createUseStyles((theme) => ({
	siteStyles: (siteStyles) => ({
		border: siteStyles.border,
		key: siteStyles.key
	}),
}));

const DetailToggleContext = React.createContext();
DetailToggleContext.displayName = 'DetailToggleContext';

function Slider({ imgUrls }) {
	return (
		<WbSlider
			className="rd-card__slider"
			showDotNavigation
			dotNavigationPosition="bottom"
			theme="dark"
			showArrowNavigation
		>
			{imgUrls.map((imgUrl) => (
				<WbSliderItem key={imgUrl.split('/').pop()}>
					<ImageWithWbFallback src={imgUrl} />
				</WbSliderItem>
			))}
		</WbSlider>
	);
}

function RdCardHeader({ icon, title }) {
	return (
		<div className="rd-card__header">
			{icon && <img src={icon} className="rd-card__icon" alt="" />}
			<WbText size="s" strong>
				{title}
			</WbText>
		</div>
	);
}

function RdCard({ img, title, icon, children, rightTag, leftTag, equipmentHighlightFilter }) {
	const [showDetails, setShowDetails] = React.useState(false);
	const toggle = React.useCallback(() => setShowDetails(!showDetails), [showDetails]);
	const contextValue = React.useMemo(() => ({ showDetails, toggle }), [showDetails, toggle]);
	const hasMultipleImages = typeof img === 'object' && img.length > 0;

	const [details, buttonBar] = React.Children.toArray(children);

	const { siteStyles } = useSiteStyles();
	const classes = useStyles(siteStyles);

	return (
		<DetailToggleContext.Provider value={contextValue}>
			<WbCard className="rd-card__container">
				<div className="rd-card__content">
					<div className={`rd-card__mainImageContainer ${siteStyles.key === "equipment" && equipmentHighlightFilter === "equipmentHighlight" ? classes.siteStyles : null}`} data-testid={`rd-card-${equipmentHighlightFilter}`}>
						{hasMultipleImages ? <Slider imgUrls={img} /> : <ImageWithWbFallback src={img} />}
						{rightTag && (
							<div className="rd-card__tag">
								<WbTag variant="primary">{rightTag}</WbTag>
							</div>
						)}
						{leftTag && (
							<div className="rd-card__tag rd-card__tag--topLeft">
								<WbTag variant="primary">{leftTag}</WbTag>
							</div>
						)}
						<div className="rd-card__mainImageOverlay">
							<RdCardHeader icon={icon} title={title} />
						</div>
					</div>
					{details}
				</div>
				{buttonBar}
			</WbCard>
		</DetailToggleContext.Provider>
	);
}

function RdCardButtonBar({ showMoreDetailsButton, equipmentHighlightFilter }) {
	const { t } = useTranslation();
	const context = React.useContext(DetailToggleContext);
	const { showDetails, toggle } = context;
	const handleClick = (e) => {
		e.preventDefault();
		toggle();
	};

	const checkHighlight = () => {
		return equipmentHighlightFilter === "specificationsHighlight";
	}

	const { siteStyles } = useSiteStyles();
	const classes = useStyles(siteStyles);

	return (
		<div className="rd-card__buttonBar" style={{
			border: showMoreDetailsButton && checkHighlight() ? `${siteStyles.border}` : "none",
		}} data-testid={`rd-card-button-${showMoreDetailsButton && checkHighlight() ? "specificationsHighlight" : ""}`}>
			{showMoreDetailsButton && (
				<WbLink variant="tiny" onClick={handleClick} data-testid="rd-card-button">
					{showDetails ? t("demoTabs.lessDetails") : t("demoTabs.moreDetails")}
				</WbLink>
			)}
		</div>
	);
}

function RdCardDetails({ children, icon, title }) {
	const context = React.useContext(DetailToggleContext);
	const classes = classNames('rd-card-details', {
		'rd-card-details-open': context.showDetails,
		'rd-card-details-closed': !context.showDetails,
	});
	return (
		<div className={classes} data-testid="rd-card-details">
			<div className="rd-card-details-open__header">
				<RdCardHeader icon={icon} title={title} />
				<WbIcon
					className="rd-card-details-open__closeIcon"
					name="bds/close/24"
					onClick={() => context.toggle()}
					data-testid="rd-card-details-closeIcon"
				/>
			</div>
			<RDivider />
			<div className="rd-card-details-open__content">{children}</div>
		</div>
	);
}

export { RdCard, RdCardDetails, RdCardButtonBar };
