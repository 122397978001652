/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
import { useEffect, useMemo, useRef } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import PropTypes from 'prop-types';
import './VideoJS.scss';

const defaultVideoOptions = {
	controls: true,
	responsive: true,
	fluid: true,
};

export default function VideoJS(props) {
	const videoRef = useRef(null);
	const playerRef = useRef(null);
	const { sources, onReady } = props;

	const options = useMemo(() => {
		if (sources) return { ...defaultVideoOptions, sources };
		return { ...defaultVideoOptions };
	}, [sources]);

	useEffect(() => {
		if (!playerRef.current) {
			const videoElement = videoRef.current;

			if (!videoElement) return;

			// eslint-disable-next-line no-multi-assign
			const player = (playerRef.current = videojs(videoElement, { ...options }, () => onReady && onReady(player)));
		} else {
			const player = playerRef.current;
			player.autoplay(options.autoplay);
			player.src(options.sources);
		}
	}, [onReady, options, videoRef]);

	useEffect(() => {
		const player = playerRef.current;

		return () => {
			if (player) {
				player.dispose();
				playerRef.current = null;
			}
		};
	}, [playerRef]);

	return (
		<div data-vjs-player data-testid="getting-started-video">
			{
				// eslint-disable-next-line jsx-a11y/media-has-caption
				<video ref={videoRef} className="video-js vjs-big-play-centered" />
			}
		</div>
	);
}

VideoJS.defaultProps = {
	onReady: undefined,
};

VideoJS.propTypes = {
	sources: PropTypes.arrayOf(PropTypes.shape({ src: PropTypes.string, type: PropTypes.oneOf(['video/mp4']) })).isRequired,
	onReady: PropTypes.func,
};
