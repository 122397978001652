const headerMenuConstants = [
	{
		key: 'exterior',
		icon: 'coupe',
		tooltip: 'demoPage.header.headerMenu.exterior.tooltip',
		href: 'https://developer.mercedes-benz.com/products/vehicle_images/pricing',
	},
	{
		key: 'interior',
		icon: 'seat',
		tooltip: 'demoPage.header.headerMenu.interior.tooltip',
		href: 'https://developer.mercedes-benz.com/products/vehicle_images/pricing',
	},
	{
		key: 'equipment',
		icon: 'bds/wheel/24',
		tooltip: 'demoPage.header.headerMenu.equipment.tooltip',
		href: 'https://developer.mercedes-benz.com/products/vehicle_images/pricing',
	},
	{
		key: 'specifications',
		icon: 'bds/document/24',
		tooltip: 'demoPage.header.headerMenu.specifications.tooltip',
		href: 'https://developer.mercedes-benz.com/products/vehicle_specification',
	},
	{
		key: 'me-services',
		icon: 'bds/mb-star/24',
		tooltip: 'demoPage.header.headerMenu.me-services.tooltip',
		href: 'https://developer.mercedes-benz.com/products/vehicle_specification',
	},
];

export default headerMenuConstants;