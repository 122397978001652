/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/button-has-type */
import '../../Assets/VideoPlaylist/css/modal.css';
import React from 'react';
import Modal from 'react-modal';
import styles from '../../Assets/VideoPlaylist/css/modal.module.css';

import { usePlayerStore } from '../../Utils/VideoPlaylist/lib/stores';
import Player from './Player';

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#root');

export default function ModalPlayer() {
    const currentPlayer = usePlayerStore(state => state.currentPlayer);
    const setCurrentPlayer = usePlayerStore(state => state.setCurrentPlayer);
    const hasTouch = usePlayerStore(state => state.hasTouch);
    const closeModal = () => {
        setCurrentPlayer(undefined);
    };

    return (
        <Modal
            
            overlayClassName={styles.overlay}
            className={styles.modal}
            isOpen={currentPlayer != null}
            onRequestClose={closeModal}
        >
            <div className="absolute top-0 flex w-full items-center justify-end py-32 px-48 text-white pr-10 player-close">
                {hasTouch && (
                    <button onClick={closeModal} className="flex items-center drop-shadow-lg">
                        <img src="/video-playlist/close.svg" alt="Close" className="mr-[20px] h-[23px] w-[23px]" />
                        <span className="text-xs">close</span>
                    </button>
                )}
            </div>
            {currentPlayer != null && <Player data={currentPlayer.data} />}
        </Modal>
    );
}
