import { useEffect, useState } from 'react';

function useWidth() {
	const [width, setWidth] = useState();

	const updateWidth = () => {
		const tempWidth = window.innerWidth;
		setWidth(tempWidth);
	};

	useEffect(() => {
		updateWidth();
		window.addEventListener('resize', updateWidth);

		return () => window.removeEventListener('resize', updateWidth);
	}, []);

	return width;
}

export default useWidth;
