import { WbGrid, WbGridCol, WbGridRow } from '@workbench/react';
import FooterLinks from './FooterLinks';
import './Footer.scss'

function Footer() {
	return (
		<WbGrid className="footer">
			<WbGridRow>
				<WbGridCol mq1={12}>
					<FooterLinks />
				</WbGridCol>
			</WbGridRow>
		</WbGrid>
	);
}

export default Footer;
