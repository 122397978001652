import React from 'react';
import TopHeader from './TopHeader';
import SubHeader from './SubHeader';
import './Header.scss'

function Header() {

	return (
		<>
			<TopHeader />
            <SubHeader />
		</>
	);
}

export default Header;
