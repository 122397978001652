import './VehicleCard.scss';

import { WbCard, WbHeading, WbText } from '@workbench/react';
import { ReactComponent as CarIcon } from '../../../../Assets/Images/car.svg';
import { ReactComponent as EngineIcon } from '../../../../Assets/Images/engine.svg';
import { ReactComponent as MillageIcon } from '../../../../Assets/Images/mileage.svg';
import { ReactComponent as FuelIcon } from '../../../../Assets/Images/fuel.svg';
import { ReactComponent as TransmissionIcon } from '../../../../Assets/Images/transmission.svg';
import RDBox from '../../../Common/RDBox/RDBox';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useSiteStyles } from '../../../../Contexts/StyleContext';

const useStyles = createUseStyles((theme) => ({
	siteStyles: (siteStyles) => ({
		border: siteStyles.border,
		key: siteStyles.key
	}),
}));

const IconType = {
	engine: <EngineIcon className="vehicle-list__icon" />,
	mile: <MillageIcon className="vehicle-list__icon" />,
	car: <CarIcon className="vehicle-list__icon" />,
	fuel: <FuelIcon className="vehicle-list__icon" />,
	transmission: <TransmissionIcon className="vehicle-list__icon" />,
};



function VehicleCard(props) {
	const { infoList } = props;
	const { i18n } = useTranslation();

	function iconHelper(type) {
		const icon = IconType[type];

		if (icon) {
			return icon;
		}
		return IconType.car;
	}

	const getList = il => {
		if (il?.vehicleCard) {
			return il.vehicleCard.slice(1).map((e) => (
				<li className="vehicle-list__item" key={e.type}>
					<RDBox>
						{iconHelper(e.type)}
						<WbText size="m">{e.value}</WbText>
					</RDBox>
				</li>
			));
		}

		return infoList.vehicleDetails[i18n.language === 'de' ? 1 : 0].map((e) => (
			<li className="vehicle-list__item" key={e.type}>
				<RDBox>
					{iconHelper(e.type)}
					<WbText size="m">{e.value}</WbText>
				</RDBox>
			</li>
		));
	}

	const { siteStyles } = useSiteStyles();
	const classes = useStyles(siteStyles);

	return (
		<WbCard className={`rd-vehicle-card ${siteStyles.key === "specifications" ? classes.siteStyles : null}`}>
			<RDBox className="rd-vehicle-card__detail">
				<WbHeading size="m">{infoList?.vehicleCard ? infoList?.vehicleCard[0]?.value : infoList.name[i18n.language === 'de' ? 1 : 0]}</WbHeading>
			</RDBox>
			<RDBox>
				<ul className="vehicle-list">
					{
						getList(infoList)
					}
				</ul>
			</RDBox>
		</WbCard>
	);
}

export default VehicleCard;
