import React, { useState } from 'react';
import { WbText, WbLink } from '@workbench/react';
import { useTranslation } from 'react-i18next';
import './EquipmentDetail.scss';

function EquipmentDetail({ steveData }) {
	const { t } = useTranslation();
	const [showMore, setShowMore] = useState(false);
	if (!steveData?.components?.components.length > 0) return null;
	const bulletpointsHeadlineValue = steveData?.components.bulletpointsHeadline || t('demoTabs.bulletpointsHeadlinePlaceholder');
	const coreLength = steveData?.coreArgument.length;
	const expandText = () => {
		setShowMore(!showMore);
	};
	const slicedCoreArgument = () => {
		const sentences = steveData?.coreArgument.split(/[.!?]+/);
		const firstSentence = sentences[0].trim();

		if (showMore) {
			return (
				<WbText size="s">
					{`${steveData?.coreArgument}`}
					<WbLink variant="tiny" onClick={expandText} data-testid="equipment-detail-collapse-link">
						{' '}
						{t('demoTabs.showLess')}
					</WbLink>
				</WbText>
			);
		}
		return (
			<WbText size="s">
				{`${firstSentence}`}
				<WbLink variant="tiny" data-testid="equipment-detail-expand-link" onClick={expandText}>
					{' '}
					{t('demoTabs.showMore')}
				</WbLink>
			</WbText>
		);
	};

	const mainContents = steveData?.components.components.filter((item) => item);
	const subContents = mainContents.map((item) => item.components);

	return (
		<div className="equipmentDetail">
			<div className="equipmentDetail__coreArgument">
				{coreLength >= 100 ? slicedCoreArgument() : <WbText size="s">{steveData?.coreArgument}</WbText>}
			</div>
			<WbText className="equipmentDetail__headline" strong>{bulletpointsHeadlineValue}</WbText>
			<ul>
				{mainContents && mainContents.map((item, index) => (
					// eslint-disable-next-line react/no-array-index-key
					<li key={`${steveData?.code}-bulletPoint-${index}`} className="equipmentDetail__detail">
						<WbText size="s" strong>
							{item.content}
						</WbText>
						<ul>
							{subContents && subContents[index].map((subItem, subIndex) => (
								// eslint-disable-next-line react/no-array-index-key
								<li key={`${steveData?.code}-bulletPoint-${subIndex}-${subItem}`}>
									<WbText size="s">{subItem.content}</WbText>
								</li>
							))}
						</ul>
					</li>
				))}
			</ul>
		</div>
	);
}

export default EquipmentDetail;
