/* eslint-disable jsx-a11y/label-has-associated-control */
import { WbGrid, WbGridCol, WbGridRow, WbHeading } from '@workbench/react';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ImagePreview from '../../Demo/DemoInfo/ImagePreview/ImagePreview';
import VehicleCard from '../../Demo/DemoInfo/VehicleCard/VehicleCard';
import VehicleTabs from '../VehicleTabs/VehicleTabs';
import './VehicleInfo.scss';

function DemoInfo(props) {
    const { infoList, vin } = props;
    const { t, i18n } = useTranslation();
    const [type, setType] = useState('exterior');
    const [subType, setSubType] = useState('');
    const [images, setImages] = useState(1);
    const [nightImages, setNightImages] = useState(1);
    const [isLoading, setIsLoading] = useState(false);

    const rdDemoImageGallery = useRef(null);

    useMemo(async () => {
        const wbImages = [];
        const wbNightImages = [];

        setIsLoading(true);

        infoList.demoVinData.images.forEach(img => {
            wbImages.push({
                highRes: { src: img },
            });
        })

        infoList.demoVinData.night.images.forEach(img => {
            wbNightImages.push({
                highRes: { src: img },
            });
        })

        setImages(wbImages);
        setNightImages(wbNightImages);
        setIsLoading(false);
    }, [infoList]);


    const fullscreenToggle = () => {
        if (document.fullscreenElement) {
            document.exitFullscreen();
        }
        else {
            rdDemoImageGallery.current.requestFullscreen();
        }
    }

    return (
        <>
            <WbGrid fullWidth className="rd-demo-heading">
                <WbGridRow>
                    <WbGridCol mq5={12} mq4={12} mq3={12} mq2={12} mq1={12} className="rd-demo-heading__text">
                        <WbHeading size="l">{infoList?.vehicleCard[0]?.value} <nobr>{window.REACT_APP_ENVIRONMENT !== 'PRODUCTION' ? ` - ${i18n.language === 'de' ? 'FIN' : 'VIN'} ${vin}` : ''}</nobr></WbHeading>
                    </WbGridCol>
                </WbGridRow>
            </WbGrid>

            <WbGrid fullWidth>
                <WbGridRow className="rd-demo-info">
                    <WbGridCol mq5={8} mq4={8} mq1={12} ref={rdDemoImageGallery} className="rd-demo-info__galery ">
                        <ImagePreview
                            routeId={infoList.demoVinData.routeId}
                            type={type}
                            subType={subType}
                            images={images}
                            nightImages={nightImages}
                            textureUrls={infoList.demoVinData.bisImages}
                            textureUrlsNight={infoList.demoVinData.night.bisImages}
                            isLoading={isLoading}
                            interiorImage={infoList.demoVinData.interiorImage}
                            interiorImages={infoList.demoVinData.interiorImages}
                            exteriorImages={infoList.demoVinData.images}
                            exteriorImagesNight={infoList.demoVinData.night.images}
                            interiorImagesNight={infoList.demoVinData.night.interiorImages}
                            isWindowOpen={infoList.demoVinData.interiorImage ? true : false}
                            windowImages={[infoList.demoVinData.interiorImage, infoList.demoVinData.exteriorImage]}
                            trunkImage={infoList.demoVinData.trunkImage}
                            trunkImageNight={infoList.demoVinData.night.trunkImage}
                            typeHandler={setType}
                            subTypeHandler={setSubType}
                            fullscreenToggle={fullscreenToggle}
                            vehicleVideoData={infoList.demoVinData?.videoDetails}
                            disableNight={infoList.demoVinData.disableNight}
                        />
                    </WbGridCol>
                    <WbGridCol className="rd-demo-vehicle" mq5={4} mq4={4} mq1={12}>
                        <VehicleCard infoList={infoList} />
                    </WbGridCol>
                </WbGridRow>
                {
                    (infoList.demoVinData.tabs && infoList.demoVinData.tabs.length > 0) > 0 &&
                    <WbGridRow className="rd-demo-info rd-vehicle-tab-info">
                        <WbGridCol mq5={12} mq4={12} mq1={12}>
                            <VehicleTabs infoList={infoList.demoVinData} steveData={infoList.steveData} mmesData={infoList.mmesData} equipmentImages={infoList.equipmentImages} />
                        </WbGridCol>
                    </WbGridRow>
                }
            </WbGrid>
        </>
    );
}

export default DemoInfo;
