import { WbGrid, WbGridCol, WbGridRow, WbText } from '@workbench/react';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RDBox from '../../Common/RDBox/RDBox';
import { ReactComponent as SVGIcon } from '../../../Assets/Images/wb_icon.svg';

function SubHeader() {
	const { i18n } = useTranslation();
	const { t } = useTranslation();
	const language = i18n.language.split('-')[0] === 'de' ? 'English' : 'Deutsch';
	
	const [hoverLeft, setHoverLeft] = useState('');
	const [hoverWidth, setHoverWidth] = useState('');

	const onLanguageChange = useCallback(() => {
		if (i18n.language === 'en') {
			return i18n.changeLanguage('de');
		}
		return i18n.changeLanguage('en');
	}, [i18n]);

	const styles = {
		left: hoverLeft,
		width: hoverWidth,
	};

	const updateHover = (element) => {
		const boundries = element.target.getBoundingClientRect();
		setHoverLeft(boundries.left);
		setHoverWidth(boundries.width);
	};

	const removeHover = () => {
		setHoverWidth(0);
	};

	return (
		<div className="sub-header">
			<WbGrid className="sub-header-grid">
				<WbGridRow className="sub-header-container">
					<div className='sub-header__HeaderLinks-hover' style={styles} data-testid="hover-line"/>
					<WbGridCol mq3="hide" className="sub-header__logo">
						<div className="logo__retailer-dashboard" />
					</WbGridCol>
					<WbGridCol className="sub-header-hoverLinks">
						<span className="sub-header__HeaderLink">
							<a
								href={t('mainScreen.header.dataLink')}
								rel="noopener noreferrer"
								target="_blank"
								onMouseEnter={updateHover}
								onMouseLeave={removeHover}
							>
								{t('mainScreen.header.mercedesBenzData')}
							</a>
						</span>
						<span className="sub-header__HeaderLink">
							<a
								href="https://developer.mercedes-benz.com/products?u=Retailer"
								rel="noopener noreferrer"
								target="_blank"
								onMouseEnter={updateHover}
								onMouseLeave={removeHover}
							>
								{t('mainScreen.header.products')}
							</a>
						</span>
						<span className="sub-header__HeaderLink">
							<a
								href="https://developer.mercedes-benz.com/contact/vehicle_images/business_inquiry"
								rel="noopener noreferrer"
								target="_blank"
								onMouseEnter={updateHover}
								onMouseLeave={removeHover}
							>
								{t('mainScreen.header.contact')}
							</a>
						</span>
						<span className="sub-header__HeaderLink">
							<a
								href="https://developer.mercedes-benz.com/contact/vehicle_images/technical_inquiry"
								rel="noopener noreferrer"
								target="_blank"
								onMouseEnter={updateHover}
								onMouseLeave={removeHover}
							>
								{t('mainScreen.header.support')}
							</a>
						</span>
					</WbGridCol>
					<WbGridCol className="sub-header__language">
						<RDBox
							align="center"
							className="sub-header__language-change"
							data-testid="language-change"
							onClick={() => {
								onLanguageChange();
							}}
						>
							<SVGIcon slot="icon" />
							<WbText>{language} </WbText>
						</RDBox>
					</WbGridCol>
				</WbGridRow>
			</WbGrid>
		</div>
	);
}

export default SubHeader;
