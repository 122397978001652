function handleDismissEvents(event) {
    const notification = event.srcElement;
    notification.remove();
  }

export default function createNotification (message, error=false, ttl="60000") {
    const host = document.querySelector('wb-notification-host');
    const notification = document.createElement('wb-notification');
    notification.innerText = message;
    notification.variant = error ? "error" : "";
    notification.lifetime = ttl;
    if(host) {
        host.appendChild(notification);
        document.addEventListener('wbdismiss', handleDismissEvents);
        return true;
    }
    // eslint-disable-next-line no-console
    console.log("error occured while creating notification!");
    return false;
}