import { useNavigate } from 'react-router-dom';
import { WbCard } from '@workbench/react';
import DemoInfo from '../DemoInfo/DemoInfo';
import DemoTabs from '../DemoTabs/DemoTabs';
import allVehicleData from '../../../Constants/vehicleData';
import './DemoDetails.scss';

function DemoDetails(props) {
	const navigate = useNavigate();
	const { vehicleId } = props;
	const vehicleData = allVehicleData.find((item) => item.routeId === vehicleId);
	if (!vehicleData) {
		return navigate('/demo');
	}

	return (
		<WbCard className="demo-detail__container">
			<DemoInfo infoList={vehicleData} />
			<DemoTabs infoList={vehicleData} />
		</WbCard>
	);
}

export default DemoDetails;
