import { WbGrid, WbGridCol, WbGridRow } from '@workbench/react';

import ProductKey from '../../Components/GettingStarted/ProductKey';
import '../../Components/GettingStarted/GettingStarted.scss';
import GettingStartedIntro from '../../Components/GettingStarted/GettingStartedIntro';
import GettingStartedVideo from '../../Components/GettingStarted/GettingStartedVideo';
import { useSiteStyles } from '../../Contexts/StyleContext';
import { useEffect } from 'react';

function GettingStarted() {
	const { setSiteStyles } = useSiteStyles();

	let resetStyles = {
		border: '',
		key: '',
	};

	useEffect(() => {
		setSiteStyles(resetStyles);
	}, []);

	return (
		<div className="getting-started">
			<WbGrid className="gs-main">
				<WbGridRow>
					<WbGridCol mq4={6} mq1={12}>
						<GettingStartedIntro />
					</WbGridCol>
					<WbGridCol mq4={6} mq1={12}>
						<GettingStartedVideo className="gs-main__video" />
					</WbGridCol>
				</WbGridRow>
			</WbGrid>
			<ProductKey />
		</div>
	);
}

export default GettingStarted;
