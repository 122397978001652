import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { WbBreadcrumb, WbBreadcrumbs, WbCard, WbGrid } from '@workbench/react';
import { useTranslation } from 'react-i18next';
import VehicleInfo from '../../Components/VINVehicle/VehicleInfo/VehicleInfo';
import './VehicleDetails.scss';

function BreadCrumb() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const handleBreadCrumbClick = (e) => {
		navigate(`/${e.currentTarget.innerText.toLowerCase()}`);
	};
	return (
		<WbBreadcrumbs className="navigation">
			<WbBreadcrumb onClick={handleBreadCrumbClick}>{t('breadcrumb.home')}</WbBreadcrumb>
			<WbBreadcrumb onClick={handleBreadCrumbClick}>{t('breadcrumb.vehicle')}</WbBreadcrumb>
			<WbBreadcrumb onClick={handleBreadCrumbClick}>{t('breadcrumb.vehicleDetails')}</WbBreadcrumb>
		</WbBreadcrumbs>
	);
}


function VehicleDetails() {
	const { detailsId } = useParams();
	const vehicleDetails = JSON.parse(localStorage.getItem(detailsId));
	const vehicleData = vehicleDetails?.vehicleData;
	const vehicleId = vehicleDetails?.vehicleId;

	if (vehicleData && vehicleId) {
		return (
			<WbGrid>
				<BreadCrumb />
				<WbCard className="vehicle-detail__container">
					<VehicleInfo vin={vehicleId} infoList={vehicleData} />
				</WbCard>
			</WbGrid>
		);
	}else{
		console.log("Vehicle not found: vehicleData " , vehicleDetails );
	}
	return (<Navigate to="/vehicle" />);

}

export default VehicleDetails;
