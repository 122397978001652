
import { useNavigate } from 'react-router-dom';
import {
    WbGrid,
    WbBreadcrumbs,
    WbBreadcrumb,
    WbNotificationHost,
} from '@workbench/react';
import { useTranslation } from 'react-i18next';
import VinInput from '../../Components/VINVehicle/VinInput/VinInput';
import './Vehicle.scss';

function BreadCrumb() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const handleBreadCrumbClick = (e) => {
        navigate(`/${e.currentTarget.innerText.toLowerCase()}`);
    };
    return (
        <WbBreadcrumbs className="navigation">
            <WbBreadcrumb onClick={handleBreadCrumbClick}>{t('breadcrumb.home')}</WbBreadcrumb>
            <WbBreadcrumb onClick={handleBreadCrumbClick}>{t('breadcrumb.vehicle')}</WbBreadcrumb>
        </WbBreadcrumbs>
    );
}

function Vehicle() {
    return (
        <WbGrid>
            <BreadCrumb />
            <VinInput />
            <WbNotificationHost position='top-end' />
        </WbGrid>
    );
}

export default Vehicle;
