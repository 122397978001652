import { Wb360Viewer, WbAspectRatio, WbGallery, WbGalleryItem, WbGridCol, WbPlaceholder, WbSphereView } from '@workbench/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import vehicleData from "../../../../Constants/vehicleData";
import { useSiteStyles } from '../../../../Contexts/StyleContext';
import checkHighlight from "../../../../Utils/checkHighlightKeyValue";
import BlingsVideo from "../../DemoTabContents/BlingsVideo/BlingsVideo";
import './ImagePreview.scss';
import ImagePreviewController from './ImagePreviewController/ImagePreviewController';

const useStyles = createUseStyles((theme) => ({
	siteStyles: (siteStyles) => ({
		border: siteStyles.border,
		key: siteStyles.key
	}),
}));

function ImagePreview(props) {
	const {
		type,
		subType,
		images,
		nightImages,
		isLoading,
		textureUrls,
		textureUrlsNight,
		typeHandler,
		subTypeHandler,
		isWindowOpen,
		interiorImage,
		interiorImages,
		exteriorImages,
		exteriorImagesNight,
		interiorImagesNight,
		trunkImage,
		trunkImageNight,
		fullscreenToggle,
		routeId,
		vinDemo,
		vehicleVideoData,
		disableNight
	} = props;

	const [night, setNight] = useState(false);
	const [currentImages, setCurrentImages] = useState({
		images,
		interiorImages,
		exteriorImages,
		trunkImage,
		textureUrls
	});

	let exteriorImageGallery;
	let exteriorImageGalleryNight;

	if (exteriorImages?.length > 32) {
		exteriorImageGallery = [exteriorImages[0], exteriorImages[6], exteriorImages[9], exteriorImages[18], exteriorImages[27], exteriorImages[32]];
	}
	else {
		exteriorImageGallery = [exteriorImages[0], exteriorImages[1], exteriorImages[2], exteriorImages[3], exteriorImages[4], exteriorImages[5]]
	}
	if (exteriorImagesNight?.length > 32) {
		exteriorImageGalleryNight = [exteriorImagesNight[0], exteriorImagesNight[6], exteriorImagesNight[9], exteriorImagesNight[18], exteriorImagesNight[27], exteriorImagesNight[32]];
	}
	else {
		exteriorImageGalleryNight = [exteriorImagesNight[0], exteriorImagesNight[1], exteriorImagesNight[2], exteriorImagesNight[3], exteriorImagesNight[4], exteriorImagesNight[5]]
	}

	function handleAvailablePanels() {
		return { video: vinDemo ? 0 : 1, exterior: images ? 1 : 0, interior: isWindowOpen ? 1 : 0, trunk: trunkImage ? 1 : 0 };
	}	// TODO: revert to 1 after vinDemo vvs implementation

	function handleAvailableSubPanels() {
		return {
			interior: {
				'360-view-interior': textureUrls ? 1 : 0,
				'image-gallery': interiorImages ? 1 : 0,
				trunk: trunkImage ? 1 : 0,
				'night-view': disableNight ? 0 : 1, // TODO: revert to 1 after implementing night view chooser
				'fullscreen-view': 1
			},
			exterior: {
				'360-view-exterior': images?.length === 36 ? 1 : 0,
				'image-gallery': exteriorImages ? 1 : 0,
				'night-view': disableNight ? 0 : 1, // TODO: revert to 1 after implementing night view chooser
				'fullscreen-view': 1,
				'video': vinDemo ? 0 : 1 // TODO: revert to 1 after vinDemo vvs implementation
			},
			video: {
				'fullscreen-view': vinDemo ? 0 : 1 // TODO: revert to 1 after vinDemo vvs implementation
			}
		};
	}

	useEffect(() => {
		let ci = {
			images: night ? nightImages : images,
			interiorImages: night ? interiorImagesNight : interiorImages,
			exteriorPhotos: night ? exteriorImageGalleryNight : exteriorImageGallery,
			trunkImage: night ? trunkImageNight : trunkImage,
			textureUrls: night ? textureUrlsNight : textureUrls
		};
		setCurrentImages(ci);
	}, [night])

	const { siteStyles } = useSiteStyles();
	const classes = useStyles(siteStyles);

	return (
		<div className="rd-demo-image-preview" >
			<div className={`rd-demo-image-preview__image-container ${checkHighlight(siteStyles.key) ? classes.siteStyles : null}`} data-testid={night ? "image-preview-night" : "image-preview"}>
				{/* ImageContainer içeriği */}
				<ImageContainer
					type={type}
					subType={subType}
					images={currentImages.images.length !== images.length ? images : currentImages.images}
					interiorImage={interiorImage}
					interiorImages={currentImages.interiorImages}
					exteriorImageGallery={currentImages.exteriorPhotos}
					textureUrls={currentImages.textureUrls}
					isLoading={isLoading}
					trunkImage={currentImages.trunkImage}
					routeId={routeId}
					vinDemo={vinDemo}
					vehicleVideoData={vehicleVideoData}
				/>
			</div>
			<div className="rd-demo-image-preview__controller">
				<ImagePreviewController
					type={type}
					subType={subType}
					typeHandler={typeHandler}
					subTypeHandler={subTypeHandler}
					availablePanels={handleAvailablePanels()}
					availableSubPanels={handleAvailableSubPanels()}
					fullscreenToggle={fullscreenToggle}
					night={night}
					setNight={setNight}
				/>
			</div>
		</div>
	);
}

function ImageContainer(props) {
	const { type, subType, images, isLoading, textureUrls, interiorImage, interiorImages, exteriorImageGallery, trunkImage, routeId, vinDemo, vehicleVideoData } = props;
	const { i18n } = useTranslation();

	let videoData = null;
	if (!routeId && !vinDemo) {
		videoData = vehicleVideoData;
	}
	else {
		videoData = !vinDemo && vehicleData.find((item) => item.routeId === routeId).videoDetails[i18n.language === 'de' ? 1 : 0];
	}


	if (type !== 'video' && window.player) {
		window.player.pause();
	}

	if (type === 'exterior') {
		return <ExteriorContainer images={images} exteriorImageGallery={exteriorImageGallery} isLoading={isLoading} subType={subType} />;
	} else if (type === 'interior') {
		return <InteriorContainer interiorImages={interiorImages} interiorImage={interiorImage} trunkImage={trunkImage} subType={subType} textureUrls={textureUrls} />;
	} else if (type === 'video') {
		return <VideoContainer videoData={videoData} type={type} />;
	}
	return <div />;
}

function ExteriorContainer({ images, exteriorImageGallery, isLoading, subType }) {
	if (subType === '360-view-exterior') {
		return !isLoading && <Wb360Viewer preloadHighResImages showHint loading={isLoading} images={images} data-testid="360-exterior-view"/>;
	} else if (subType === "image-gallery") {
		return (
			<WbGallery className='rd-demo-image-gallery' preventFullscreenMode data-testid="exterior-image-gallery">
				{exteriorImageGallery.map((e, index) => (
					<WbGalleryItem src={e} key={e+index} />
				))}
			</WbGallery>
		);
	}
}

function InteriorContainer({ interiorImages, interiorImage, trunkImage, subType, textureUrls }) {

	if (subType === '360-view-interior' && textureUrls) {
		return <WbSphereView fieldOfView={100} textureUrls={textureUrls} data-testid="interior-sphere-images"/>;
	} 
	
	if (subType === 'image-gallery' && interiorImages) {
		return (
			<WbGallery className='rd-demo-image-gallery' preventFullscreenMode data-testid="interior-images-gallery">
				{interiorImages.map((e, index) => (
					<WbGalleryItem src={e} key={e+index} />
				))}
			</WbGallery>
		);
	} 
	
	if (subType === 'trunk' && trunkImage) {
		return <img className="rd-demo-info__trunk-image" src={trunkImage} data-testid="trunk-image"/>;
	}
}

function VideoContainer({ videoData, type }) {
	const { t } = useTranslation();
	if (videoData === null) {
		return (
			<WbGridCol mq1={12} mq3={12} mq4={6} mq5={6} className="rd-demo-video" >
				<WbAspectRatio ratio='16x9'>
					<WbPlaceholder>
						{t('vinDemoPage.userMessage.video.notAvailable')}
					</WbPlaceholder>
				</WbAspectRatio>
			</WbGridCol>
		)
	}
	return (
		<WbGridCol mq1={12} mq3={12} mq4={6} mq5={6} className="rd-demo-video" >
			<BlingsVideo detail={videoData} type={type} />
		</WbGridCol>
	)
}

export default ImagePreview;
