/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import 'swiper/css';
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import styles from '../../Assets/VideoPlaylist/css/playlist.module.css';
// import { animate } from '../../Utils/VideoPlaylist/lib/animation';
import { sleep } from '../../Utils/VideoPlaylist/lib/promises';
import { usePlayerStore } from '../../Utils/VideoPlaylist/lib/stores';

function SwipeIndicator({ blurResolution }) {
    return (
        <div className="relative">
            <div className="absolute -top-56 -left-40 grid h-[150px] w-[150px] grid-cols-1 grid-rows-1 place-items-center">
                {Array.from(Array(blurResolution)).map((_, index) => (
                    <img alt='pointer' data-animation="point" className={`${styles.stack}`} src={`${process.env.PUBLIC_URL}/video-playlist/touchPoint.svg`} style={{ opacity: 1 / blurResolution }} key={`point-${index}`} />
                ))}
            </div>
            <figure data-animation="hand">
                <img data-hand="1" src={`${process.env.PUBLIC_URL}/video-playlist/hand_1.svg`} alt="Swipe here" width={374} draggable={false} />
                <img className="absolute inset-0 opacity-0" data-hand="2" src={`${process.env.PUBLIC_URL}/video-playlist/hand_2.svg`} alt="Swipe here" width={379} draggable={false} />
                <img className="absolute inset-0 left-24 opacity-0" data-hand="3" src={`${process.env.PUBLIC_URL}/video-playlist/hand_3.svg`} alt="Swipe here" width={297} draggable={false} />
            </figure>

        </div>
    );
}
// No-Touch Animation.
// const useNoTouch = (slide, click, initialized) => {
//     const [playState, setPlayState] = useState('ready');
//     const hasTouch = usePlayerStore(state => state.hasTouch);
//     useLayoutEffect(() => {
//         if (!hasTouch && initialized && playState === 'ready') {
//             const playNextVideo = async () => {
//                 setPlayState('animating');
//                 await sleep(20000);
//                 slide();
//                 await sleep(2000);
//                 await click(false);
//                 setPlayState('playing');
//             };
//             playNextVideo().catch(error => {
//                 throw error;
//             });
//         }
//     }, [hasTouch, initialized, slide, click, playState]);
//     // Reset State when Video is done so we can go select the next one
//     const currentPlayer = usePlayerStore(state => state.currentPlayer);
//     useEffect(() => {
//         if (currentPlayer == null && playState === 'playing') {
//             setPlayState('ready');
//         }
//     }, [currentPlayer, playState]);
// };
// // Hand Animation
// const useTouch = (finger, slide, click, initialized) => {

//     const hasTouch = usePlayerStore(state => state.hasTouch);
//     const idle = usePlayerStore(state => state.idle);
//     const resume = useRef(undefined);
//     const cleanup = useRef(undefined);
//     const [playState, setPlayState] = useState('ready');
//     useLayoutEffect(function animation() {
//         if (idle && playState === 'ready' && hasTouch && initialized && finger != null) {
//             setPlayState('animating');
//             const controls = animate(finger, {
//                 onSwipe: () => {
//                     slide();
//                 },
//                 onClick: () => {
//                     click(true)
//                         .then(() => {
//                             setPlayState('playing');
//                         })
//                         .catch(error => {
//                             throw error;
//                         });
//                 },
//                 onFinish: () => {
//                     setPlayState('ready');
//                     // Start the next iteration
//                     animation();
//                 }
//             });
//             resume.current = controls.resume;
//             cleanup.current = controls.cleanup;
//         }
//         return () => {
//             // we need to get the current value for Idle at the time of execution, not at the time of creation of this function
//             const currentlyIdle = usePlayerStore.getState().idle;
//             if (!currentlyIdle) {
//                 // if Video is still playing leave on 'playing' to stop animation from starting early
//                 // if already ready do not set again to avoid infinite loop
//                 if (playState === 'animating') {
//                     setPlayState('ready');
//                 }
//                 resume.current = undefined;
//                 cleanup.current?.();
//             }
//         };
//     }, [finger, initialized, hasTouch, idle, playState, slide, click]);
//     // Reset State when Video is done so we can resume or restart our animation
//     const currentPlayer = usePlayerStore(state => state.currentPlayer);
//     useEffect(() => {
//         if (currentPlayer == null && playState === 'playing') {
//             // is there an Animation to resume?
//             if (resume.current != null) {
//                 setPlayState('animating');
//                 resume.current();
//             }
//             else {
//                 setPlayState('ready');
//             }
//         }
//         if (currentPlayer != null && playState !== 'playing') {
//             setPlayState('playing');
//         }
//     }, [currentPlayer, playState]);
// };
export default function Playlist({ dataSets }) {
    // Blings format check
    const isMobile = window.BlingsPlayer.helpers.utils.isMobile();

    const gridGutterWidth = 40;
    const slidesPerView = 3;
    const indicatorWrapper = useRef(null);
    const [swiper, setSwiper] = useState(null);
    const hasTouch = usePlayerStore(state => state.hasTouch);
    const setCurrentPlayer = usePlayerStore(state => state.setCurrentPlayer);
    const [outputFormat, setOutputFormat] = useState(isMobile);
    const click = useCallback(async (touch) => {
        if (swiper != null && dataSets.length > 0 && !swiper.destroyed) {
            const activeIndex = touch ? swiper.activeIndex + 1 : swiper.activeIndex;
            const realIndex = touch ? (swiper.realIndex + 1) % dataSets.length : swiper.realIndex;
            swiper.slides[activeIndex]?.classList.add(styles.highlight);
            await sleep(550);
            setCurrentPlayer(dataSets[realIndex]);
            swiper.slides[activeIndex]?.classList.remove(styles.highlight);
        }
    }, [dataSets, setCurrentPlayer, swiper]);
    const currentSlide = useRef(0);
    /**
     * Slide to the next slide. Use slide(false) to slide to the currentSlide instead. Useful if that has been changed manually
     * Does not work if jumping more than (slidesPerView)
     * @param index optional, set the index of the slide to slide to, otherwise we progress by one
     */
    const slide = useCallback((index) => {
        if (swiper != null && dataSets.length > 0) {
            // any passed index needs to be decreased on Touch devices to slide that index to the middle
            if (index != null && hasTouch) {
                index--;
            }
            const target = index ?? currentSlide.current + 1;
            if (target >= dataSets.length) {
                // Jump to the duplicated version of this slide before the Start
                swiper.slideToLoop(currentSlide.current - dataSets.length, 0);
                swiper.update();
                currentSlide.current = target - dataSets.length;
            }
            else if (target < 0) {
                // Jump to the duplicated version of this slide before the Start
                swiper.slideToLoop(currentSlide.current + dataSets.length, 0);
                swiper.update();
                currentSlide.current = target + dataSets.length;
            }
            else {
                currentSlide.current = target;
            }
            swiper.slideToLoop(currentSlide.current, 1200);
        }
    }, [currentSlide, dataSets.length, swiper, hasTouch]);

    // Dont open hand animation on mobile
    // if (!outputFormat) {
    //     useNoTouch(slide, click, swiper != null && dataSets.length > 0);
    //     useTouch(indicatorWrapper.current, slide, click, swiper != null && dataSets.length > 0);
    // }
    // TODO: Try to slide to slide 1 (Index 0) before we start the Animation

    return (
        <>
            <Swiper
                onSwiper={inst => {
                    setSwiper(inst);
                }}
                // So we can continue animating from that slide after going idle
                // eslint-disable-next-line no-return-assign
                onTransitionEnd={() => (currentSlide.current = swiper?.realIndex)}
                style={{ overflow: 'visible' }}
                spaceBetween={gridGutterWidth}
                loop
                breakpoints={{
                    768: { slidesPerView: 1 },
                    1024: { slidesPerView }
                }}
            >
                {dataSets.map(data => (
                    <SwiperSlide key={data.filename}>
                        <figure
                            onClick={() => {
                                setCurrentPlayer(data);
                                // always true at this point but necessary for typescript
                                if (swiper != null) {
                                    // get the index of this slide (keeping in mind it might be its duplicated version)
                                    // and subtract the offset due to duplication
                                    slide(swiper.clickedIndex - slidesPerView);
                                }
                            }}
                        >
                            <img
                                draggable={false}
                                className="mb-s h-[311px] w-full object-cover"
                                src={data.data.endImage}
                                alt={data.title}
                            />
                            <h4 className="text-s text-grey-20 dark:text-white">{data.title}</h4>
                        </figure>
                    </SwiperSlide>
                ))}
            </Swiper>
            <figure
                ref={indicatorWrapper}
                className="pointer-events-none absolute left-[1001px] top-[693px] z-10 opacity-0"
            >
                <SwipeIndicator blurResolution={20} />
            </figure>
        </>
    );
}
