import React from 'react';
import { WbGridCol, WbGridRow, WbHeading, WbTab, WbTabContent, WbTabs, WbText } from '@workbench/react';
import { useTranslation } from 'react-i18next';
import RDBox from '../../Common/RDBox/RDBox';
import './DemoTabs.scss';
import { DemoTabContents, chooseDemoTabContent } from '../DemoTabContents/DemoTabContents';
import RDivider from '../../Common/RDivider/RDivider';
import vehicleData from "../../../Constants/vehicleData";
import { createUseStyles } from 'react-jss';
import { useSiteStyles } from '../../../Contexts/StyleContext';

const useStyles = createUseStyles((theme) => ({
	siteStyles: (siteStyles) => ({
		border: siteStyles.border,
		key: siteStyles.key,
	}),
}));

function DemoTabs(props) {
	const { infoList } = props;
	const { t } = useTranslation();
	const routeId = infoList.routeId;

	const checkTabExteriorOrInterior = (tab) => {
		return tab.name === "exterior" || tab.name === "interior";
	}

	const routeIdLackTable = {
		"EQS": "UNI",
		"S-Class-Hybrid": "METALLIC",
		"SL-AMG": "UNI",
		"Smart-EQ": "METALLIC",
		"Sprinter": "UNI",
		"V-Class": "METALLIC",
	}

	const isSmartUpperPaint = (routeId, tab) => {
		return routeId === "Smart-EQ" && tab.name === "exterior";
	}

	const { siteStyles } = useSiteStyles();
	const classes = useStyles(siteStyles);

	const equipmentHighlightFilter = (tab) => {
		if(siteStyles.key === "equipment" && tab.name !== "generalData" && tab.name !== "meServices"){
			return "equipmentHighlight";
		} else if(siteStyles.key === "specifications" && tab.name !== "meServices"){
			return "specificationsHighlight";
		} else if(siteStyles.key === "me-services" && tab.name === "meServices"){
			return "meServicesHighlight";
		}
	}

	const chooseSelectedTab = () => {
		if(siteStyles.key === "equipment"){
			return "packages";
		} else if(siteStyles.key === "me-services"){
			return "meServices";
		}
		return "generalData";
	}

	return (
		<RDBox className="rd-demo-content">
			<WbTabs className="rd-demo-content__tabs" selected={chooseSelectedTab()} data-testid="rd-demo-tabs">
				{infoList.tabs.map((tab) => (
					<React.Fragment key={tab.name}>
						<WbTab className={`${equipmentHighlightFilter(tab) === "equipmentHighlight" ? classes.siteStyles : null} ${equipmentHighlightFilter(tab) === "specificationsHighlight" ? classes.siteStyles : null} ${equipmentHighlightFilter(tab) === "meServicesHighlight" ? classes.siteStyles : null}`} name={tab.name} data-testid={`demo-tab-${tab.name}`}>{t(`demoTabs.dataTabs.${tab.name}`)}</WbTab>
						<WbTabContent name={tab.name} slot="content" className={`rd-demo-content__tab ${equipmentHighlightFilter(tab)==="meServicesHighlight" ? classes.siteStyles : null} ${equipmentHighlightFilter(tab) === "specificationsHighlight" && tab.name === "generalData" ? classes.siteStyles : null} `} data-testid={`demo-tab-content-${tab.name}`}>
							{checkTabExteriorOrInterior(tab) && (
								<React.Fragment>
									<WbGridRow className="rd-demo-line-content">
										<WbGridCol mq3={6} mq1={8}>
											<RDBox className="rd-demo-image-box">
												<img className="rd-demo-lineImage" src={tab.name === "exterior" ? infoList.exteriorPaint : infoList.interiorUpholstery}></img>
												{isSmartUpperPaint(routeId, tab) && <img className="rd-demo-lineImage" src={infoList.exteriorUpperPaint}></img>}
												<RDBox className="rd-demo-image-box-description">
													<WbText>{tab.name === "exterior" ? t("demoTabs.paint") : t("demoTabs.upholstery")}</WbText>
													<WbText>{tab.name === "exterior" ? t(`demoTabs.${routeId}Paint`) : t(`demoTabs.${routeId}Upholstery`)}</WbText>
												</RDBox>
											</RDBox>
										</WbGridCol>
										{tab.name === "exterior" && (
											<WbGridCol mq3={6} mq1={4}>
												<RDBox className="rd-demo-line-box">
													<WbText>{t("demoTabs.lack")}</WbText>
													<WbText>{routeIdLackTable[routeId]}</WbText>
												</RDBox>
											</WbGridCol>
										)}
									</WbGridRow>
									<RDivider />
								</React.Fragment>
							)}
							<WbHeading size="xs" className="rd-demo-content__heading">
								{t(`demoTabs.dataTabs.${tab.name}`)}
							</WbHeading>
							<WbGridRow>
								<DemoTabContents tab={tab} vehicleData={infoList} equipmentHighlightFilter={equipmentHighlightFilter(tab)} className="rd-demo-content__element">
									{chooseDemoTabContent(tab.type)}
								</DemoTabContents>
							</WbGridRow>
						</WbTabContent>
					</React.Fragment>
				))}
			</WbTabs>
		</RDBox>
	);
}

export default DemoTabs;
