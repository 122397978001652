import { useState } from 'react';
import { WbAspectRatio, WbPlaceholder } from '@workbench/react';

function ImageWithWbFallback({ src }) {
	const [error, setError] = useState(false);
	return (
		<WbAspectRatio ratio="16x9">
			{(error || !src) ? (
				<WbPlaceholder data-testid="placeholder" />
			) : (
				<img
					src={src}
					alt=""
					loading="lazy"
					onError={() => {
						setError(true);
					}}
					data-testid="imageWithFallback"
				/>
			)}
		</WbAspectRatio>
	);
}

export default ImageWithWbFallback;
