import { WbGrid, WbGridCol, WbGridRow, WbVehicleTile } from '@workbench/react';
import React from 'react';
import './DemoContent.scss';
import { useNavigate } from 'react-router-dom';
import classList from "../../../Constants/classList";

function DemoContent() {
	const navigate = useNavigate();

	const onExampleVehicleClick = (e) => {
		navigate(`/demo/${e.routeId}`);
	};

	return (
		<WbGrid fullWidth className="rd-vehicle-class-list">
			<WbGridRow className="rd-demo-header__main">
				{classList.map((e) => (
					<WbGridCol mq5={4} mq4={6} mq1={12} key={e.key}>
						<WbVehicleTile
							data-testid={`vehicle-tile-${e.routeId}`}
							className="rd-demo-vehicles"
							onClick={() => onExampleVehicleClick(e)}
							key={e.key}
							img={{
								src: e.imageUrl,
								alt: e.description,
							}}
							heading={{
								content: e.description,
							}}
						/>
					</WbGridCol>
				))}
			</WbGridRow>
		</WbGrid>
	);
}

export default DemoContent;
